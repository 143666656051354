import {
  formatValueSize,
  GL_COLORS,
  GLCardTemplate,
  GLTooltip,
  GLTypography,
  useI18n,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";

import { GetMessageListResponseRow } from "../../../../Services/messageCenterService/messageListService/useMessageListService.types";
// import { DateTime } from "luxon";

interface PushListCard {
  items: GetMessageListResponseRow[];
}

export const CardsDesktop = ({ items }: PushListCard) => {
  const { t } = useI18n();

  // function formatTime(timestamp: number): string {
  //   const dt = DateTime.fromMillis(timestamp, { zone: "utc" });

  //   if (dt.hour > 0) return dt.toFormat("HH'h 'mm'm'");

  //   if (dt.minute > 0) return dt.toFormat("mm'm 's's'");

  //   if (dt.second > 0) return dt.toFormat("s's");

  //   if (dt.millisecond > 0) return dt.toFormat("SSS") + "ms";

  //   return "--";
  // }

  function formatOS(os: string): string {
    if (os === "android") return "Android";

    return os;
  }

  return (
    <>
      {items?.map((col, index) => (
        <GLCardTemplate.Provider
          key={col.device_id + col.read_at + Math.random()}
        >
          <GLCardTemplate.Root borderRadius="0px">
            <GLCardTemplate.Header>
              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography
                  text={String(format(col.read_at, "dd/MM/yyyy - HH:mm:ss"))}
                  color={"FONT_COLOR"}
                />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                width={70}
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography text={String(col.user_id)} color={"FONT_COLOR"} />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
                width={70}
              >
                <GLTypography text={col.device_id} color={"FONT_COLOR"} />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTypography text={col.title} color={"FONT_COLOR"} />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
              >
                <GLTooltip
                  text={col.message.length > 50 ? col.message : undefined}
                  position={index === 0 ? "bottom-center" : "top-left"}
                >
                  <GLTypography
                    text={formatValueSize({
                      size: 50,
                      value: col.message,
                    })}
                    color={"FONT_COLOR"}
                    as="span"
                  />
                </GLTooltip>
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
                width={40}
              >
                <GLTypography text={formatOS(col.os)} color={"FONT_COLOR"} />
              </GLCardTemplate.Column>

              <GLCardTemplate.Column
                style={{
                  borderRadius: "0px 10px",
                }}
                align="center"
                justify="flex-start"
                width={60}
              >
                <GLTypography
                  text={
                    col.event_type === "EVENT_IN"
                      ? t("eventList.columns.eventType.entry")
                      : t("eventList.columns.eventType.exit")
                  }
                  color={"FONT_COLOR"}
                />
              </GLCardTemplate.Column>
            </GLCardTemplate.Header>
          </GLCardTemplate.Root>
        </GLCardTemplate.Provider>
      ))}
    </>
  );
};

export const CardsMobile = ({ items }: PushListCard) => {
  const { t } = useI18n();

  // function formatTime(timestamp: number): string {
  //   const dt = DateTime.fromMillis(timestamp, { zone: "utc" });

  //   if (dt.hour > 0) return dt.toFormat("HH'h 'mm'm'");

  //   if (dt.minute > 0) return dt.toFormat("mm'm 's's'");

  //   if (dt.second > 0) return dt.toFormat("s's");

  //   if (dt.millisecond > 0) return dt.toFormat("SSS") + "ms";

  //   return "--";
  // }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          padding: "15px",
        }}
      >
        {items?.map((col) => (
          <GLCardTemplate.Provider
            key={col.device_id + col.read_at + Math.random()}
          >
            <GLCardTemplate.Root borderRadius="10px">
              <GLCardTemplate.Header padding={"15px"} justify="space-between">
                <GLCardTemplate.Column
                  direction="column"
                  alignSelf="flex-start"
                >
                  <GLTypography
                    fontSize={3}
                    text="Title"
                    color="FONT_COLOR_DARK_GREY"
                    weight={600}
                  />
                  <GLTypography fontSize={3} text={col.title} />
                </GLCardTemplate.Column>

                <GLCardTemplate.Column direction="column">
                  <GLTypography
                    fontSize={3}
                    text="Message"
                    color="FONT_COLOR_DARK_GREY"
                    weight={600}
                  />
                  <GLTypography fontSize={3} text={col.message} />
                </GLCardTemplate.Column>
                <GLCardTemplate.Arrow />
              </GLCardTemplate.Header>

              <GLCardTemplate.Content
                style={{ backgroundColor: GL_COLORS.BACKGROUND_SECONDARY }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "15px",
                  }}
                >
                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text="Device ID"
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    {col.device_id ? (
                      <GLTypography
                        text={col.device_id}
                        color={"FONT_COLOR"}
                        fontSize={3}
                      />
                    ) : (
                      "--"
                    )}
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text="Title"
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    <GLTypography fontSize={3} text={col.title} />
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text="Sent at"
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    <GLTypography
                      text={String(
                        format(col.read_at, "dd/MM/yyyy - HH:mm:ss")
                      )}
                      color={"FONT_COLOR"}
                      fontSize={3}
                    />
                  </GLCardTemplate.Column>
                </div>

                <div
                  style={{
                    display: "flex",
                    padding: "15px",
                  }}
                >
                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text="User ID"
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    <GLTypography
                      text={String(col.user_id)}
                      color={"FONT_COLOR"}
                      fontSize={3}
                    />
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column">
                    <GLTypography
                      fontSize={3}
                      text={t("eventList.columns.type")}
                      color="FONT_COLOR_DARK_GREY"
                      weight={600}
                    />
                    <GLTypography
                      text={col.event_type === "EVENT_IN" ? "In" : "Out"}
                      color={"FONT_COLOR"}
                      fontSize={3}
                    />
                  </GLCardTemplate.Column>

                  <GLCardTemplate.Column direction="column">
                    <></>
                  </GLCardTemplate.Column>
                </div>
              </GLCardTemplate.Content>
            </GLCardTemplate.Root>
          </GLCardTemplate.Provider>
        ))}
      </div>
    </>
  );
};
