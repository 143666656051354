import { useI18n, useToast } from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";
import { getAnalytics, logEvent } from "firebase/analytics";
// import { format } from "date-fns";
import { useEffect, useState } from "react";

import { useMessageContentServices } from "../../../../../Services/messageCenterService/MessageContentServices/useMessageContentServices";
import { useMessageCenterStore } from "../../../store/messageCenter";
import { useOnlineCampaignContent } from "../../OnlineCampaign/Content/useOnlineCampaignContent";
import {
  CREATE_CAMPAIGN_STEP_NAMES_TO_KEYS,
  CreateCampaingStepNames,
  useCreateCampaignStore,
} from "../store/createCampaign";
import { useMessageContentStore } from "../store/messageContent";
import { useScheduleStore } from "../store/schedule";
import { useSegmentationStore } from "../store/segmentation";
import { useSpotListStore } from "../store/spotList";

export function useReview() {
  const [isLoading] = useState(false);
  const { state: segmentationState, actions: segmentationActions } =
    useSegmentationStore();
  const { state: spotListState, actions: spotListActions } = useSpotListStore();
  const { state: messageContentState, actions: messageContentActions } =
    useMessageContentStore();
  const { state: scheduleState, actions: scheduleActions } = useScheduleStore();

  const { state: createCampaignState, actions: createCampaignActions } =
    useCreateCampaignStore();

  const { createCampaign, updateCampaign } = useMessageContentServices();
  const { state: messageCenterState } = useMessageCenterStore();
  const { refetchAll } = useOnlineCampaignContent();

  const { addToast } = useToast();
  const { t } = useI18n();
  const analytics = getAnalytics();

  const queryClient = useQueryClient();

  function sendForm() {
    const { campaignName, appsSelected } = segmentationState;
    const { startDate, endDate, campaign_interval } = scheduleState;

    const appsId = appsSelected.map((app) => Number(app.id));
    const spotsId = spotListState.glSpotsAddeds.map((spot) => +spot.device_id);
    const notifications = messageContentState.notificationsList.map(
      (notification) => notification
    );

    createCampaignActions.setIsLoading(true);

    createCampaign({
      name: campaignName,
      app_ids: appsId,
      device_ids: spotsId,
      messages: notifications,
      grace_period: formatMinimumIntervalToSeconds(),
      start_date: startDate,
      end_date: endDate!,
      campaign_interval,
    })
      .then((res) => {
        if (res.status === 201) {
          refetchAll();
          addToast({
            title: t(
              "messageCenter.list.modal.creationModal.messages.campaignCreated"
            ),
            message: t(
              "messageCenter.list.modal.creationModal.messages.campaignCreatedDescription"
            ),
            type: "success",
          });
          createCampaignActions.setModalSteperContentIsOpen(false);
          createCampaignActions.resetAll();

          queryClient.invalidateQueries({
            queryKey: ["get-campaign-by-id", messageCenterState.cardInfo.id],
          });

          logEvent(analytics, "campaign_created");
        }
      })
      .catch((err) => {
        const { response } = err;

        if (
          response.data.message === "There is already a campaign with that name"
        ) {
          addToast({
            title: t(
              "messageCenter.list.modal.creationModal.messages.campaignAlreadyExists"
            ),
            message: t(
              "messageCenter.list.modal.creationModal.messages.campaignAlreadyExistsDescription"
            ),
            type: "error",
          });
          return;
        }

        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });
      })
      .finally(() => {
        createCampaignActions.setIsLoading(false);
      });
  }

  const _updateCampaign = () => {
    const { campaignName, appsSelected } = segmentationState;
    const { startDate, endDate, campaign_interval } = scheduleState;

    const appsId = appsSelected.map((app) => Number(app.id));
    const spotsId = spotListState.glSpotsAddeds.map((spot) => +spot.device_id);
    const notifications = messageContentState.notificationsList.map(
      (notification) => notification
    );

    if (!messageCenterState.cardInfo) return;

    if (createCampaignState.modalType !== "edition") return;

    createCampaignActions.setIsLoading(true);
    updateCampaign(messageCenterState.cardInfo.id, {
      name: campaignName,
      app_ids: appsId,
      device_ids: spotsId,
      messages: notifications,
      grace_period: formatMinimumIntervalToSeconds(),
      start_date: startDate,
      end_date: endDate!,
      campaign_interval,
    })
      .then((res) => {
        if (res && res.status === 200) {
          addToast({
            title: t(
              "messageCenter.list.modal.creationModal.messages.campaignEdited"
            ),
            message: t(
              "messageCenter.list.modal.creationModal.messages.campaignEditedDescription"
            ),
            type: "success",
          });
          createCampaignActions.setModalSteperContentIsOpen(false);
          queryClient.invalidateQueries({
            queryKey: ["get-campaign-by-id", messageCenterState.cardInfo.id],
          });
          createCampaignActions.resetAll();
        }
      })
      .catch((err) => {
        console.log(err);
        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });
      })
      .finally(() => {
        createCampaignActions.setIsLoading(false);
      });
  };

  const scheduleTime = scheduleState.scheduleSendValue.times;
  const scheduleDate = scheduleState.scheduleSendValue.date;

  const inputFrequencyAndPeriodValue = `${scheduleTime}${scheduleDate ? " " + scheduleDate : ""}`;

  const segmentation = {
    campaignName: segmentationState.campaignName,
    appsSelectedText: formatAppsSelectedToText(),
    appsSelected: segmentationState.appsSelected,

    devicesSelected: spotListState.glSpotsAddeds,
    devicesSelectedText: formatDevicesSelectedToText(),
  };

  const notificationList = {
    messages: messageContentState.notificationsList,
  };

  const schedule = {
    frequency: inputFrequencyAndPeriodValue,
    minimumInterval: formatMinimumIntervalToText(),
  };

  function formatAppsSelectedToText() {
    const appsSelected = segmentationState.appsSelected.length;

    if (appsSelected === 1) {
      return "01 app selected";
    }

    return `${appsSelected} apps selected`;
  }

  function formatDevicesSelectedToText() {
    const devicesSelected = spotListState.glSpotsAddeds.length;

    if (devicesSelected === 1) {
      return "01 device selected";
    }

    return `${devicesSelected} devices selected`;
  }

  function formatMinimumIntervalToSeconds() {
    const valueIsEquivalentTo30MinInSeconds = 60 * 30; // 1800
    const valueInSeconds =
      scheduleState.minimumInterval * valueIsEquivalentTo30MinInSeconds;

    return valueInSeconds || 3600;
  }

  function formatMinimumIntervalToText() {
    const valueIsEquivalentTo30MinInSeconds = 60 * 30; // 1800
    const valueInSeconds =
      scheduleState.minimumInterval * valueIsEquivalentTo30MinInSeconds;

    const hours = String(Math.floor(valueInSeconds / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((valueInSeconds % 3600) / 60)).padStart(
      2,
      "0"
    );

    const hoursText = Number(hours) > 1 ? "hours" : "hour";
    const minutesText = "minutes";
    // const minutesText = Number(minutes) > 1 ? "minutes" : "minute";

    const text = `${hours} ${hoursText} and ${minutes} ${minutesText}`;

    return text;
  }

  function handleOnClickInEdit(
    stepName: keyof typeof CREATE_CAMPAIGN_STEP_NAMES_TO_KEYS
  ) {
    createCampaignActions.toStepOnClickInEdit(stepName);
  }

  useEffect(() => {
    createCampaignActions.setStepWasVisited<CreateCampaingStepNames>("REVIEW");
  }, []);

  return {
    handleOnClickInEdit,
    sendForm,
    _updateCampaign,
    segmentation,
    notificationList,
    schedule,
    isLoading,
    segmentationActions,
    spotListActions,
    messageContentActions,
    scheduleActions,
    segmentationState,
    spotListState,
  };
}
