import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { CreateCampaingStepNames, useCreateCampaignStore } from "../store/createCampaign";
import { Notification, useMessageContentStore } from "../store/messageContent";

export type EventTimeTypes = "on_entering" | "on_exiting" | "after";

interface OnSubmitNewNotificationProps {
  title: string;
  message: string;
}

const optionsToDropdownTime = [
  {
    id: 1,
    text: "1 min",
  },
  {
    id: 2,
    text: "2 min",
  },
  {
    id: 3,
    text: "3 min",
  },
  {
    id: 4,
    text: "4 min",
  },
  {
    id: 5,
    text: "5 min",
  },
];

export function useMessageContent() {
  const { actions: messageContentActions, state: messageContentState } =
    useMessageContentStore();

  const { actions: createCampaignActions } = useCreateCampaignStore();

  const [platformSelectedId, setPlatformSelectedId] = useState(
    messageContentState.optionsToTextSwitcher[0].id
  );

  const [notificationPreviewMobileIsOpen, setNotificationPreviewMobileIsOpen] =
    useState(false);
  const [eventTime, setEventTime] = useState<EventTimeTypes>("on_entering");
  const [isDropdownTimeEventOpen, setIsDropdownTimeEventOpen] = useState(false);
  const [dropdownTimeEventValue, setDropdownTimeEventValue] = useState(
    optionsToDropdownTime[0]
  );
  const [notificationDropdownOpen, setNotificationDropdownOpen] = useState(false)

  const refDropdownTimeEvent = useRef<HTMLButtonElement>(null);

  const platformSelected = messageContentState.optionsToTextSwitcher.find(
    (option) => option.id === platformSelectedId
  );

  const schema = yup.object().shape({
    title: yup.string().required("Title is required"),
    message: yup.string().required("Message is required"),
  });

  function handleOnSelectPlatform(optionId: number) {
    const platform = messageContentState.optionsToTextSwitcher.find(
      (option) => option.id === optionId
    );

    if (platform) setPlatformSelectedId(platform.id);
  }

  function onSubmitNewNotification(data: OnSubmitNewNotificationProps) {
    const notification: Notification = {
      title: data.title,
      body: data.message,
      event_type: eventTime,
      minute: dropdownTimeEventValue.id,
    };

    messageContentActions.addNotification(notification);
    setNotificationDropdownOpen(true)
    resetForm();
  }

  function resetForm() {
    setEventTime("on_entering");
    setDropdownTimeEventValue(optionsToDropdownTime[0]);
    setValue("title", "");
    setValue("message", "");
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<OnSubmitNewNotificationProps>({
    resolver: yupResolver(schema),
  });

  const watchTitle = watch("title");
  const watchMessage = watch("message");

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        refDropdownTimeEvent.current &&
        !refDropdownTimeEvent.current.contains(event.target as Node) &&
        isDropdownTimeEventOpen
      ) {
        setIsDropdownTimeEventOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownTimeEventOpen]);

  useEffect(() => {
    messageContentActions.updatePlaformImageWhenDarkMode();
    messageContentActions.setNotificationError("");

    createCampaignActions.setStepWasVisited<CreateCampaingStepNames>("MESSAGE_CONTENT");
  }, []);

  useEffect(() => {
    if (
      messageContentState?.notificationsList?.length > 0 &&
      messageContentState?.notificationError
    ) {
      messageContentActions.setNotificationError("");
    }
  }, [messageContentState?.notificationsList]);

  return {
    handleOnSelectPlatform,
    platformSelected,
    eventTime,
    setEventTime,
    register,
    handleSubmit,
    errors,
    onSubmitNewNotification,
    control,
    watchTitle,
    watchMessage,
    optionsToDropdownTime,
    isDropdownTimeEventOpen,
    setIsDropdownTimeEventOpen,
    dropdownTimeEventValue,
    setDropdownTimeEventValue,
    refDropdownTimeEvent,
    optionsToTextSwitcher: messageContentState.optionsToTextSwitcher,
    notificationPreviewMobileIsOpen,
    setNotificationPreviewMobileIsOpen,
    notificationDropdownOpen,
    setNotificationDropdownOpen
  };
}
