import { i18n } from "@group-link-one/grouplink-components";
import { create } from "zustand";

import { CampaignIntervalConfig } from "../../OnlineCampaign/types/onlineCampaigns.types";

interface ScheduleStoreState {
  startDate: string | Date;
  endDate: string | Date | undefined;
  selectedDay: Date;
  minimumInterval: number;
  scheduleSendValue: {
    times: string;
    date: string;
  };
  startDateValueFormatted: string;
  endDateValueFormatted: string;
  dropdownBoxOptionsIsOpen: boolean;
  campaign_interval?: CampaignIntervalConfig | undefined;
}

interface ScheduleStoreActions {
  setSelectedDay: (selectedDay: Date) => void;
  setScheduleSendValueDate: (scheduleSendValueDate: string) => void;
  setScheduleSendValueTimes: (scheduleSendValueTime: string) => void;
  setDropdownBoxOptionsIsOpen: (dropdownBoxOptionsIsOpen: boolean) => void;
  setMinimumInterval: (minimumInterval: number) => void;
  setStartDate: (selectedDay: Date | string) => void;
  setEndDate: (selectedDay: Date | string) => void;
  setCampaignConfig: (config: CampaignIntervalConfig) => void;
  resetAll: () => void;
  setStartDateFormatted: (startDate: string) => void;
  setEndDateFormatted: (endDate: string) => void;
}

interface ScheduleStore {
  state: ScheduleStoreState;
  actions: ScheduleStoreActions;
}

export const useScheduleStore = create<ScheduleStore>((set) => ({
  state: {
    selectedDay: new Date(),
    scheduleSendValue: {
      times: i18n.t(""),
      date: i18n.t(""),
    },
    dropdownBoxOptionsIsOpen: false,
    maximumTimes: 1,
    minimumInterval: 0,
    startDate: new Date().toISOString(),
    endDate: undefined,
    startDateValueFormatted: "",
    endDateValueFormatted: "",
  },
  actions: {
    setSelectedDay: (selectedDay) =>
      set(({ state }) => ({ state: { ...state, selectedDay } })),

    setStartDate: (startDate) =>
      set(({ state }) => ({ state: { ...state, startDate } })),

    setEndDate: (endDate) =>
      set(({ state }) => ({ state: { ...state, endDate } })),

    setStartDateFormatted: (startDate) =>
      set(({ state }) => ({
        state: { ...state, startDateValueFormatted: startDate },
      })),

    setEndDateFormatted: (endDate) =>
      set(({ state }) => ({
        state: { ...state, endDateValueFormatted: endDate },
      })),

    setScheduleSendValueDate: (setScheduleSendValueDate) =>
      set(({ state }) => ({
        state: {
          ...state,
          scheduleSendValue: {
            ...state.scheduleSendValue,
            date: setScheduleSendValueDate,
          },
        },
      })),

    setScheduleSendValueTimes: (setScheduleSendValueTime) =>
      set(({ state }) => ({
        state: {
          ...state,
          scheduleSendValue: {
            ...state.scheduleSendValue,
            times: setScheduleSendValueTime,
          },
        },
      })),

    setDropdownBoxOptionsIsOpen: (dropdownBoxOptionsIsOpen) =>
      set(({ state }) => ({ state: { ...state, dropdownBoxOptionsIsOpen } })),

    setMinimumInterval: (minimumInterval) =>
      set(({ state }) => ({ state: { ...state, minimumInterval } })),

    setCampaignConfig: (campaign_interval) =>
      set(({ state }) => ({
        state: {
          ...state,
          campaign_interval,
        },
      })),

    resetAll: () =>
      set(() => ({
        state: {
          endDateValueFormatted: "",
          startDateValueFormatted: "",
          selectedDay: new Date(),
          scheduleSendValue: {
            times: i18n.t(
              "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.four"
            ),
            date: i18n.t(
              "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.six"
            ),
          },
          dropdownBoxOptionsIsOpen: false,
          maximumTimes: 1,
          minimumInterval: 2,
          startDate: new Date(),
          endDate: new Date(),
        },
      })),
  },
}));
