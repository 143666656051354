import {
  AuditRow,
  useAuditStore,
  useGLPagination,
  useI18n,
  useInfiniteScroll,
  useToast,
} from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useMemo } from "react";

import { useAuditService } from "../../../Services/auditService/useAuditService";

export const AUDIT_LIST_IPP = 25;

export const useAuditContent = () => {
  const { state: paginationState, actions: paginationActions } =
    useGLPagination();
  const { t } = useI18n();
  const { addToast } = useToast();
  const { getAudits } = useAuditService();
  const { onInfiniteScroll } = useInfiniteScroll();
  const { state: auditState } = useAuditStore();
  const queryClient = useQueryClient();

  const activeTab = useMemo(() => {
    return paginationActions.getActiveTabById(paginationState.activeTabId);
  }, [paginationState.activeTabId]);

  const {
    data: audits,
    isLoading: auditIsLoading,
    isError: auditIsErrored,
  } = useQuery({
    queryKey: [
      "get-audits",
      auditState.categoryFilter || "",
      format(new Date(auditState.range.from!), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      format(new Date(auditState.range.to!), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    ],
    queryFn: async () => {
      try {
        const { nextPageToken, optionsToStoreNextPageToken } =
          paginationActions.getNextPageToken();

        const options: any = {
          ipp: AUDIT_LIST_IPP,
          next_page_token: nextPageToken,
          start_date: format(
            new Date(auditState.range.from!),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          ),
          end_date: format(
            new Date(auditState.range.to!),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          ),
        };

        if (auditState.categoryFilter?.length > 0) {
          options.category = auditState.categoryFilter;
          options.start_date = format(
            new Date(auditState.range.from!),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          );
          options.end_date = format(
            new Date(auditState.range.to!),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          );
        }

        const response = await getAudits(options);

        paginationActions.setNextPageToken({
          hasMore: response.has_more,
          nextPageToken: response.next_page_token || undefined,
          optionsToStoreNextPageToken,
        });

        if (
          response.rows.length === 0 &&
          auditState.categoryFilter.length > 0 &&
          auditState.range.from &&
          auditState.range.to
        ) {
          addToast({
            title: t("audit.list.emptyList.auditNotFound.title"),
            message: t("audit.list.emptyList.auditNotFound.description"),
            type: "info",
          });
        }

        return response.rows;
      } catch (error) {
        console.log(error);
      }

      return;
    },
  });

  async function getMoreDevicesOnScroll() {
    const activeTabIdMap = {
      "tab-1": {
        do: async (options: any) => {
          return await getAudits(options);
        },
        cachekey: "get-audits",
      },
    };

    if (paginationState.isFetchingInfiniteScroll) return;

    const { nextPageToken, optionsToStoreNextPageToken } =
      paginationActions.getNextPageToken();

    if (!nextPageToken) return;

    const options: any = {
      ipp: AUDIT_LIST_IPP,
      next_page_token: nextPageToken,
      start_date: format(
        new Date(auditState.range.from!),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      end_date: format(
        new Date(auditState.range.to!),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
    };

    if (auditState.categoryFilter?.length > 0) {
      options.category = auditState.categoryFilter;
      options.start_date = format(
        new Date(auditState.range.from!),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      );
      options.end_date = format(
        new Date(auditState.range.to!),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      );
    }

    paginationActions.setIsFetchingInfiniteScroll(true);

    const tabMapActive =
      activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap];

    const response = await tabMapActive.do(options);

    paginationActions.setIsFetchingInfiniteScroll(false);

    paginationActions.setNextPageToken({
      hasMore: response.has_more,
      nextPageToken: response.next_page_token || undefined,
      optionsToStoreNextPageToken,
    });

    const currentAuditList: AuditRow[] | undefined = queryClient.getQueryData([
      tabMapActive.cachekey,
      auditState.categoryFilter || "",
      format(new Date(auditState.range.from!), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      format(new Date(auditState.range.to!), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    ]);

    if (!currentAuditList) return;

    queryClient.setQueryData(
      [
        tabMapActive.cachekey,
        auditState.categoryFilter || "",
        format(
          new Date(auditState.range.from!),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        format(new Date(auditState.range.to!), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      ],
      [...currentAuditList, ...response.rows]
    );
  }

  function invalidateAllQueries() {
    queryClient.invalidateQueries({
      queryKey: ["get-audits"],
    });
  }

  const isToShowAuditList =
    !auditIsLoading && !auditIsErrored && audits && audits?.length > 0;

  const showFilteredEmptyList =
    !auditIsLoading &&
    !auditIsErrored &&
    audits?.length === 0 &&
    auditState.categoryFilter.length > 0;

  const isToShowEmptyList = audits && audits?.length === 0;

  const isToShowError = !auditIsLoading && auditIsErrored;

  useEffect(() => {
    paginationActions.tabsActions.setOnClick(paginationState.tabs[0], () => {
      paginationActions.setCurrentPage(0);
      paginationActions.setActiveTab(1);
    });

    invalidateAllQueries();
  }, []);

  useEffect(() => {
    paginationActions.setCurrentPage(0);
  }, [paginationState.search]);

  return {
    activeTab,
    tabs: paginationState.tabs,
    paginationState,
    paginationActions,
    audits,
    auditIsLoading,
    isToShowAuditList,
    isToShowEmptyList,
    isToShowError,
    showFilteredEmptyList,
    getMoreDevicesOnScroll,
    onInfiniteScroll,
  };
};
