import { GLCard } from "@group-link-one/grouplink-components";
import { format } from "date-fns";

import {
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesPendingActivationResponse,
} from "../../../Services/deviceListService/useDeviceListService.types";
import { useDeviceListCard } from "./useDeviceListCard";

interface DeviceListCardProps {
  item:
    | GetDevicesActivatedLastReadingsResponse
    | GetDevicesPendingActivationResponse;
}

export function DeviceListCard({ item }: DeviceListCardProps) {
  const { isMobile, onDetailsClick, onAddressClick } = useDeviceListCard();

  function isPendingActivationCard(
    card: any
  ): card is GetDevicesPendingActivationResponse {
    return (
      (card as GetDevicesPendingActivationResponse).description !== undefined
    );
  }

  function isActivatedCard(
    card: any
  ): card is GetDevicesActivatedLastReadingsResponse {
    return (
      (card as GetDevicesActivatedLastReadingsResponse).device_id !== undefined
    );
  }

  if (isPendingActivationCard(item)) {
    return (
      <GLCard
        key={item.device_id}
        variant="sr-device-list-card"
        id={String(item.device_id) || "--"}
        type="pending"
        isMobile={isMobile}
      />
    );
  }

  const hasAlerts = item.channels.some((channel) => channel.alerts?.length > 0);

  if (isActivatedCard(item)) {
    return (
      <GLCard
        key={item.device_id}
        variant="sr-device-list-card"
        id={String(item.device_id) || "--"}
        address={item.meta.display_address || "--"}
        cardType={item.meta.device_measurement_category}
        registeredAt={format(
          new Date(item.channels[0].activated_at),
          "MMM d, yyyy"
        )}
        activated_by={{
          name: item.channels[0].activated_by || "--",
          email: item.channels[0].activated_by_email || "--",
        }}
        lastEvent={item.last_seen}
        type="active"
        hasAlerts={hasAlerts}
        name={item.channels[0].name || "--"}
        style={{
          borderRadius: "8px",
        }}
        isMobile={isMobile}
        onAddressClick={() =>
          onAddressClick(item.meta.latitude, item.meta.longitude)
        }
        onOpenDetails={() => onDetailsClick(item)}
      />
    );
  }

  return null;
}
