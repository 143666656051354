import {
  Colors,
  GL_COLORS,
  GLBarChartDataProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { addDays, differenceInDays, format } from "date-fns";
import { useMemo } from "react";

import { useDetailsDeviceStore } from "../../../store/details-device-store";

export const useEntryAndExitEvents = () => {
  const { state: deviceDetailsState } = useDetailsDeviceStore();
  const { t } = useI18n();

  const bars = [
    {
      fill: GL_COLORS.ACCENT_COLOR,
      dataKey: "x",
      name: "In",
    },
    {
      fill: Colors.GL_BLUE,
      dataKey: "y",
      name: "Out",
    },
  ];

  const data = useMemo(() => {
    if (
      !deviceDetailsState.currentData ||
      !deviceDetailsState.range.from ||
      !deviceDetailsState.range.to
    )
      return [];

    const dailyReadginsFormattedByDate = deviceDetailsState.currentData.reduce(
      (acc: GLBarChartDataProps[], current) => {
        const date = format(new Date(current.event_day), "MM/dd/yyyy");
        const existingDate = acc.find((item) => item.label === date);

        if (existingDate) {
          if (current.event_type === "EVENT_OUT") {
            existingDate.y! += current.events_count;
          }

          if (current.event_type === "EVENT_IN") {
            existingDate.x! += current.events_count;
          }
        } else {
          if (current.event_type === "EVENT_OUT") {
            acc.push({
              label: date,
              x: 0,
              y: current.events_count,
              valueType: "number",
            });
          }

          if (current.event_type === "EVENT_IN") {
            acc.push({
              label: date,
              x: current.events_count,
              y: 0,
              valueType: "number",
            });
          }
        }

        return acc;
      },
      []
    );

    const countAllDays = differenceInDays(
      deviceDetailsState.range.to,
      deviceDetailsState.range.from
    );

    const allDays: GLBarChartDataProps[] = Array.from({
      length: countAllDays + 1,
    }).map((_, index) => {
      const date = format(
        addDays(new Date(deviceDetailsState.range.from!), index),
        "MM/dd/yyyy"
      );

      const existingDate = dailyReadginsFormattedByDate.find(
        (item) => item.label === date
      );

      if (existingDate) {
        return {
          label: date,
          x:
            dailyReadginsFormattedByDate.find((item) => item.label === date)
              ?.x || 0,
          y:
            dailyReadginsFormattedByDate.find((item) => item.label === date)
              ?.y || 0,
          valueType: "number",
        };
      } else {
        return {
          label: date,
          x: 0,
          y: 0,
          valueType: "number",
        };
      }
    });

    return allDays;
  }, [deviceDetailsState.currentData]);

  return {
    t,
    data,
    deviceDetailsState,
    bars,
  };
};
