import {
  GL_COLORS,
  GLBarChart,
  GLBox,
  GLBoxChart,
  GLPieChart,
} from "@group-link-one/grouplink-components";
import React from "react";

import { OverviewGraphsContainer } from "./OverviewGraphsStyle";
import { useOverviewGraphs } from "./useOverviewGraphs";

export function OverviewGraphs() {
  const {
    graphOSData,
    graphTypeEventsData,
    graphPushesData,
    bars,
    isSM,
    isLoading,
    pushOSLoading,
    t,
  } = useOverviewGraphs();

  return (
    <OverviewGraphsContainer direction="column" gap={isSM ? 15 : 20}>
      <GLBox gap={20}>
        <GLBox
          width="60%"
          style={{
            borderRadius: 10,
            border: `1px solid ${GL_COLORS.BORDER_COLOR}`,
          }}
        >
          <GLBoxChart
            title={t("messageCenter.overview.graphs.labels.pushPerOS")}
            marginBottomTitle={0}
            hasBorder={false}
            isLoading={pushOSLoading}
            tooltipText={t(
              "messageCenter.overview.graphs.tooltips.pushesPerSO"
            )}
            chart={
              <GLPieChart
                data={graphOSData}
                pieChartOptions={{
                  outerRadius: 110,
                  innerRadius: 80,
                }}
                defaultDescription={t(
                  "messageCenter.overview.graphs.labels.pushes"
                )}
                defaultValueAsTotal
                hasDefaultValue
                hasTooltip
              />
            }
            textSwitcherId="os"
          />

          <GLBoxChart
            title={t("messageCenter.overview.graphs.labels.events")}
            marginBottomTitle={0}
            hasBorder={false}
            isLoading={pushOSLoading}
            tooltipText={t("messageCenter.overview.graphs.tooltips.events")}
            chart={
              <GLPieChart
                data={graphTypeEventsData}
                pieChartOptions={{
                  outerRadius: 110,
                  innerRadius: 80,
                }}
                defaultDescription={t(
                  "messageCenter.overview.graphs.labels.events"
                )}
                defaultValueAsTotal
                hasDefaultValue
                hasTooltip
              />
            }
            textSwitcherId="os"
          />
        </GLBox>

        <GLBoxChart
          title={t("messageCenter.overview.graphs.labels.pushPerHour")}
          tooltipText={t(
            "messageCenter.overview.graphs.tooltips.pushesPerHour"
          )}
          textSwitcherId="os"
          marginBottomTitle={0}
          isLoading={isLoading}
          chart={<GLBarChart data={graphPushesData} bars={bars} />}
        />
      </GLBox>
    </OverviewGraphsContainer>
  );
}
