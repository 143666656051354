import { useI18n, useToast } from "@group-link-one/grouplink-components";
import { useState } from "react";

import { useMessageContentServices } from "../../../../Services/messageCenterService/MessageContentServices/useMessageContentServices";
import { useMessageCenterStore } from "../../store/messageCenter";
import { useOnlineCampaignContent } from "../OnlineCampaign/Content/useOnlineCampaignContent";

export const useModalDeleteCampaign = () => {
  const { deleteCampaign } = useMessageContentServices();
  const { addToast } = useToast();
  const { actions } = useMessageCenterStore();
  const { t } = useI18n();

  const { refetchAll } = useOnlineCampaignContent();

  const [isLoading, setIsLoading] = useState(false);

  const deleteCampaignById = (id: number) => {
    setIsLoading(true);

    deleteCampaign(id)
      .then((res) => {
        if (res && res.status === 202) {
          addToast({
            title: t(
              "messageCenter.list.modal.creationModal.messages.campaignDeleted"
            ),
            message: t(
              "messageCenter.list.modal.creationModal.messages.campaignDeletedDescription"
            ),
            type: "success",
          });

          refetchAll();

          actions.setModalDeleteIsOpen(false);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    deleteCampaignById,
    isLoading,
  };
};
