import { useAuth } from "@group-link-one/gl-auth";
import {
  GLPageNotFound,
  useGLModalLogout,
} from "@group-link-one/grouplink-components";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useFlags } from "../Context/FlagsProvider";
import Logo404 from "../images/art_404.svg";
import RightLogo404 from "../images/bg_404.svg";
import GLLogoDark from "../images/Common/logo-glsmartretail-dark.svg";
import GLLogo from "../images/Common/logo-glsmartretail-light.svg";
import { AuditLayout } from "../layouts/AuditLayout/AuditLayout";
import { DashboardLayout } from "../layouts/DashboardLayout/DashboardLayout";
import { DevelopersLayout } from "../layouts/DevelopersLayout/DevelopersLayout";
import { EventListLayout } from "../layouts/EventListLayout/EventListLayout";
import { MainLayout } from "../layouts/MainLayout/MainLayout";
import { MessageCenterLayout } from "../layouts/MessageCenterLayout/MessageCenterLayout";
import { OnboardLayout } from "../layouts/OnboardLayout/OnboardLayout";
import { PageLayout } from "../layouts/PageLayout";
import { ReportLayout } from "../layouts/ReportLayout/ReportLayout";
import { SettingsLayout } from "../layouts/SettingsLayout/SettingsLayout";
import { Audit } from "../Pages/Audit/Audit";
import { DeviceList } from "../Pages/DeviceList/DeviceList";
import { EventList } from "../Pages/EventList/eventList";
import { GroupList } from "../Pages/GroupList/GroupList";
import HeatMap from "../Pages/HeatMap/heatMap";
import { OnlineCampaign } from "../Pages/MessageCenter/Campaign/OnlineCampaign/onlineCampaign";
import { MessageList } from "../Pages/MessageCenter/MessageList/MessageList";
import { NewPassword } from "../Pages/Onboard/CreateNewPassword/newPassword";
import { SignUp } from "../Pages/Onboard/SignUp/signUp";
import { Overview } from "../Pages/Overview/Overview";
import { Reports } from "../Pages/ReportList/ReportList";
import { Apps } from "../Pages/Settings/Apps/apps";
import { GroupsUsers } from "../Pages/Settings/GroupsUsers/organizationUsers";
import { LoginSecurity } from "../Pages/Settings/LoginSecurity/loginSecurity";
import { MyAccount } from "../Pages/Settings/MyAccount/myAccount";
import { Organizations } from "../Pages/Settings/Organizations/organizations";
import { SettingsOverview } from "../Pages/Settings/Overview/overview";
import { Users } from "../Pages/Settings/Users/organizationUsers";
import { VerifyRoute } from "./VerifyRoute";

export const AuthenticatedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { manipulateReduxValues, accessToken, accessTokenIsValid } = useAuth();
  const { hasPrivileges, user } = useFlags();

  const { handleOpenModalLogout, modalLogoutIsOpenOpen } = useGLModalLogout();

  const indexRoute = useMemo(() => {
    if (hasPrivileges(["devices_activated_last_reading"])) return "/dashboard";

    return hasPrivileges(["apps_get"])
      ? "/message-center/online-campaigns"
      : "/settings/overview";
  }, [user]);

  useEffect(() => {
    manipulateReduxValues("SET_ACTION_ON_IDLE", handleOpenModalLogout);
  }, []);

  useEffect(() => {
    if (accessToken && !accessTokenIsValid && !modalLogoutIsOpenOpen) {
      handleOpenModalLogout();
    }
  }, []);

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route
          element={<VerifyRoute needAuth={true} element={<MainLayout />} />}
        >
          <Route
            path="network"
            element={
              <VerifyRoute needAuth={true} element={<EventListLayout />} />
            }
          >
            <Route
              path="event-list"
              element={<VerifyRoute needAuth={true} element={<EventList />} />}
            />
          </Route>

          <Route
            path="settings"
            element={
              <VerifyRoute needAuth={true} element={<SettingsLayout />} />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Navigate to="overview" />}
                />
              }
            />
            <Route
              path="overview"
              element={
                <VerifyRoute needAuth={true} element={<SettingsOverview />} />
              }
            />
            <Route
              path="my-account"
              element={<VerifyRoute needAuth={true} element={<MyAccount />} />}
            />
            <Route
              path="login-security"
              element={
                <VerifyRoute needAuth={true} element={<LoginSecurity />} />
              }
            />

            <Route
              path="users"
              element={<VerifyRoute needAuth={true} element={<Users />} />}
            />
            <Route
              path="organizations"
              element={
                <VerifyRoute needAuth={true} element={<Organizations />} />
              }
            />

            <Route
              path="groups-users"
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<GroupsUsers />}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges(["organization_user_group"])}
                />
              }
            />

            <Route
              path="audit"
              element={<VerifyRoute needAuth={true} element={<Audit />} />}
            />
          </Route>

          <Route path="dashboard" element={<DashboardLayout />}>
            <Route
              index
              element={<VerifyRoute needAuth={true} element={<Overview />} />}
            />
            {/* <Route
              index
              element={<VerifyRoute needAuth={true} element={<Dashboard />} />}
            /> */}
          </Route>

          <Route path="developers" element={<DevelopersLayout />}>
            <Route
              path="apps"
              element={<VerifyRoute needAuth={true} element={<Apps />} />}
            />

            {/* <Route
              path="integration"
              element={
                <VerifyRoute needAuth={true} element={<Integration />} />
              }
            /> */}
          </Route>

          <Route
            path="heat-map"
            element={<VerifyRoute needAuth={true} element={<HeatMap />} />}
          />

          <Route
            path="message-center"
            element={
              <VerifyRoute needAuth={true} element={<MessageCenterLayout />} />
            }
          >
            <Route
              path="online-campaigns"
              element={
                <VerifyRoute needAuth={true} element={<OnlineCampaign />} />
              }
            />

            <Route
              path="message-list"
              element={
                <VerifyRoute needAuth={true} element={<MessageList />} />
              }
            />
          </Route>

          <Route
            path="reports"
            element={<VerifyRoute needAuth={true} element={<ReportLayout />} />}
          >
            <Route
              index
              element={<VerifyRoute needAuth={true} element={<Reports />} />}
            />
          </Route>

          <Route
            path="audit"
            element={<VerifyRoute needAuth={true} element={<AuditLayout />} />}
          >
            <Route
              index
              element={<VerifyRoute needAuth={true} element={<Audit />} />}
            />
          </Route>

          <Route
            path="devices"
            element={<VerifyRoute needAuth={true} element={<PageLayout />} />}
          >
            {/* <Route index element={<DeviceList />} /> */}

            <Route
              index
              element={<VerifyRoute needAuth={true} element={<DeviceList />} />}
            />

            <Route
              path="groups"
              element={
                <VerifyRoute
                  needAuth={true}
                  onlyPrivileges
                  userHasPrivileges={hasPrivileges([
                    "organization_device_group",
                  ])}
                  element={<GroupList />}
                />
              }
            />
          </Route>

          <Route
            index
            path="/"
            element={
              <VerifyRoute
                needAuth={true}
                element={<Navigate to={indexRoute} />}
              />
            }
          />
        </Route>

        {accessToken && (
          <>
            <Route element={<OnboardLayout />}>
              <Route path="/join-organization" element={<SignUp />} />
              <Route path="/new-password" element={<NewPassword />} />
            </Route>

            <Route
              path="*"
              element={
                <GLPageNotFound
                  logoOptions={{
                    src: {
                      logoLight: GLLogo,
                      logoDark: GLLogoDark,
                    },
                    backgroundImage: RightLogo404,
                    alt: "GroupLink logo",
                    logoRight: Logo404,
                  }}
                  onClickButton={() => navigate("/")}
                  title="Oops!"
                  subtitle="Page not found"
                  description="It looks like you were detached from our IoT world..."
                />
              }
            />
          </>
        )}
      </Routes>
    </AnimatePresence>
  );
};
