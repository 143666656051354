import {
  DateRange,
  GL_COLORS,
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";
import { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import { useDetailsDeviceStore } from "../store/details-device-store";
import { useGetDailySummary } from "./Graphs/useGetDailySuummary";
import { useGetDowSummary } from "./Graphs/useGetDowSummary";

export const useModalDeviceDetails = () => {
  const { state: detailsDeviceState, actions: detailsDeviceActions } =
    useDetailsDeviceStore();

  const { getDevicesActivatedDailyReadings } = useDeviceListService();
  const { getDailyReadings } = useGetDailySummary();
  const { getDowReadings } = useGetDowSummary();

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    rootOptions: {
      width: !isMobile ? "fit-content" : "100%",
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? "transparent" : "inherit",
        alignItems: isMobile ? "flex-end" : "initial",
      },
    },
    inputOptions: {
      width: "fit-content",
    },
    boxOptions: {
      position: "bottom-right",
      style: {
        boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
      },
    },
    currentRelativeTime: detailsDeviceState.currentRelativeTime,
    currentDateRange: detailsDeviceState.currentDateRange,

    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      detailsDeviceActions.setCurrentRelativeTime(option);
    },

    setCurrentDateRange: (range?: DateRange) => {
      detailsDeviceActions.setCurrentDateRange(range);
    },

    onRangeChange: async (range?: DateRange) => {
      if (!detailsDeviceState.device) return;

      const { device_id } = detailsDeviceState.device;
      if (!range?.from || !range?.to || !device_id) return;

      detailsDeviceActions.setRange(range);
      detailsDeviceActions.setIsLoading(true);

      await Promise.all([
        getDailyReadings({ device_id, range }),
        getDowReadings({ device_id, range }),
      ]);

      detailsDeviceActions.setIsLoading(false);
    },
  };

  function onClose() {
    detailsDeviceActions.resetAll();
  }

  useEffect(() => {
    if (!detailsDeviceState.range.from || !detailsDeviceState.range.to) return;

    getDevicesActivatedDailyReadings({
      from: format(detailsDeviceState.range.from, "yyyy-MM-dd"),
      until: format(detailsDeviceState.range.to, "yyyy-MM-dd"),
    });
  }, []);

  return {
    isOpen: detailsDeviceState.modalIsOpen,
    detailsDeviceState,
    relativeFilterTimeOptions,
    onClose,
  };
};
