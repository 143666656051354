import {
  GL_CHEVRON_DOWN,
  GL_COLORS,
  GL_LOGIN,
  GL_LOGOUT,
  GL_SEND_MESSAGE,
  GLButton,
  GLCard,
  GLEmptyList,
  GLNotificationAndroid,
  GLNotificationIphone,
  GLNotificationListDropdown,
  GLNotificationPreview,
  GLTextAreaField,
  GLTextField,
  GLTextSwitcher,
  GLTypography,
  useI18n,
} from "@group-link-one/grouplink-components";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Controller } from "react-hook-form";
import { useMediaQuery } from "usehooks-ts";

import { useMessageContentStore } from "../store/messageContent";
import {
  MessageContentContainer,
  NotificationPreviewMobile,
} from "./messageContentStyle";
import { useMessageContent } from "./useMessageContent";

export function MessageContent() {
  const { t } = useI18n();
  const {
    handleOnSelectPlatform,
    platformSelected,
    eventTime,
    setEventTime,
    errors,
    control,
    handleSubmit,
    onSubmitNewNotification,
    watchMessage,
    watchTitle,
    setNotificationPreviewMobileIsOpen,
    notificationPreviewMobileIsOpen,
    // optionsToDropdownTime,
    // dropdownTimeEventValue,
    // isDropdownTimeEventOpen,
    // setIsDropdownTimeEventOpen,
    // setDropdownTimeEventValue,
    // refDropdownTimeEvent,
    optionsToTextSwitcher,
    notificationDropdownOpen,
    setNotificationDropdownOpen,
  } = useMessageContent();

  const { actions: messageContentActions, state: messageContentState } =
    useMessageContentStore();

  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <MessageContentContainer>
      <div className="message-content-left">
        <div>
          <GLTypography
            text={t(
              "messageCenter.list.modal.creationModal.steps.two.labels.createNotification"
            )}
            color="DISABLED_FONT_COLOR"
            fontSize={4}
            as="h3"
            style={{
              textTransform: "uppercase",
            }}
          />

          {messageContentState?.notificationError && (
            <GLTypography
              text={messageContentState?.notificationError}
              color="DANGER"
              fontSize={3.5}
              as="span"
              style={{
                display: "block",
                marginTop: 10,
              }}
            />
          )}

          <div
            className={`message-content-create-notification ${messageContentState?.notificationError ? "error" : ""}`}
          >
            <div className="notification-options">
              <div className="notification-choose-moment">
                <button
                  onClick={() => setEventTime("on_entering")}
                  className={eventTime === "on_entering" ? "active" : ""}
                >
                  <GL_LOGIN />
                  <span>
                    {t("messageCenter.list.card.notificationCard.on_entering")}
                  </span>
                </button>

                <button
                  onClick={() => setEventTime("on_exiting")}
                  className={eventTime === "on_exiting" ? "active" : ""}
                >
                  <GL_LOGOUT />
                  <span>
                    {t("messageCenter.list.card.notificationCard.on_exiting")}
                  </span>
                </button>
              </div>

              <form
                className="notification-form"
                onSubmit={handleSubmit(onSubmitNewNotification)}
              >
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <GLTextField
                      title={t(
                        "messageCenter.list.modal.creationModal.steps.two.labels.notificationTitle"
                      )}
                      placeholder={t(
                        "messageCenter.list.modal.creationModal.steps.two.placeholder.notificationTitle"
                      )}
                      characterLimit={80}
                      maxLength={80}
                      failure={!!errors.title?.message}
                      failureMessage={errors.title?.message}
                      {...field}
                    />
                  )}
                />

                {/* <GLCheckbox text="Include greeting" iconType="check" /> */}

                <Controller
                  control={control}
                  name="message"
                  render={({ field }) => (
                    <GLTextAreaField
                      title={t(
                        "messageCenter.list.modal.creationModal.steps.two.labels.notificationText"
                      )}
                      placeholder={t(
                        "messageCenter.list.modal.creationModal.steps.two.placeholder.notificationText"
                      )}
                      characterLimit={150}
                      max={150}
                      failure={!!errors.message?.message}
                      failureMessage={errors.message?.message}
                      {...field}
                    />
                  )}
                />

                <GLButton
                  text={t(
                    "messageCenter.list.modal.creationModal.steps.two.labels.saveButton"
                  )}
                  variant="outline"
                  align="center"
                  weight={600}
                  border={true}
                />
              </form>
            </div>

            {!isMobile && (
              <div
                className={`notification-platforms ${platformSelected?.text.toLocaleLowerCase()}`}
              >
                <GLNotificationPreview
                  notificationTitle={watchTitle}
                  notificationImage=""
                  notificationMessage={watchMessage}
                  androidImage=""
                  iphoneImage=""
                />
              </div>
            )}
          </div>
        </div>

        <GLNotificationListDropdown
          isOpen={notificationDropdownOpen}
          setIsOpen={setNotificationDropdownOpen}
          totalLength={messageContentState?.notificationsList?.length}
          content={
            <>
              {messageContentState?.notificationsList?.length > 0 &&
                messageContentState?.notificationsList?.map(
                  (notification, index) => (
                    <GLCard
                      variant="sr-notification-list-card"
                      key={index}
                      id={String(index + 1)}
                      message={notification.body}
                      title={notification.title}
                      type={notification.event_type}
                      minute={notification.minute}
                      onDelete={() => {
                        messageContentActions.removeNotification(
                          notification.body!
                        );
                      }}
                    />
                  )
                )}

              {messageContentState?.notificationsList?.length === 0 && (
                <GLEmptyList
                  icon={
                    <GL_SEND_MESSAGE
                      size={60}
                      fill={GL_COLORS.FONT_COLOR_VARIANT}
                    />
                  }
                  title="No notifications created"
                  description="Create a notification to see it here"
                />
              )}
            </>
          }
        />
      </div>

      <div className="message-content-right"></div>

      {isMobile && (
        <NotificationPreviewMobile>
          <div
            className="notification-preview-header"
            role="button"
            tabIndex={0}
            onClick={() =>
              setNotificationPreviewMobileIsOpen(
                !notificationPreviewMobileIsOpen
              )
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setNotificationPreviewMobileIsOpen(
                  !notificationPreviewMobileIsOpen
                );
              }
            }}
          >
            <GLTypography
              text="Notification preview"
              color="FONT_COLOR"
              fontSize={4}
              weight={600}
              as="p"
            />

            <GL_CHEVRON_DOWN size={12} fill={GL_COLORS.FONT_COLOR_DARK_GREY} />
          </div>

          <AnimatePresence mode="wait">
            {notificationPreviewMobileIsOpen && (
              <motion.div
                className="notification-preview-body"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
              >
                <div
                  className={`notification-platforms ${platformSelected?.text.toLocaleLowerCase()}`}
                >
                  <GLTextSwitcher
                    options={optionsToTextSwitcher}
                    onSelect={(option) => handleOnSelectPlatform(option)}
                  />

                  <AnimatePresence mode="wait">
                    {platformSelected && (
                      <motion.div
                        key={platformSelected.id}
                        className={"notification-platform-preview"}
                        initial={{
                          opacity: 0,
                          x: platformSelected.text === "iPhone" ? -20 : 20,
                        }}
                        animate={{
                          opacity: 1,
                          x: 0,
                        }}
                        exit={{
                          opacity: 0,
                          x: platformSelected.text === "iPhone" ? -20 : 20,
                        }}
                      >
                        <img src={platformSelected.image || ""} alt="" />

                        {platformSelected.text === "iPhone" ? (
                          <GLNotificationIphone
                            title={watchTitle}
                            message={watchMessage}
                          />
                        ) : (
                          <GLNotificationAndroid
                            title={watchTitle}
                            message={watchMessage}
                          />
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </NotificationPreviewMobile>
      )}

      <Helmet title="Message Center - Content" />
    </MessageContentContainer>
  );
}
