import { GLBox } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const OverviewBigNumbersContainer = styled(GLBox)`
  height: fit-content;

  @media (max-width: 1350px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  /*
  @media (max-width: 660px) {
    display: grid;
    grid-template-columns: 1fr;
  } */
`;
