import { DateRange } from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";

import { useDeviceListService } from "../../../../Services/deviceListService/useDeviceListService";
import { useDetailsDeviceStore } from "../../store/details-device-store";

interface GetDowReadingsParams {
  range: DateRange;
  device_id: number;
}

export const useGetDowSummary = () => {
  const { getDevicesDowSummary } = useDeviceListService();
  const { actions: detailsDeviceActions } = useDetailsDeviceStore();
  const queryClient = useQueryClient();

  async function getDowReadings({ device_id, range }: GetDowReadingsParams) {
    const dowReadings = await queryClient.fetchQuery({
      queryKey: [
        "getDevicesActivatedDowReadings",
        range.from,
        range.to,
        device_id,
      ],
      queryFn: async () => {
        if (!range.from || !range.to) return;

        const response = await getDevicesDowSummary({
          from: format(range.from, "yyyy-MM-dd"),
          until: format(range.to, "yyyy-MM-dd"),
          device_ids: [device_id],
          by_hour: true,
        });

        detailsDeviceActions.setCurrentDowData(response);

        return response;
      },
    });

    return dowReadings;
  }

  return {
    getDowReadings,
  };
};
