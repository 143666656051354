import { PaginateResponseProps } from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../../useAxiosHTTPRequest";
import {
  GetMessageListParams,
  GetMessageListResponseRow,
} from "./useMessageListService.types";

export const useMessageListServices = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getMessageList = async (
    data: GetMessageListParams
  ): Promise<PaginateResponseProps<GetMessageListResponseRow[]>> => {
    return (
      await httpPrivate.get("/sr/notification-list", {
        params: data,
      })
    ).data;
  };

  return {
    getMessageList,
  };
};
