import { useRef } from "react";

import { useSegmentationStore } from "../../store/segmentation";

export const useCampaignName = () => {
  const { actions: segmentationActions, state: segmentationState } =
    useSegmentationStore();

  const inputCampaignNameValue = segmentationState.campaignName;
  const hasErrorInInputCampaignName = !!segmentationState.campaignNameError;
  const inputCampaignNameMessageError = segmentationState.campaignNameError;

  const inputCampaignNameRef = useRef<HTMLInputElement>(null);

  function handleOnInput(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    segmentationActions.setCampaingName((e.target as HTMLInputElement).value);

    if (value.length > 0) {
      segmentationActions.setCampaignNameError("");
    }
  }

  return {
    inputCampaignNameValue,
    hasErrorInInputCampaignName,
    inputCampaignNameMessageError,
    inputCampaignNameRef,
    handleOnInput,
  };
};
