import {
  GL_COLORS,
  UNIVERSAL_PROPERTIES,
} from "@group-link-one/grouplink-components";
import { motion } from "framer-motion";
import styled from "styled-components";

export const SegmentationContainer = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  gap: 100px;

  .segmentation-content-left {
    display: flex;
    flex-direction: column;
    gap: 50px;

    position: relative;

    .segmentation-source-apps {
      > p {
        margin-bottom: 30px;
      }

      .segmentation-apps-list {
        height: 305px;
        border-radius: 16px;
        border: 1px solid ${GL_COLORS.BORDER_COLOR};
        overflow-y: auto;
        padding: 20px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        .segmentation-list-empty {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;

          img {
            width: 100px;
          }
        }

        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${GL_COLORS.FONT_COLOR_DARK_GREY};
          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
        }

        &.error {
          border: 1px solid ${GL_COLORS.DANGER};
        }
      }

      .error-message {
        display: inline-block;
        margin-top: 5px;
      }
    }

    .segmentation-list-spots {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .list-spots-header {
        display: flex;
        flex-direction: column;

        > div {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          gap: 20px;

          p {
            color: ${GL_COLORS.FONT_COLOR};
            font-size: ${UNIVERSAL_PROPERTIES.FONT_SIZE_SECONDARY};
          }
        }
      }

      .gl-checkbox__container {
        .gl-checkbox__text {
          font-size: ${UNIVERSAL_PROPERTIES.FONT_SIZE_SECONDARY};
          color: ${GL_COLORS.ACCENT_COLOR};
          font-weight: 600;
        }
      }

      .device-empty-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        grid-column: 1/4;

        padding: 60px 0;

        img {
          width: 60px;
        }
      }
    }
  }

  .segmentation-content-right {
    > div {
      position: sticky;
      top: 40px;
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;

    .segmentation-content-right {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    padding-bottom: 120px;
  }
`;

export const ListFiltersTopContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ListFiltersBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  width: 100%;

  div:first-child {
    display: flex;
    align-items: center;

    > p {
      margin-left: 20px;
      padding-left: 20px;

      border-left: 1px solid ${GL_COLORS.BORDER_COLOR};

      span {
        display: inline-block;
        margin-right: 5px;
        font-weight: 600;
      }

      white-space: nowrap;
    }
  }
`;

export const SpotListPopup = styled(motion.div).attrs({
  initial: { opacity: 0, y: 20, translateX: "-50%" },
  animate: { opacity: 1, y: 0, translateX: "25%" },
  exit: { opacity: 0, y: 20, translateX: "-50%" },
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  background-color: ${GL_COLORS.BACKGROUND_PRIMARY};
  border: 1px solid ${GL_COLORS.BORDER_COLOR};
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 5px 10px #888e9e4d;

  position: sticky;
  bottom: 80px;
  left: 50%;
  z-index: 9999999;

  p {
    span {
      display: inline-block;
      margin-right: 5px;
      font-weight: 600;
    }

    white-space: nowrap;
  }

  > div {
    display: flex;
    gap: 40px;
  }

  @media (max-width: 1024px) {
    bottom: 0px;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-color: transparent;
    box-shadow: 0px -5px 15px #b2bdc533;
    padding: 20px;

    > div {
      gap: 20px;
    }
  }
`;
