import {
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
  GLRelativeListOptionID,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { useOverviewStore } from "../../MessageCenter/store/overview";
import { Range } from "../Overview.types";

export const useOverviewRightContent = () => {
  const { t } = useI18n();
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { actions: overviewActions, state: overviewState } = useOverviewStore();

  const options: GLRelativeListOptionID[] = [
    "last_5_minutes",
    "last_15_minutes",
    "last_30_minutes",
    "last_1_hour",
    "last_3_hours",
    "last_6_hours",
    "last_12_hours",
    "last_24_hours",
    "last_3_days",
    "last_7_days",
    "last_15_days",
    "last_30_days",
  ];

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    currentRelativeTime: overviewState.currentRelativeTime,
    currentDateRange: overviewState.currentDateRange,
    rootOptions: {
      width: "fit-content",
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? "transparent" : "inherit",
        alignItems: isMobile ? "flex-end" : "initial",
      },
    },
    boxOptions: {
      position: "bottom-right",
      width: !isMobile ? "600px" : "calc(100vw - 80px)",
      style: {
        zIndex: 9999,
      },
    },
    inputOptions: {
      placeholder: t("audit.filters.dateFilter.placeholder"),
      style: {
        zIndex: 999,
      },
    },
    options,

    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      overviewActions.setCurrentRelativeTime(option);
    },

    setCurrentDateRange: (range?: Range) => {
      overviewActions.setCurrentDateRange(range);
    },

    onRangeChange: async (range?: Range) => {
      overviewActions.setRange(range!);
    },
  };

  return {
    relativeFilterTimeOptions,
  };
};
