import {
  GL_COLORS,
  GL_GLStation,
  GLBox,
  GLList,
  GLLoading,
  GLTypography,
} from "@group-link-one/grouplink-components";

import { DeviceListCard } from "../Cards/DeviceListCard";
import {
  DeviceListAllDevicesContainer,
  DeviceListEmpty,
} from "./DeviceListContentStyle";
import { useDeviceListContent } from "./useDeviceListContent";

export function DeviceListContent() {
  const {
    activeTab,
    currentDeviceList,
    distanceFromHeader,
    listContainerRef,
    getMoreDevicesOnScroll,
    onInfiniteScroll,
    paginationState,
    isLoading,
    tabs,
  } = useDeviceListContent();

  return (
    <DeviceListAllDevicesContainer
      ref={listContainerRef}
      gap={30}
      direction="column"
      height="100%"
    >
      {!isLoading && (
        <GLList
          tabs={tabs}
          tabActive={activeTab?.name || ""}
          hasFixedHeaderAnimation={true}
          scrollPositionToActiveHeaderFixed={distanceFromHeader}
          isLoading={false}
          content={
            <>
              {currentDeviceList && currentDeviceList.length === 0 && (
                <DeviceListEmpty>
                  <div>
                    <GL_GLStation
                      size={30}
                      fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                    />
                  </div>

                  <GLTypography
                    text="No GL device found"
                    color="FONT_COLOR_DARK_GREY"
                    fontSize={5}
                    weight={600}
                    as="p"
                  />
                </DeviceListEmpty>
              )}

              {currentDeviceList &&
                currentDeviceList.length > 0 &&
                currentDeviceList.map((item, index) => (
                  <DeviceListCard key={index} item={item} />
                ))}

              {paginationState.isFetchingInfiniteScroll && (
                <GLBox align="center" justify="center">
                  <GLLoading type="dots" size="primary" />
                </GLBox>
              )}
            </>
          }
          mode="infinit-scroll"
          type={
            currentDeviceList && currentDeviceList.length > 0 ? "list" : "empty"
          }
          listName="Device List"
          onScroll={(event) =>
            onInfiniteScroll({ event, callback: getMoreDevicesOnScroll })
          }
        />
      )}

      {isLoading && (
        <GLList tabs={tabs} tabActive={tabs[0].name} isLoading={true} />
      )}
    </DeviceListAllDevicesContainer>
  );
}
