import {
  GL_CALENDAR,
  GL_CHECKIN,
  GL_REVIEW,
  GL_SEND_MESSAGE,
  GLModalSteperContentStoreActions,
  GLModalSteperContentStoreState,
  i18n,
} from "@group-link-one/grouplink-components";
import { create } from "zustand";

interface UsersStore {
  state: GLModalSteperContentStoreState;
  actions: GLModalSteperContentStoreActions;
}

export enum CREATE_CAMPAIGN_STEP_NAMES_TO_KEYS {
  SEGMENTATION = 0,
  MESSAGE_CONTENT = 1,
  SCHEDULE = 2,
  REVIEW = 3,
}

export type CreateCampaingStepNames =
  keyof typeof CREATE_CAMPAIGN_STEP_NAMES_TO_KEYS;

export const useCreateCampaignStore = create<UsersStore>((set) => ({
  state: {
    modalSteperContentIsOpen: false,
    stepActive: 0,
    steperIsOnTop: false,
    steps: [
      {
        key: "segmentation",
        Icon: <GL_CHECKIN />,
        title: i18n.t("messageCenter.list.modal.creationModal.steps.one.title"),
        wasVisited: true,
      },
      {
        key: "message-content",
        Icon: <GL_SEND_MESSAGE />,
        title: i18n.t("messageCenter.list.modal.creationModal.steps.two.title"),
        textToHere: i18n.t(
          "messageCenter.list.modal.creationModal.steps.one.headerButton"
        ),
        wasVisited: false,
      },
      {
        key: "schedule",
        title: i18n.t(
          "messageCenter.list.modal.creationModal.steps.three.title"
        ),
        Icon: <GL_CALENDAR />,
        textToHere: i18n.t(
          "messageCenter.list.modal.creationModal.steps.two.headerButton"
        ),
        wasVisited: false,
      },
      {
        key: "review",
        title: i18n.t(
          "messageCenter.list.modal.creationModal.steps.four.title"
        ),
        Icon: <GL_REVIEW />,
        textToHere: i18n.t(
          "messageCenter.list.modal.creationModal.steps.three.headerButton"
        ),
        wasVisited: false,
      },
    ].map((_, i) => ({ ..._, id: i })),
    modalType: "creation",
    isLoading: false,
    textToCreate: i18n.t(
      "messageCenter.list.modal.creationModal.steps.four.headerButtonCreation"
    ),
    textToEdit: i18n.t(
      "messageCenter.list.modal.creationModal.steps.four.headerButtonEdit"
    ),
  },
  actions: {
    setModalSteperContentIsOpen: (isOpen: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          modalSteperContentIsOpen: isOpen,
        },
      }));
    },

    setModalType: (type: GLModalSteperContentStoreState["modalType"]) => {
      set((state) => ({
        state: {
          ...state.state,
          modalType: type,
        },
      }));
    },

    setSteperIsOnTop: (isOnTop: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          steperIsOnTop: isOnTop,
        },
      }));
    },

    setStepActive: (step: number) => {
      set((state) => ({
        state: {
          ...state.state,
          stepActive: step,
        },
      }));
    },

    setStepWasVisited: (stepName) => {
      set((state) => ({
        state: {
          ...state.state,
          steps: state.state.steps.map((step) => {
            if (
              step.id ===
              CREATE_CAMPAIGN_STEP_NAMES_TO_KEYS[
                stepName as CreateCampaingStepNames
              ]
            ) {
              return {
                ...step,
                wasVisited: true,
              };
            }

            return step;
          }),
        },
      }));
    },

    setIsLoading: (isLoading: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          isLoading,
        },
      }));
    },

    toStepOnClickInEdit: (stepName) => {
      set((state) => ({
        state: {
          ...state.state,
          stepActive:
            CREATE_CAMPAIGN_STEP_NAMES_TO_KEYS[
              stepName as CreateCampaingStepNames
            ],
        },
      }));
    },

    resetAll: () => {
      set((state) => ({
        state: {
          ...state.state,
          modalSteperContentIsOpen: false,
          stepActive: 0,
          steperIsOnTop: false,
          modalType: "creation",
          isLoading: false,
        },
      }));
    },
  },
}));
