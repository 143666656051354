import { GLNotificationList } from "@group-link-one/grouplink-components";

import { EventListTableContent } from "./Content/TableContent";
import { useMessageList } from "./useMessageList";

export const MessageList = () => {
  const { relativeFilterTimeOptions, t } = useMessageList();

  return (
    <GLNotificationList
      relativeFilterTimeOptions={relativeFilterTimeOptions}
      tableContent={<EventListTableContent />}
      title={t("notificationList.subtitle")}
      subtitle={`${t("notificationList.title")}`}
      isDeviceIdSearchByEntireID={false}
      hasFilterByUserid
      hasFilterByRelativeTime
    />
  );
};
