import styled, { css } from "styled-components";

const flexColumn = (gap: number) => css`
  display: flex;
  flex-direction: column;
  gap: ${gap}px;
`;

export const ScheduleContainer = styled.div`
  ${flexColumn(30)}
  padding-bottom: 100px;

  .schedule-block {
    ${flexColumn(30)}
  }

  .schedule-range-radio {
    ${flexColumn(60)}
  }

  @media (max-width: 1024px) {
    padding-bottom: 300px;
  }
`;

export const CalendarDiv = styled.div`
  display: flex;
  justify-content: center;

  div {
    border: none;
  }
`