import {
  useI18n,
  useNotificationListStore,
  useToast,
} from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { useEffect, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useDeviceListService } from "../../../../Services/deviceListService/useDeviceListService";
import {
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
} from "../../../../Services/deviceListService/useDeviceListService.types";
import { useMessageListServices } from "../../../../Services/messageCenterService/messageListService/useMessageListService";
import { GetMessageListResponseRow } from "../../../../Services/messageCenterService/messageListService/useMessageListService.types";
import { queryClient } from "../../../../Services/queryClient";

export interface PushListResponse {
  eventCreated: string;
  device_id: string;
  title: string;
  message: string;
  user_id: string;
  event_type: "EVENT_IN" | "EVENT_OUT";
  os: string;
}

export const useTableContent = () => {
  const { t } = useI18n();
  const { getMessageList } = useMessageListServices();
  const { getDevicesActivatedLastReadings } = useDeviceListService();

  const { actions: notificationListActions, state: notificationListState } =
    useNotificationListStore();
  const { addToast } = useToast();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const columns = [
    {
      columnName: t("notificationList.columns.sent"),
      hasActions: false,
    },
    {
      columnName: t("notificationList.columns.user_id"),
      hasActions: false,
      width: 70,
    },
    {
      columnName: t("notificationList.columns.device_id"),
      hasActions: false,
      width: 70,
    },
    {
      columnName: t("notificationList.columns.title"),
      hasActions: false,
    },
    {
      columnName: t("notificationList.columns.message"),
      hasActions: false,
    },
    {
      columnName: t("notificationList.columns.OS"),
      hasActions: false,
      width: 40,
    },
    {
      columnName: t("notificationList.columns.eventTypeText"),
      hasActions: false,
      width: 60,
    },
  ];

  const dateTo = useMemo(() => {
    return format(
      new Date(notificationListState.range.from || new Date()),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    );
  }, [notificationListState.range.from]);

  const dateFrom = useMemo(() => {
    return format(
      new Date(notificationListState.range.to || new Date()),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    );
  }, [notificationListState.range.to]);

  const searchDeviceID = useMemo(() => {
    if (
      notificationListState.searchDeviceId.length >= 0 &&
      notificationListState.searchDeviceId.length < 10
    )
      return undefined;

    return isNaN(Number(notificationListState.searchDeviceId))
      ? undefined
      : [Number(notificationListState.searchDeviceId)];
  }, [notificationListState.searchDeviceId]);

  const {
    data: notificationList,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [
      "get-notification-list",
      dateTo,
      dateFrom,
      notificationListState.devices_id_to_request,
      notificationListState.userId,
    ],
    queryFn: async () => {
      if (
        notificationListState.devices_id_to_request.length > 0 ||
        notificationListState.userId
      ) {
        try {
          const response = await getMessageList({
            from: format(
              new Date(notificationListState.range.from || ""),
              "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
            ),
            until: format(
              new Date(notificationListState.range.to || ""),
              "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
            ),
            next_page_token: undefined,
            device_id: notificationListState.devices_id_to_request.map(Number),
            user_id: notificationListState.userId
              ? [Number(notificationListState.userId)]
              : undefined,
          });

          notificationListActions.setNextPageToken(
            response.next_page_token || ""
          );

          console.log(response);

          if (response.rows.length === 0) {
            notificationListActions.setEmptystate(
              t("eventList.list.emptyState.title2"),
              t("eventList.list.emptyState.description2")
            );

            addToast({
              title: `${t("attention")}!`,
              message: t("eventList.list.emptyState.description2"),
              type: "info",
            });

            return [];
          }

          notificationListActions.setError(false);

          return response.rows;
        } catch (err: unknown) {
          const error = err as AxiosError<Error>;
          if (
            error?.response?.data?.message[0] ===
              "from must be a valid ISO 8601 date string" ||
            error?.response?.data?.message[1] ===
              "until must be a valid ISO 8601 date string"
          ) {
            addToast({
              title: t("eventList.errors.title"),
              message: t("eventList.errors.message"),
              type: "error",
            });
            notificationListActions.setError(true);
          }
          notificationListActions.setError(true);
        }

        // return [];
      }
    },
  });

  const { data: devices_id, isLoading: deviceLoading } = useQuery({
    queryKey: ["get-devices-id-event-list", searchDeviceID],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const options: GetDevicesActivatedLastReadingsParams = {
        all_devices: true,
        skip_value_lookup: true,
        next_page_token: undefined,
        device_id: searchDeviceID,
        application: "GLSmartRetail",
      };

      const response = await getDevicesActivatedLastReadings(options);

      if (response.has_more && response.next_page_token) {
        notificationListActions.setNextPageTokenDevices(
          response.next_page_token
        );
      }

      return response.rows;
    },
  });

  async function getMoreEvents() {
    const devicesIDFormatted = notificationListState.devices_id_to_request?.map(
      (deviceid) => Number(deviceid)
    );

    if (!notificationListState.next_page_token) return [];

    notificationListActions.setIsFetchingMoreEvents(true);

    const response = await getMessageList({
      from: format(
        new Date(notificationListState.range.from || ""),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      until: format(
        new Date(notificationListState.range.to || ""),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      next_page_token: notificationListState.next_page_token,
      device_id: devicesIDFormatted,
      user_id: [Number(notificationListState.userId)],
    });

    notificationListActions.setIsFetchingMoreEvents(false);

    notificationListActions.setNextPageToken(response.next_page_token || "");

    if (response.rows.length === 0) {
      notificationListActions.setEmptystate(
        t("eventList.list.emptynotificationListState.title2"),
        t("eventList.list.emptynotificationListState.description2")
      );

      addToast({
        title: `${t("attention")}!`,
        message: t("eventList.list.emptynotificationListState.description2"),
        type: "info",
      });

      return;
    }

    notificationListActions.setError(false);

    return response.rows;
  }

  async function incrementDevicesID() {
    if (
      notificationListState.searchDeviceId.length > 0 ||
      !notificationListState.next_page_token_devices
    )
      return;

    const currentDevicesIDCached: GetDevicesActivatedLastReadingsResponse[] =
      (await queryClient.getQueryData([
        "get-devices-id-event-list",
        searchDeviceID,
      ])) || [];

    const options: GetDevicesActivatedLastReadingsParams = {
      all_devices: true,
      skip_value_lookup: true,
      next_page_token: notificationListState.next_page_token_devices,
      application: "GLSmartRetail",
    };

    notificationListActions.setIsFetchingMoreDevices(true);

    const newDevices = await getDevicesActivatedLastReadings(options);

    if (newDevices.has_more && newDevices.next_page_token) {
      notificationListActions.setNextPageTokenDevices(
        newDevices.next_page_token
      );
    }

    notificationListActions.setIsFetchingMoreDevices(false);

    const newDevicesID = currentDevicesIDCached
      .concat(newDevices.rows)
      .map((device) => device.device_id);
    const uniquesDevicesID = Array.from(new Set(newDevicesID));

    queryClient.setQueryData(
      ["get-devices-id-event-list", searchDeviceID],
      currentDevicesIDCached.concat(newDevices.rows)
    );

    notificationListActions.setDevicesId(uniquesDevicesID);
  }

  const isAtBottom = (event: React.UIEvent<HTMLElement>): boolean => {
    const finalScroll =
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight;

    return (
      finalScroll - event.currentTarget.scrollTop < 100 &&
      finalScroll - event.currentTarget.scrollTop > -100
    );
  };

  const handleScroll = async (event: React.UIEvent<HTMLElement>) => {
    if (!isAtBottom(event) || notificationListState.isFetchingMoreEvents)
      return;

    const moreEvents = await getMoreEvents();

    if (!moreEvents || moreEvents.length === 0) return;

    const currentData: GetMessageListResponseRow[] | undefined =
      queryClient.getQueryData([
        "get-message-list",
        format(
          new Date(notificationListState.range.from || ""),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        format(
          new Date(notificationListState.range.to || ""),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        notificationListState.devices_id_to_request,
        notificationListState.userId,
      ]);

    if (!currentData) return;

    queryClient.setQueryData(
      [
        "get-message-list",
        format(
          new Date(notificationListState.range.from || ""),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        format(
          new Date(notificationListState.range.to || ""),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        notificationListState.devices_id_to_request,
        notificationListState.userId,
      ],
      currentData.concat(moreEvents)
    );
  };

  useEffect(() => {
    notificationListActions.setFetchMoreDevices(() => {
      if (notificationListState.isFetchingMoreDevices) return;
      incrementDevicesID();
    });
  }, [
    notificationListState.isFetchingMoreDevices,
    notificationListState.next_page_token_devices,
    notificationListState.searchDeviceId,
  ]);

  useEffect(() => {
    notificationListActions.resetAll();
  }, []);

  useEffect(() => {
    if (devices_id === undefined) return;
    const devicesID = devices_id.map((device) => device.device_id);
    const uniquesDevicesID = Array.from(new Set(devicesID));

    notificationListActions.setDevicesId(uniquesDevicesID);
  }, [devices_id]);

  useEffect(() => {
    notificationListActions.setDeviceIsLoading(deviceLoading);
  }, [deviceLoading]);

  return {
    notificationList,
    handleScroll,
    devices_id,
    isLoading,
    isMobile,
    deviceLoading,
    notificationListActions,
    notificationListState,
    refetch,
    columns,
  };
};
