import { GLRelativeFilterTime } from "@group-link-one/grouplink-components";

import { useOverviewRightContent } from "./useOverviewRightContent";

export function OverviewRightContent() {
  const { relativeFilterTimeOptions } = useOverviewRightContent();

  return (
    <GLRelativeFilterTime {...relativeFilterTimeOptions} isMobile={false} />
  );
}
