import {
  GL_COLORS,
  UNIVERSAL_PROPERTIES,
} from "@group-link-one/grouplink-components";
import { motion } from "framer-motion";
import styled from "styled-components";

export const MessageContentContainer = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  gap: 100px;

  .message-content-left {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .message-content-create-notification {
      border: 1px solid ${GL_COLORS.BORDER_COLOR};
      border-radius: 16px;

      margin-top: 20px;

      display: grid;
      grid-template-columns: 0.6fr 0.4fr;
      gap: 40px;
      transition: all 0.4s;

      .notification-options {
        display: flex;
        flex-direction: column;
        padding: 30px 0 30px 30px;

        .notification-choose-moment {
          display: flex;
          align-items: center;
          gap: 15px;
          margin-bottom: 30px;

          > button {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 7px;

            height: 75px;

            border-radius: 10px;
            border: 1px solid ${GL_COLORS.BORDER_COLOR};
            background-color: ${GL_COLORS.BACKGROUND_PRIMARY};

            padding: 0px 20px;
            box-shadow:
              0px 3px 6px ${GL_COLORS.SHADOW_COLOR},
              0 0 0 3px transparent;

            cursor: pointer;
            transition: all 0.4s;

            .dropdown-time-event-value {
              display: flex;
              align-items: center;
              gap: 10px;
              color: ${GL_COLORS.FONT_COLOR};
              font-weight: 600;
              font-size: ${UNIVERSAL_PROPERTIES.FONT_SIZE_PRIMARY};

              svg {
                transition: all 0.4s;
                fill: ${GL_COLORS.FONT_COLOR_DARK_GREY};
              }

              &.active {
                svg {
                  transform: rotate(180deg);
                }
              }
            }

            > svg {
              fill: ${GL_COLORS.FONT_COLOR_DARK_GREY};
            }

            span {
              color: ${GL_COLORS.FONT_COLOR};
              font-size: ${UNIVERSAL_PROPERTIES.FONT_SIZE_SECONDARY};
            }

            &.active {
              box-shadow:
                0px 3px 6px transparent,
                0 0 0 3px ${GL_COLORS.ACCENT_COLOR};

              div {
                color: ${GL_COLORS.ACCENT_COLOR};

                svg {
                  fill: ${GL_COLORS.ACCENT_COLOR};
                }
              }

              > svg {
                fill: ${GL_COLORS.ACCENT_COLOR};
              }

              span {
                color: ${GL_COLORS.ACCENT_COLOR};
              }
            }
          }

          > .choose-moment-item-time {
            position: relative;
          }
        }

        .notification-form {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-bottom: 20px;

          .gl-checkbox__container {
            margin-bottom: 30px;
            margin-top: 5px;
          }

          > div + div {
            margin-top: 30px;
          }

          > button {
            margin-top: 30px;
          }
        }
      }

      .notification-platforms {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 30px 30px 0px 0px;
        grid-column: 2 / 5;

        .notification-platform-preview {
          position: relative;
          flex: 1;

          > div {
            position: absolute;
            top: 200px;
            overflow-y: visible;
          }

          img {
            height: 100%;
          }
        }
      }

      &.error {
        border-color: ${GL_COLORS.DANGER};
      }
    }
  }

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;

    .message-content-right {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    padding-bottom: 200px;

    .message-content-left {
      .message-content-create-notification {
        grid-template-columns: 1fr;

        .notification-options {
          padding: 30px;
        }
      }
    }
  }
`;

export const DropdownTimeEvent = styled(motion.div).attrs({
  initial: { opacity: 0, y: "90%", scale: 0.9 },
  animate: { opacity: 1, y: "100%", scale: 1 },
  exit: { opacity: 0, y: "97%", scale: 0.9 },
})`
  position: absolute;
  left: 0;
  bottom: -10px;

  width: 100%;
  background-color: ${GL_COLORS.BACKGROUND_PRIMARY};
  border-radius: 10px;
  border: 1px solid ${GL_COLORS.BORDER_COLOR};
  box-shadow: 0px 3px 6px ${GL_COLORS.SHADOW_COLOR};

  display: flex;
  flex-direction: column;
  overflow: hidden;

  z-index: 10;
  /* padding: 10px; */

  button {
    width: 100%;
    padding: 15px 20px;
    background-color: ${GL_COLORS.BACKGROUND_PRIMARY};
    border: none;
    text-align: left;
    cursor: pointer;
    transition: all 0.4s;

    font-size: ${UNIVERSAL_PROPERTIES.FONT_SIZE_SECONDARY_SM};

    &:hover {
      background-color: ${GL_COLORS.BACKGROUND_SECONDARY};
    }

    &.active {
      background-color: ${GL_COLORS.BACKGROUND_SECONDARY};
      color: ${GL_COLORS.ACCENT_COLOR};
    }
  }
`;

export const NotificationPreviewMobile = styled(motion.div)`
  height: auto;
  width: 100%;
  position: fixed;
  bottom: 90px;
  left: 0;
  overflow: hidden;

  background-color: ${GL_COLORS.BACKGROUND_PRIMARY};
  border-top: 1px solid ${GL_COLORS.BORDER_COLOR};

  display: flex;
  flex-direction: column;

  .notification-preview-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid ${GL_COLORS.BORDER_COLOR};
    height: 80px;
  }

  .notification-preview-body {
    padding: 20px 20px 0 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    .notification-platforms {
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: 10px;
    }

    .notification-platform-preview {
      position: relative;
      flex: 1;

      display: flex;

      > div {
        position: absolute;
        top: 200px;
        overflow-y: visible;
      }

      img {
        height: 100%;
      }
    }
  }
`;
