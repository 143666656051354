import { GLReports } from "@group-link-one/grouplink-components";

import { useModalContent } from "./hooks/useModalContent";
import { useModalDeleteReport } from "./hooks/useModalDeleteReport";
import { useReportsContent } from "./hooks/useReportsContent";

export const ReportsListContent = () => {
  const {
    reports,
    reportsIsLoading,
    reportsRefetch,
    isLoadingReportsAvailables,
    reportsAvailables,
  } = useReportsContent();
  const { createReport, isPending } = useModalContent();
  const { isPending: deleteIsPending, onDelete } = useModalDeleteReport();

  return (
    <GLReports
      reportsContentOptions={{ reports, reportsIsLoading, reportsRefetch }}
      reportTypeOptions={{
        isLoadingReportsAvailables,
        reportsAvailables,
      }}
      modalCreateReportContentOptions={{
        createReport,
        isPending,
        updateReport: () => {},
        getDevicesActivated: () => {},
        platform: "smart-retail",
        maxDateFrom: 60,
      }}
      modalDeleteReportOptions={{ onDelete, deleteIsPending }}
    />
  );
};
