import {
  DateRange,
  GLRelativeListOption,
  i18n,
} from "@group-link-one/grouplink-components";
import { subDays } from "date-fns";
import { create } from "zustand";

import {
  GetDevicesActivatedDailyReadingsRow,
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesDowSummaryRow,
} from "../../../Services/deviceListService/useDeviceListService.types";

export const initialDowData = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
];

export interface DetailsDeviceStoreState {
  modalIsOpen: boolean;
  device: GetDevicesActivatedLastReadingsResponse | undefined;

  currentData: GetDevicesActivatedDailyReadingsRow[] | undefined;
  currentDowData: GetDevicesDowSummaryRow[] | undefined;
  currentDowDataFormatted: number[][];

  dowTypeActive: "EVENT_IN" | "EVENT_OUT";

  currentRelativeTime: GLRelativeListOption | undefined;
  currentDateRange: DateRange | undefined;

  range: {
    from: Date | undefined;
    to: Date | undefined;
  };

  newDeviceName: string;
  isLoading: boolean;
}

export interface DetailsDeviceStoreActions {
  setModalIsOpen: (modalIsOpen: boolean) => void;
  setDevice: (device: GetDevicesActivatedLastReadingsResponse) => void;

  setCurrentData: (
    currentData: GetDevicesActivatedDailyReadingsRow[] | undefined
  ) => void;
  setCurrentDowData: (
    currentDowData: GetDevicesDowSummaryRow[] | undefined
  ) => void;

  setDowDataFormatted: (currentDowDataFormatted: number[][]) => void;

  setDowTypeActive: (dowTypeActive: "EVENT_IN" | "EVENT_OUT") => void;

  setCurrentRelativeTime: (option?: GLRelativeListOption) => void;
  setCurrentDateRange: (range?: DateRange) => void;

  setRange: (date: DetailsDeviceStoreState["range"]) => void;
  setNewDeviceName: (newDeviceName: string) => void;
  setIsLoading: (isLoading: boolean) => void;

  resetAll: () => void;
}

export interface DetailsDeviceStore {
  state: DetailsDeviceStoreState;
  actions: DetailsDeviceStoreActions;
}

export const useDetailsDeviceStore = create<DetailsDeviceStore>((set) => ({
  state: {
    modalIsOpen: false,
    device: undefined,
    currentData: undefined,
    currentDowData: undefined,
    currentDowDataFormatted: initialDowData,

    dowTypeActive: "EVENT_IN",

    currentRelativeTime: {
      id: "last_7_days",
      range: {
        from: subDays(new Date(), 7),
        to: new Date(),
      },
      text: i18n.t("days.last7Days"),
    },
    currentDateRange: undefined,

    range: {
      from: undefined,
      to: undefined,
    },

    isLoading: false,
    newDeviceName: "",
  },
  actions: {
    setModalIsOpen: (modalIsOpen) =>
      set((state) => ({
        state: {
          ...state.state,
          modalIsOpen,
        },
      })),

    setDevice: (device) =>
      set((state) => ({
        state: {
          ...state.state,
          device,
        },
      })),

    setCurrentData: (currentData) =>
      set((state) => ({
        state: {
          ...state.state,
          currentData,
        },
      })),

    setCurrentDowData: (currentDowData) =>
      set((state) => ({
        state: {
          ...state.state,
          currentDowData,
        },
      })),

    setDowDataFormatted: (currentDowDataFormatted) =>
      set((state) => ({
        state: {
          ...state.state,
          currentDowDataFormatted,
        },
      })),

    setDowTypeActive: (dowTypeActive) =>
      set((state) => ({
        state: {
          ...state.state,
          dowTypeActive,
        },
      })),

    setCurrentRelativeTime: (currentRelativeTime) =>
      set((state) => ({
        state: {
          ...state.state,
          currentRelativeTime,
        },
      })),

    setCurrentDateRange: (currentDateRange) =>
      set((state) => ({
        state: {
          ...state.state,
          currentDateRange,
        },
      })),

    setRange: (range) =>
      set((state) => ({
        state: {
          ...state.state,
          range,
        },
      })),

    setIsLoading: (isLoading) =>
      set((state) => ({
        state: {
          ...state.state,
          isLoading,
        },
      })),

    setNewDeviceName: (newDeviceName) =>
      set((state) => ({
        state: {
          ...state.state,
          newDeviceName,
        },
      })),

    resetAll: () =>
      set((state) => ({
        state: {
          ...state.state,
          modalIsOpen: false,
          device: undefined,
          currentData: undefined,
          range: {
            from: subDays(new Date(), 7),
            to: new Date(),
          },
          isLoading: false,
          currentDateRange: undefined,
          currentDowData: undefined,
          currentDowDataFormatted: initialDowData,
          currentRelativeTime: {
            id: "last_7_days",
            range: {
              from: subDays(new Date(), 7),
              to: new Date(),
            },
            text: i18n.t("days.last7Days"),
          },
        },
      })),
  },
}));
