import { GLBarChart, GLBoxChart } from "@group-link-one/grouplink-components";

import { useAverageTime } from "./useAverageTime";

export function GraphAverageTime() {
  const { t, bars, data, deviceDetailsState, averageTime } = useAverageTime();

  return (
    <GLBoxChart
      title={t("deviceList.detailsModal.sr.graphs.two.title")}
      textSwitcherId="average-time"
      tooltipText={t(
        "deviceList.detailsModal.sr.graphs.two.tooltipDescription"
      )}
      isLoading={deviceDetailsState.isLoading}
      chart={
        <GLBarChart
          data={data}
          bars={bars}
          average={averageTime > 0 ? averageTime : undefined}
          legendOptions={{
            wrapperStyle: {
              top: -24,
            },
          }}
          yAxisOptions={{
            tickFormatter: (value) => `${Number(value).toFixed(0)} min`,
          }}
          isLoading={false}
          hasLegend={false}
        />
      }
    />
  );
}
