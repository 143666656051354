import { GLModalRegisterDevice } from "@group-link-one/grouplink-components";

import { useModalRegisterDevice } from "./useModalRegisterDevice";

export function ModalRegisterDevice() {
  const { onPlaceSelect, onRegisterDeviceFn } = useModalRegisterDevice();

  return (
    <GLModalRegisterDevice
      onRegisterDevice={(deviceData) => onRegisterDeviceFn(deviceData)}
      onPlaceSelect={(placeId) => onPlaceSelect(placeId)}
    />
  );
}
