import { useI18n } from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { debounce } from "../../../../../utils/debounce";
import { useMessageCenterStore } from "../../../store/messageCenter";
import { useSegmentation } from "../../ModalCreateCampaign/Segmentation/hooks/useSegmentation";
import { useCreateCampaignStore } from "../../ModalCreateCampaign/store/createCampaign";
import { useMessageContentStore } from "../../ModalCreateCampaign/store/messageContent";
import { useScheduleStore } from "../../ModalCreateCampaign/store/schedule";
import { useSegmentationStore } from "../../ModalCreateCampaign/store/segmentation";
import { useSpotListStore } from "../../ModalCreateCampaign/store/spotList";

export const useOnlineCampaignRightContent = () => {
  const { t } = useI18n();
  const { actions: createCampaignActions } = useCreateCampaignStore();
  const { actions: contentActions } = useMessageCenterStore();
  const { actions: segmentationActions } = useSegmentationStore();
  const { actions: spotListActions } = useSpotListStore();
  const { actions: messageContentActions } = useMessageContentStore();
  const { actions: scheduleActions } = useScheduleStore();
  const { appsFetch, devices } = useSegmentation();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const handleInputChange = debounce(async (event) => {
    const value = (event.target as HTMLInputElement).value;
    contentActions.setSearch(value);
    contentActions.setCurrentPage(0);
  }, 500);

  const resetAll = () => {
    messageContentActions.addNewNotifications([]);
    segmentationActions.resetAll(appsFetch!);
    spotListActions.resetAll(devices, 1);
    spotListActions.setAudienceAddSpotToListAdded([]);
    scheduleActions.setEndDateFormatted("");
    scheduleActions.setStartDateFormatted("");
    scheduleActions.setScheduleSendValueDate("");
    scheduleActions.setScheduleSendValueTimes("");
  };

  return {
    t,
    createCampaignActions,
    contentActions,
    segmentationActions,
    appsFetch,
    devices,
    spotListActions,
    messageContentActions,
    scheduleActions,
    handleInputChange,
    isMobile,
    resetAll,
  };
};
