import { GLEmptyList, GLList } from "@group-link-one/grouplink-components";
import { Helmet } from "react-helmet";

import EmptyListImage from "../../../../../images/MessageCenter/empty_art_light.svg";
import { Cards } from "./cards";
import { OnlineCampaignContentContainer } from "./Style";
import { useOnlineCampaignContent } from "./useOnlineCampaignContent";

export function OnlineCampaignContent() {
  const { campaigns, tabs, activeTab, isLoading } = useOnlineCampaignContent();

  return (
    <OnlineCampaignContentContainer>
      {campaigns?.length === 0 ? (
        <div className="online-campaign-empty-list">
          <GLEmptyList
            title="No campaigns created yet"
            description="Create a new project to get started."
            icon={<img src={EmptyListImage} alt="" />}
            hasBorder={true}
          />
        </div>
      ) : (
        <GLList
          listName="Campanhas"
          isLoading={isLoading}
          content={<Cards campaigns={campaigns} />}
          tabActive={activeTab?.name || ""}
          tabs={tabs}
        />
      )}

      <Helmet title="Message Center" />
    </OnlineCampaignContentContainer>
  );
}
