import { useEffect } from "react";

import { useMessageCenterStore } from "../../../../store/messageCenter";
import { useSegmentationStore } from "../../store/segmentation";

// type IApp = {
//   app_id: number;
//   id: number;
//   android: boolean;
//   androidSelected: boolean;
//   created_at: string;
//   ios: boolean;
//   iosSelected: boolean;
//   name: string;
//   pkg_name_android: string | null;
//   pkg_name_ios: string | null;
//   platform: "ios" | "android";
// };

export const useSetSourceApps = () => {
  const { actions, state } = useSegmentationStore();
  const { state: messageCenterState } = useMessageCenterStore();

  const hasErrorInSourceApps = !!state.appsError;
  const sourceAppsMessageError = state.appsError;

  function handleOnSelectApp(id: number) {
    const appSelected = state.apps?.find((app) => app.id === id);

    if (appSelected) {
      actions.setAppsSelected(appSelected);
    }
  }

  function verifyIfWasSelected(id: number | undefined) {
    const appSelected = state.appsSelected?.find((app) =>
      app.app_id ? app.app_id : app.id === id
    );

    if (appSelected) return true;

    return false;
  }

  useEffect(() => {
    if (state.appsSelected.length > 0 && state.appsError) {
      actions.setAppsError("");
    }
  }, [state.appsSelected]);

  useEffect(() => {
    const appSelectedByCardInfo = messageCenterState?.cardInfo?.apps?.map(
      (app: any) => {
        const appFound = state.apps?.find((app_) => app_.id === app.app_id);

        return {
          ...appFound,
        };
      }
    );

    appSelectedByCardInfo?.forEach((app: any) => {
      actions.setAppsSelected(app);
    });
  }, [messageCenterState.cardInfo]);

  return {
    hasErrorInSourceApps,
    sourceAppsMessageError,
    handleOnSelectApp,
    verifyIfWasSelected,
  };
};
