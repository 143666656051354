import { useI18n, useToast } from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import { useMessageContentServices } from "../../../../../Services/messageCenterService/MessageContentServices/useMessageContentServices";
import { GetCampaignByIdResponse } from "../../../../../Services/messageCenterService/MessageContentServices/useMessageContentServices.types";
import { useMessageCenterStore } from "../../../store/messageCenter";

export const useOnlineCampaignContent = () => {
  const { t } = useI18n();
  const { state, actions } = useMessageCenterStore();

  const { getCampaigns, getCount, deleteCampaign, getCampaignById } =
    useMessageContentServices();
  const { addToast } = useToast();

  const queryClient = useQueryClient();

  const activeTab = useMemo(() => {
    return state.tabs.find((tab) => tab.id === state.activeTabId);
  }, [state.activeTabId, state.tabs]);

  const {
    data: campaigns,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "get-campaigns",
      state.search,
      activeTab?.active,
      state.currentPage,
    ],
    queryFn: async () => {
      const campaignsData = await getCampaigns({
        campaign_name_contains: state.search,
        is_published: activeTab?.active,
      });

      if (!campaignsData) return [];

      if (state.search.length === 0) return campaignsData.data;

      return campaignsData?.data;
    },
  });

  const { refetch: refetchCount } = useQuery({
    queryKey: ["get-count", state.search],
    queryFn: () => {
      getCount({ is_published: true }).then((res) => {
        actions.tabsActions.setCount(state.tabs[0], res?.data.count);

        return res?.data?.count;
      });

      return 0;
    },
  });

  const deleteCampaignById = (id: number) => {
    deleteCampaign(id)
      .then((res) => {
        if (res && res.status === 202) {
          addToast({
            title: t(
              "messageCenter.list.modal.creationModal.messages.campaignDeleted"
            ),
            message: t(
              "messageCenter.list.modal.creationModal.messages.campaignDeletedDescription"
            ),
            type: "success",
          });

          refetch();
          refetchCount();

          actions.setModalDeleteIsOpen(false);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });
      });
  };

  const { data: notifications, isLoading: loadingNotification } = useQuery<
    GetCampaignByIdResponse | undefined
  >({
    queryKey: ["get-campaign-by-id", state.campaignId],
    queryFn: async () => {
      const campaingIdAlreadyCached =
        queryClient.getQueryData<GetCampaignByIdResponse>([
          "get-campaign-by-id",
          state.campaignId,
        ]);

      if (state.campaignId && campaingIdAlreadyCached) {
        // if (createCampaignState.modalType === "edition") {
        //   actions.setCardInfo(campaingIdAlreadyCached);
        // }

        return campaingIdAlreadyCached;
      }

      if (state.campaignId && !campaingIdAlreadyCached) {
        const response = await getCampaignById(state.campaignId);

        if (!response) return undefined;

        // if (createCampaignState.modalType === "edition") {
        //   actions.setCardInfo(response);
        // }

        return response;
      }

      return undefined;
    },
    refetchOnWindowFocus: false,
  });

  async function getCampaignInfo(campaignId: number) {
    const responseCampaign = await getCampaignById(campaignId);

    if (!responseCampaign) return undefined;

    actions.setCardInfo(responseCampaign);
    return responseCampaign;
  }

  const refetchAll = () => {
    refetch();
    refetchCount();
  };

  function resetPagination() {
    actions.setCurrentPage(0);
  }

  useEffect(() => {
    actions.tabsActions.setOnClick(state.tabs[0], () => {
      resetPagination();
      actions.setActiveTab(1);
    });
  }, []);

  return {
    tabs: state.tabs,
    campaigns,
    activeTab,
    isLoading,
    actions,
    getCampaignInfo,
    deleteCampaignById,
    notifications,
    loadingNotification,
    refetchAll,
  };
};
