import { GLMainTemplate } from "@group-link-one/grouplink-components";
import { Outlet } from "react-router-dom";

import GLLogoDark from "../../images/Common/logo-glsmartretail-dark.svg";
import GLLogo from "../../images/Common/logo-glsmartretail-light.svg";
import { useMainLayout } from "./useMainLayout";

export function MainLayout() {
  const { navListTop, navListBottom } = useMainLayout();
  return (
    <GLMainTemplate
      asideOptions={{
        navListTop,
        navListBottom,
        logoOptions: {
          src: {
            logoLight: GLLogo,
            logoDark: GLLogoDark,
          },
          alt: "Logo GL Smart Retail",
          clipPath: 20,
          position: 67,
        },
      }}
    >
      <Outlet />
    </GLMainTemplate>
  );
}
