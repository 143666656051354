import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";

import { GLSpotsMockProps } from "../../../../../../mock/gl-spots";
import { useAppService } from "../../../../../../Services/appService/useAppService";
import { useMessageContentServices } from "../../../../../../Services/messageCenterService/MessageContentServices/useMessageContentServices";
import { useCreateCampaignStore } from "../../store/createCampaign";
import { useSegmentationStore } from "../../store/segmentation";
import { useSpotListStore } from "../../store/spotList";

export function useSegmentation() {
  const { getApps } = useAppService();
  const { getDevices } = useMessageContentServices();

  const listContainerRef = useRef<HTMLDivElement>(null);

  const { actions: createCampaignActions, state: createCampaignState } =
    useCreateCampaignStore();

  const { actions: spotListActions, state: spotListState } = useSpotListStore();

  const { actions: segmentationActions } = useSegmentationStore();

  const { data: appsFetch, isLoading: appsIsLoading } = useQuery({
    queryKey: ["get-apps"],
    queryFn: () => getApps().then((res) => res),
  });

  const { data: devices } = useQuery({
    queryKey: ["get-devices"],
    queryFn: async () => {
      const response = await getDevices();

      return response?.data.rows || [];
    },
  });

  const activeTab = spotListState.tabs.find(
    (tab) => tab.id === spotListState.activeTabId
  );

  function handleOnSelectGLSpot(spotId: number) {
    const spotSelected = devices?.find(
      (spot: GLSpotsMockProps) => spot.device_id === spotId
    );

    const spotAlreadySelected = spotListState.glSpotsSelected.find(
      (spot) => spot.device_id === spotId
    );

    if (spotListState.selectAll && spotSelected) {
      spotListActions.setAudienceSelectAll(false);
      spotListActions.setAudienceRemoveSpotFromSelectedList(spotSelected);
    }

    if (spotSelected && !spotAlreadySelected) {
      spotListActions.setAudienceAddSpotToListSelected([spotSelected]);
    }

    if (spotSelected && spotAlreadySelected) {
      spotListActions.setAudienceRemoveSpotFromSelectedList(spotSelected);
    }
  }

  function selectAllGLSpots() {
    spotListActions.setAudienceSelectAll(true);
    spotListActions.setAudienceResetList();

    if (activeTab?.cacheKey === "all-gl-spots") {
      spotListActions.setAudienceAddSpotToListSelected(
        spotListState.allGlSpots
      );
    } else {
      spotListActions.setAudienceAddSpotToListSelected(
        spotListState.glSpotsAddeds
      );
    }
  }

  function deselectAllGLSpots() {
    spotListActions.setAudienceSelectAll(false);
    spotListActions.setAudienceResetList();
  }

  function handleOnSelectAllGLSpots() {
    if (spotListState.selectAll) {
      spotListActions.setAudienceSelectAll(false);
      spotListActions.setAudienceResetList();
    }

    if (!spotListState.selectAll) {
      selectAllGLSpots();
    }
  }

  function formatStatusGLSpot(
    spotId: number
  ): "active" | "inactive" | "no-status" {
    if (spotListState.selectAll) return "active";
    if (spotListState.glSpotsSelected.length === 0) return "no-status";

    const spotIsSelected = spotListState.glSpotsSelected.find(
      (spot) => spot.device_id === spotId
    );

    return spotIsSelected ? "active" : "inactive";
  }

  function handleOnAddSpotsToAudience(
    glSpotsSelecteds: GLSpotsMockProps[],
    type: "to-audience-added" | "to-all-gl-spots"
  ) {
    glSpotsSelecteds?.forEach((spot) => {
      if (type === "to-audience-added") {
        spotListActions.setAudienceAddSpotToListAdded([spot]);
        spotListActions.setAudienceRemoveSpotFromGLSpotAllList(spot);
      }

      if (type === "to-all-gl-spots") {
        spotListActions.setAudienceIncrementAllSpots([spot]);
        spotListActions.setAudienceRemoveSpotFromAddedList(spot);
        // spotListActions.setAudienceRemoveSpotFromAddedList(spot);
      }
    });

    return spotListActions.setAudienceResetList();
  }

  function handleNextPage() {}

  function handlePreviousPage() {}

  function resetPagination() {
    spotListActions.setAudienceCurrentPage(0);
  }

  // ========================================================

  useEffect(() => {
    if (activeTab && activeTab.id === 2) {
      spotListActions.setAudienceActiveTab(2);
      spotListActions.setAudienceAddCurrentListSpots(
        spotListState.glSpotsAddeds
      );
    }

    if (activeTab && activeTab.id === 1) {
      spotListActions.setAudienceActiveTab(1);
      spotListActions.setAudienceAddCurrentListSpots(spotListState.allGlSpots);
    }

    spotListActions.setAudienceResetList();
    spotListActions.setAudienceSelectAll(false);
  }, [activeTab]);

  useEffect(() => {
    const newUserData = appsFetch?.map((app) => ({
      ...app,
      androidSelected: false,
      iosSelected: false,
    }));

    segmentationActions.setApps(newUserData || null);
  }, [appsFetch]);

  // ========================================================

  useEffect(() => {
    spotListActions.setAudienceActiveTab(1);

    spotListActions.tabsActions.setOnClick(spotListState.tabs[0], () => {
      resetPagination();
      spotListActions.setAudienceActiveTab(1);
    });

    spotListActions.tabsActions.setOnClick(spotListState.tabs[1], () => {
      resetPagination();
      spotListActions.setAudienceActiveTab(2);
    });

    // TODO
    if (spotListState.glSpotsAddeds?.length === 0) {
      spotListActions.setAudienceAddAllSpots(devices);
    } else {
      spotListActions.setAudienceAddAllSpots(spotListState.allGlSpots);
    }
  }, []);

  useEffect(() => {
    if (
      spotListState.glSpotsSelected.length > 0 &&
      spotListState.glSpotsSelected.length ===
        spotListState.glSpotsOnList.length
    ) {
      spotListActions.setAudienceSelectAll(true);
    }
  }, [spotListState.glSpotsSelected]);

  useEffect(() => {
    // TODO
    spotListActions?.tabsActions?.setCount(
      spotListState?.tabs[0],
      spotListState?.allGlSpots?.length
    );

    spotListActions.tabsActions.setCount(
      spotListState.tabs[1],
      spotListState.glSpotsAddeds?.length
    );
  }, [spotListState?.allGlSpots?.length, spotListState.glSpotsAddeds?.length]);

  useEffect(() => {
    if (spotListState.glSpotsAddeds?.length > 0) {
      spotListActions.setAudienceActiveTab(2);
      spotListActions.setAudienceSpotListError(undefined);
    }
  }, [spotListState.glSpotsAddeds]);

  return {
    devices,
    activeTab,
    appsIsLoading,
    handleNextPage,
    handlePreviousPage,
    handleOnSelectGLSpot,
    handleOnSelectAllGLSpots,
    deselectAllGLSpots,
    handleOnAddSpotsToAudience,
    formatStatusGLSpot,
    createCampaignActions,
    createCampaignState,
    spotListActions,
    spotListState,
    selectAllGLSpots,
    listContainerRef,
    appsFetch,
  };
}
