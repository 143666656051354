import { i18n } from "@group-link-one/grouplink-components";
import { create } from "zustand";

import { GetCampaignByIdResponse } from "../../../Services/messageCenterService/MessageContentServices/useMessageContentServices.types";
import { TabProps } from "../../Settings/Users/Content/useUsersContent";

interface MessageCenterStoreState {
  currentPage: number;
  tabs: TabProps[];
  activeTabId: number | undefined;
  modalDeleteIsOpen: boolean;
  search: string;
  cardInfo: GetCampaignByIdResponse;
  campaignId: number | undefined;
}

interface MessageCenterStoreActions {
  setCurrentPage: (page: number) => void;
  setActiveTab: (tabId: number) => void;
  setSearch: (search: string) => void;
  tabsActions: {
    setCount: (tab: TabProps, count: number) => void;
    setOnClick: (tab: TabProps, onClick: () => void) => void;
  };
  setModalDeleteIsOpen: (isOpen: boolean) => void;
  setCardInfo: (infos: GetCampaignByIdResponse) => void;
  setCampaignId: (id: number) => void;

  resetAll: () => void;
}

interface MessageCenterStore {
  state: MessageCenterStoreState;
  actions: MessageCenterStoreActions;
}

export const useMessageCenterStore = create<MessageCenterStore>((set) => ({
  state: {
    currentPage: 0,
    campaignId: undefined,
    activeTabId: 1,
    search: "",
    cardInfo: {
      id: 0,
      name: "",
      created_at: "",
      messages: [],
      devices: [],
      apps: [],
    },
    tabs: [
      {
        id: 1,
        name: i18n.t("messageCenter.list.tabs.one"),
        active: true,
        count: 0,
        onClick: () => {},
        cacheKey: "",
      },
    ],
    modalDeleteIsOpen: false,
  },
  actions: {
    setCurrentPage: (page: number) =>
      set((state) => ({
        state: {
          ...state.state,
          currentPage: page,
        },
      })),
    setActiveTab: (tabId: number) =>
      set((state) => ({
        state: {
          ...state.state,
          activeTabId: tabId,
        },
      })),
    setSearch: (search) =>
      set((state) => ({
        state: {
          ...state.state,
          search,
        },
      })),
    setCardInfo: (infos) =>
      set((state) => {
        return {
          state: {
            ...state.state,
            cardInfo: infos,
          },
        };
      }),
    setCampaignId: (id) =>
      set((state) => ({
        state: {
          ...state.state,
          campaignId: id,
        },
      })),
    tabsActions: {
      setCount: (tab: TabProps, count: number) =>
        set((state) => ({
          state: {
            ...state.state,
            tabs: state.state.tabs.map((t: TabProps) =>
              t.name === tab.name ? { ...t, count } : t
            ),
          },
        })),
      setOnClick: (tab: TabProps, onClick: () => void) =>
        set((state) => ({
          state: {
            ...state.state,
            tabs: state.state.tabs.map((t: TabProps) =>
              t.name === tab.name ? { ...t, onClick } : t
            ),
          },
        })),
    },
    setModalDeleteIsOpen: (isOpen: boolean) =>
      set((state) => ({
        state: {
          ...state.state,
          modalDeleteIsOpen: isOpen,
        },
      })),

    resetAll: () =>
      set((state) => ({
        state: {
          ...state.state,
          currentPage: 0,
          search: "",
          cardInfo: {
            id: 0,
            name: "",
            created_at: "",
            messages: [],
            devices: [],
            apps: [],
          },
          campaignId: undefined,
        },
      })),
  },
}));
