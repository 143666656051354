import { GL_COLORS } from "@group-link-one/grouplink-components";

import { getColorByVar } from "../../utils/getColorByVar";

export const useDeviceIcon = (type: "red" | "green") => {
  const varColor = getColorByVar(GL_COLORS.ACCENT_COLOR);

  return `
  <svg width="66px" height="72px" viewBox="0 0 66 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Untitled</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-11348" transform="translate(0.5005, 0.5004)" fill="${varColor}" fill-rule="nonzero" stroke="${varColor}" stroke-opacity="0">
            <g id="icon-pin" opacity="0.3" transform="translate(0, 54.0021)">
                <path d="M30.9995,16.9995 L52.9195,12.0205 C65.0255,9.2705 65.0255,4.8125 52.9195,2.0625 L52.9195,2.0625 C40.8135,-0.6875 21.1855,-0.6875 9.0795,2.0625 L9.0795,2.0625 C-3.0265,4.8125 -3.0265,9.2705 9.0795,12.0205 L30.9995,16.9995 Z" id="Subtraction_1"></path>
            </g>
            <g id="icon-pin-2" transform="translate(0.0001, 0)">
                <path d="M30.9994048,69.0006259 L52.9194048,52.8566259 C58.7326282,47.0534671 61.9993105,39.1766516 61.9993105,30.9626259 C61.9993105,22.7486002 58.7326282,14.8717847 52.9194048,9.06862593 L52.9194048,9.06862593 C40.8072873,-3.02287531 21.1915223,-3.02287531 9.0794048,9.06862593 L9.0794048,9.06862593 C3.26648776,14.8717444 -8.73866951e-16,22.7483455 -8.73866951e-16,30.9621259 C-8.73866951e-16,39.1759064 3.26648776,47.0525074 9.0794048,52.8556259 L30.9994048,69.0006259 Z" id="Subtraction_1-2"></path>
            </g>
        </g>
        <g id="icon-station" transform="translate(16, 17)">
            <rect id="Retângulo_5054" x="0" y="0" width="30" height="30"></rect>
            <g id="Grupo_5143" transform="translate(0.0031, 1.001)" fill="#FFFFFF" fill-rule="nonzero">
                <path d="M19.6178708,27.999 L10.3818708,27.999 C8.31148456,27.9893302 6.40527548,26.8701862 5.38787079,25.067 L0.769870795,16.932 C-0.256623598,15.1117431 -0.256623598,12.8872569 0.769870795,11.067 L5.38787079,2.932 C6.40527548,1.12881382 8.31148456,0.00966984668 10.3818708,0 L19.6178708,0 C21.6883142,0.00946146347 23.5946202,1.12866235 24.6118708,2.932 L29.2308708,11.067 C30.2573652,12.8872569 30.2573652,15.1117431 29.2308708,16.932 L24.6118708,25.067 C23.5946202,26.8703376 21.6883142,27.9895385 19.6178708,27.999 L19.6178708,27.999 Z M10.3818708,1.992 C9.01387692,1.99737094 7.75400115,2.73649806 7.08187079,3.928 L2.46387079,12.063 C1.78612886,13.2646944 1.78612886,14.7333056 2.46387079,15.935 L7.08187079,24.07 C7.75400115,25.2615019 9.01387692,26.0006291 10.3818708,26.006 L19.6178708,26.006 C20.986208,26.0018842 22.2466685,25.262414 22.9178708,24.07 L27.5368708,15.935 C28.214021,14.7331503 28.214021,13.2648497 27.5368708,12.063 L22.9178708,3.928 C22.2458948,2.73634627 20.9859218,1.99716212 19.6178708,1.992 L10.3818708,1.992 Z" id="Caminho_14349"></path>
                <g id="Grupo_5142" transform="translate(7.351, 9.8433)">
                    <path d="M4.63188595,2.17865247 C5.32723159,2.18090167 5.98290454,2.50283782 6.40988595,3.05165247 L8.12088595,1.54565247 C7.21189875,0.504652856 5.87498329,-0.0609368617 4.49488595,0.0116524684 C3.39038368,-0.0709986359 2.29828846,0.288515791 1.45886739,1.01110055 C0.619446323,1.73368531 0.101466335,2.76014491 0.0188859451,3.86465247 C0.00888594507,3.99665247 0.00488594507,4.12865247 0.00788594507,4.26465247 C-0.057050342,5.27226301 0.281059386,6.2643834 0.947795671,7.02264355 C1.61453196,7.78090369 2.55524726,8.24315448 3.56288595,8.30765247 C3.71508176,8.31680633 3.86769013,8.31680633 4.01988595,8.30765247 C4.78708839,8.3467828 5.54276537,8.108615 6.14888595,7.63665247 L6.14888595,8.12365247 L7.89388595,8.12365247 L7.89388595,3.94265247 L5.57388595,3.94265247 L5.57388595,5.84265247 C5.22479623,6.0727444 4.81387951,6.19099633 4.39588595,6.18165247 C3.45588595,6.18165247 2.70788595,5.60665247 2.70788595,4.23365247 C2.65071708,3.74812256 2.78890869,3.25978183 3.0920192,2.87620447 C3.39512972,2.49262712 3.8382927,2.24528088 4.32388595,2.18865247 C4.42608636,2.1766321 4.52912105,2.17328682 4.63188595,2.17865247 L4.63188595,2.17865247 Z" id="Path_12545"></path>
                    <polygon id="Path_12546" points="11.7108859 0.191652468 9.03788595 0.191652468 9.03788595 8.12365247 15.2918859 8.12365247 15.2918859 6.05065247 11.7108859 6.05065247"></polygon>
                </g>
            </g>
        </g>
        <g id="Group-11349" transform="translate(44, 42)" fill="${type === "green" ? "#00FFAA" : "#E12C57"}" fill-rule="nonzero" stroke="${type === "green" ? "#00FFAA" : "#E12C57"}" stroke-opacity="0.200000003" stroke-width="8">
            <circle id="Ellipse_2765" cx="9" cy="9" r="9"></circle>
        </g>
    </g>
</svg>
    `;
};
