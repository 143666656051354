import {
  DataGroup,
  GL_COLORS,
  GL_GLStation,
  GLTransferListSideOptions,
  useGroupsDevicesStore,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import {
  GetDevicesActivatedLastReadingsResponse
} from "../../../Services/deviceListService/useDeviceListService.types";
import { useDeviceGroupsUtils } from "../useDeviceGroupsUtils";

const IPP_DEVICES = 50;

export const useGroupDevicesTransferList = () => {
  const [devicesAvailables, setDevicesAvailables] = useState<DataGroup[]>(
    []
  );
  const [devicesAvailablesData, setDevicesAvailablesData] = useState<
    GetDevicesActivatedLastReadingsResponse[]
  >([]);

  const [devicesAddeds, setDevicesAddeds] = useState<DataGroup[]>([]);
  const [devicesAddedsData, setDevicesAddedsData] = useState<
    number[]
  >([]);

  const [availableDevicesSearchIsCorrect, setAvailableDevicesSearchIsCorrect] = useState(true);

  const { t } = useI18n();

  const { getDevicesActivatedLastReadings } = useDeviceListService()

  const { state: groupDevicesState, actions: groupDevicesActions } =
    useGroupsDevicesStore();

  const { getDeviceGroup } = useDeviceGroupsUtils()

  const isEditing = false;
  const queryClient = useQueryClient();

  const searchDeviceIDAvailablesDevices = useMemo(() => {

    if (groupDevicesState.availableDevicesSearch.length >= 0 &&
      groupDevicesState.availableDevicesSearch.length < 10
    ) return undefined;

    return isNaN(Number(groupDevicesState.availableDevicesSearch))
      ? undefined
      : [Number(groupDevicesState.availableDevicesSearch)];

  }, [groupDevicesState.availableDevicesSearch]);

  const valueTosearchDeviceIDAvailablesDevices = (search: string) => {

    if (search.length >= 0 &&
      search.length < 10
    ) return undefined;

    return isNaN(Number(search))
      ? undefined
      : [Number(search)];
  }

  const leftSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: "users-availables-transfer-list",
          count: undefined,
          id: 1,
          name: t("groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne"),
          onClick: () => { },
        },
      ],
      color: GL_COLORS.ACCENT_COLOR,
      listName: t("groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne"),
      onScrollCallback: async () => {
        getMoreDevicesAvailables();
      },
    };
  }, [
    groupDevicesState.devices_ids,
    groupDevicesState.isFetchingMoreAvailableDevices,
    groupDevicesState.nextPageTokenAvailableDevices,
  ]);

  const rightSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: "users-in-group-transfer-list",
          count: devicesAddeds.length,
          id: 1,
          name: t("groupsDevices.addDevicesModal.transferList.rightSide.tabs.tabOne"),
          onClick: () => { },
        },
      ],
      color: GL_COLORS.LIGHT_GREEN,
      listName: t("groupsDevices.addDevicesModal.transferList.rightSide.tabs.tabOne"),
      onScrollCallback: async () => { },
    };
  }, [
    devicesAddeds
  ]);

  async function getDevicesAvailables(search?: string) {
    return await queryClient.fetchQuery({
      queryKey: ["get-devices-availables"],
      queryFn: async () => {
        groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

        const availableDevices = await getDevicesActivatedLastReadings({
          next_page_token: undefined,
          ipp: IPP_DEVICES,
          device_id: valueTosearchDeviceIDAvailablesDevices(search || ""),
        });

        groupDevicesActions.setIsFetchingMoreAvailableDevices(false);

        if (availableDevices.has_more && availableDevices.next_page_token) {
          groupDevicesActions.setNextPageTokenAvailableDevices(
            availableDevices.next_page_token
          );
        }

        const devicesFiltereds = availableDevices.rows.filter(
          (device) =>
            groupDevicesState.devices_ids.indexOf(device.device_id) === -1
        );

        const devicesFormatted: DataGroup[] =
          formatDevicesAvailables(devicesFiltereds);

        setDevicesAvailablesData(devicesFiltereds);
        setDevicesAvailables(devicesFormatted);

        return devicesFiltereds;
      },
    });
  }

  async function getDevicesInGroup(devicesFilteredData: GetDevicesActivatedLastReadingsResponse[]) {

    const devicesInGroup = await getDeviceGroup(Number(groupDevicesState.groupsDevicesCardInfo.id));

    const devicesFormatted: DataGroup[] = devicesInGroup.devices.map(
      (device) => {
        return {
          id: String(device),
          title: String(device),
          body: "",
          icon: <GL_GLStation fill={GL_COLORS.FONT_COLOR_VARIANT} />,
        };
      }
    );

    const devicesAddedsIDs = devicesInGroup.devices.map(
      (device) => Number(device)
    );

    const devicesAvailablesFiltered = devicesFilteredData.filter(
      (device) => devicesAddedsIDs.indexOf(device.device_id) === -1
    );

    setDevicesAddedsData(devicesInGroup.devices);
    setDevicesAddeds(devicesFormatted);

    setDevicesAvailablesData(devicesAvailablesFiltered);
    setDevicesAvailables(
      formatDevicesAvailables(devicesAvailablesFiltered)
    );

    groupDevicesActions.setGroupsDeviceCardInfo({
      ...groupDevicesState.groupsDevicesCardInfo,
      devices_ids: devicesAddedsIDs,
    })
    // groupDevicesActions.setDevicesIds(devicesAddedsIDs);
  }

  function filterDevicesAddedsBySearch(search: string) {

    if (search.length > 0) {
      const devicesFiltered = devicesAddedsData.filter((device) => {
        return String(device).includes(search);
      });

      const devicesFormatted: DataGroup[] = devicesFiltered.map(
        (device) => {
          return {
            id: String(device),
            title: `${device}`,
            body: "",
          };
        }
      );

      setDevicesAddeds(devicesFormatted);
    } else {
      const devicesFormatted: DataGroup[] = devicesAddedsData.map(
        (device) => {
          return {
            id: String(device),
            title: `${device}`,
            body: "",
          };
        }
      );

      setDevicesAddeds(devicesFormatted);
    }
  }

  function formatDevicesAvailables(
    currentDevicesAvailables: GetDevicesActivatedLastReadingsResponse[]
  ): DataGroup[] {
    if (!currentDevicesAvailables) return [];

    const devicesFormatted: DataGroup[] = currentDevicesAvailables?.map(
      (device) => {
        return {
          id: String(device.device_id),
          title: device.channels[0].name,
          body: `ID: ${device.device_id}`,
          icon: <GL_GLStation fill={GL_COLORS.FONT_COLOR_VARIANT} />,
        };
      }
    );

    return devicesFormatted;
  }

  async function getMoreDevicesAvailables() {
    if (
      groupDevicesState.isFetchingMoreAvailableDevices ||
      !groupDevicesState.nextPageTokenAvailableDevices
    )
      return;

    groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

    const response = await getDevicesActivatedLastReadings({
      next_page_token: groupDevicesState.nextPageTokenAvailableDevices,
      ipp: IPP_DEVICES,
      device_id: searchDeviceIDAvailablesDevices,
    });

    setDevicesAvailablesData([...devicesAvailablesData, ...response.rows]);

    groupDevicesActions.setIsFetchingMoreAvailableDevices(false);
    groupDevicesActions.setNextPageTokenAvailableDevices(response.next_page_token);

    const currentDevicesAvailables: GetDevicesActivatedLastReadingsResponse[] | undefined =
      queryClient.getQueryData(["get-devices-availables"]);

    const newDevicesAvailables = response.rows.filter(
      (device) => groupDevicesState.devices_ids.map(Number).indexOf(device.device_id) === -1
    );

    if (currentDevicesAvailables) {
      const currentDevicesAvailablesWihoutAddeds =
        currentDevicesAvailables.filter(
          (device) =>
            groupDevicesState.devices_ids.map(Number).indexOf(device.device_id) === -1
        );

      const newDevicesAvailablesFormatted: DataGroup[] =
        formatDevicesAvailables(
          currentDevicesAvailablesWihoutAddeds.concat(newDevicesAvailables)
        );

      setDevicesAvailables(newDevicesAvailablesFormatted);

      queryClient.setQueryData(
        ["get-devices-availables"],
        currentDevicesAvailablesWihoutAddeds.concat(newDevicesAvailables)
      );
    }
  }

  function onTransferItem(items: DataGroup[], type: "left" | "right") {
    if (type === "right") {
      if (groupDevicesState.devicesInGroupSearch.length === 0) {
        const devicesIds = items.map((device) => Number(device.id));
        groupDevicesActions.setDevicesIds(devicesIds);

        const devicesAddedsDataFormatted: number[] = items.map(
          (device) => Number(device.id)
        );

        setDevicesAddedsData(devicesAddedsDataFormatted);
      }

      setDevicesAddeds(items);
    }

    if (type === "left") {
      setDevicesAvailables(items);

      const currentDevicesSorted = items
        .map((item) => {
          return devicesAvailablesData.find(
            (device) => device.device_id === Number(item.id)
          );
        })
        .filter((item) => item);

      queryClient.setQueryData(
        ["get-devices-availables"],
        currentDevicesSorted
      );

      if (groupDevicesState.devicesInGroupSearch.length > 0) {
        const lastItemFromAvailables = items.slice(-1)[0];

        const newDevicesAddedsData = devicesAddedsData.filter(
          (device) => device !== Number(lastItemFromAvailables.id)
        );

        groupDevicesActions.setDevicesIds(
          newDevicesAddedsData.map((device) => device)
        );

        setDevicesAddedsData(newDevicesAddedsData);
      }
    }
  }

  function onSearch(value: string, side: "left" | "right") {
    if (side === "left") {
      groupDevicesActions.setAvailableDevicesSearch(value);

      if (value.length === 0 ||
        value.length >= 10
      ) {
        getDevicesAvailables(value);
        setAvailableDevicesSearchIsCorrect(true);
      } else {
        setAvailableDevicesSearchIsCorrect(false);
      }
    }

    if (side === "right") {
      groupDevicesActions.setDevicesInGroupSearch(value);
      filterDevicesAddedsBySearch(value.toLocaleLowerCase());
    }
  }

  async function initOnOpenModal() {

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(true);

    const devicesFiltered = await getDevicesAvailables();
    setDevicesAddeds([]);
    await getDevicesInGroup(devicesFiltered);

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(false);
  }

  useEffect(() => {
    if (groupDevicesState.openAddDevicesModal) {
      initOnOpenModal()
    }

    if (!groupDevicesState.openAddDevicesModal) {
      setDevicesAddeds([]);
    }
  }, [groupDevicesState.openAddDevicesModal, isEditing]);

  useEffect(() => {
    groupDevicesActions.setDevicesIds(devicesAddedsData);
  }, [devicesAddedsData])

  return {
    t,
    availableDevicesSearchIsCorrect,
    devicesAvailables,
    devicesAddeds,
    leftSideOptions,
    rightSideOptions,
    groupDevicesState,
    onTransferItem,
    onSearch,
  };
};
