import { onRequestError } from "../../../Errors/onError";
import {
  ICreateCampaignData,
  IParamsMessageCenter,
  IUpdateData,
} from "../../../Pages/MessageCenter/Campaign/OnlineCampaign/types/onlineCampaigns.types";
import useAxiosHTTPRequest from "../../useAxiosHTTPRequest";
import { GetCampaignByIdResponse } from "./useMessageContentServices.types";

export const useMessageContentServices = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const createCampaign = async (data: ICreateCampaignData) => {
    return await httpPrivate.post("/campaign", data);
  };

  const getCampaigns = async ({
    is_published,
    campaign_name,
    campaign_name_contains,
  }: IParamsMessageCenter) => {
    try {
      return await httpPrivate.get("/campaign", {
        params: {
          is_published,
          campaign_name: campaign_name ? campaign_name : undefined,
          campaign_name_contains,
        },
      });
    } catch (error) {
      return onRequestError(error);
    }
  };

  const getCampaignById = async (
    id: number
  ): Promise<GetCampaignByIdResponse | void> => {
    try {
      return (await httpPrivate.get(`/campaign/${id}`)).data;
    } catch (error) {
      return onRequestError(error);
    }
  };

  const getCount = async ({
    is_published,
    campaign_name_contains,
  }: IParamsMessageCenter) => {
    try {
      return await httpPrivate.get("/campaign/count", {
        params: {
          is_published,
          campaign_name_contains: campaign_name_contains
            ? campaign_name_contains
            : undefined,
        },
      });
    } catch (error) {
      return onRequestError(error);
    }
  };

  const getDevices = async () => {
    try {
      return await httpPrivate.get("/sr/device-name");
    } catch (error) {
      return onRequestError(error);
    }
  };

  const updateCampaign = async (id: number, data: IUpdateData) => {
    try {
      return await httpPrivate.patch(`/campaign/${id}`, data);
    } catch (error) {
      return onRequestError(error);
    }
  };

  const deleteCampaign = async (id: number) => {
    try {
      return await httpPrivate.delete(`/campaign/${id}`);
    } catch (error) {
      return onRequestError(error);
    }
  };

  return {
    createCampaign,
    getCampaigns,
    getCampaignById,
    getCount,
    getDevices,
    updateCampaign,
    deleteCampaign,
  };
};
