import {
  GLRecurringNotificationOnActionDone,
  useI18n,
} from "@group-link-one/grouplink-components";
import { format, Locale } from "date-fns";
import { enUS } from "date-fns/locale/en-US";
import { es } from "date-fns/locale/es";
import { pt } from "date-fns/locale/pt";
import { useEffect, useState } from "react";

import {
  CreateCampaingStepNames,
  useCreateCampaignStore,
} from "../store/createCampaign";
import { useScheduleStore } from "../store/schedule";

export function useSchedule() {
  const language = localStorage.getItem("i18nextLng");
  const [lang, setLang] = useState<Locale | undefined>();
  const { t } = useI18n();
  const {
    actions,
    state: {
      scheduleSendValue,
      campaign_interval,
      endDateValueFormatted,
      startDateValueFormatted,
    },
  } = useScheduleStore();

  const { actions: createCampaignActions } = useCreateCampaignStore();

  useEffect(() => {
    if (language === "en-US" || language === "en") setLang(enUS);
    if (language === "pt-BR" || language === "pt") setLang(pt);
    if (language === "es-es" || language === "es") setLang(es);
  }, []);

  function selectEndDate(e: Date | string) {
    const date = new Date(e);
    const formattedDate = format(
      e,
      `'${t("messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.eleven")}' LLL do, yyyy`,
      { locale: lang }
    );

    actions.setEndDateFormatted(formattedDate);

    actions.setDropdownBoxOptionsIsOpen(false);

    actions.setEndDate(date.toISOString());
  }

  function selectStartDate(e: Date | string) {
    const date = new Date(e);
    const formattedDate = format(
      e,
      `'${t("messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.eleven")}' LLL do, yyyy`,
      { locale: lang }
    );

    actions.setStartDateFormatted(formattedDate);

    actions.setDropdownBoxOptionsIsOpen(false);

    actions.setStartDate(date.toISOString());
  }

  function selectSpecificDays(date: Date[]) {
    const arr: string[] = [];

    const dates = date.map((onlyDate: Date) => {
      const d = new Date(onlyDate);
      const formatDay = format(d, " d MMMM", { locale: lang });
      arr.push(formatDay);

      return format(d, "yyyy-MM-dd");
    });

    actions.setScheduleSendValueDate(
      `${t("messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.scheduling.selectDays")} ${arr}`
    );

    const piece = dates[dates.length - 1].split("T");

    piece[1] = "23:59:59.000Z";

    const newString = piece.join("T");

    actions.setEndDate(newString);

    actions.setCampaignConfig({
      type: "specifics_days",
      config: {
        dates,
      },
    });
  }

  function handleOnSelectDate(e: Date) {
    const formattedDate = format(
      e,
      `'${t("messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.eleven")}' LLL do, yyyy`
    );

    actions.setScheduleSendValueDate(formattedDate);

    actions.setSelectedDay(e);
    // actions.setDropdownBoxOptionsIsOpen(false);
  }

  function onClickInStartNow() {
    const date = new Date();
    const _endDate = new Date();

    actions.setScheduleSendValueDate(
      t(
        "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.six"
      )
    );
    const formattedDateString = format(
      date,
      `'${t("messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.eleven")}' LLL do, yyyy`,
      { locale: lang }
    );

    const endDateISO = _endDate.toISOString();

    const piece = endDateISO.split("T");

    piece[1] = "02:59:59.999Z";

    const newString = piece.join("T");

    actions.setStartDateFormatted(formattedDateString);
    actions.setStartDate(date.toISOString());
    actions.setEndDate(String(newString));
  }

  function formatSelectedDaysText(selectedDays: string[]) {
    if (selectedDays.length === 1)
      return actions.setScheduleSendValueDate(
        `${t("messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.seven")} ${selectedDays[0].toLowerCase()}`
      );

    let selectedDaysText = selectedDays
      .slice(0, selectedDays.length - 1)
      .map(
        (d) =>
          `${t("messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.seven")} ${d.toLowerCase()}`
      )
      .join(". ");

    const lastDay = selectedDays[selectedDays.length - 1];

    if (selectedDays.length > 1) {
      selectedDaysText += ` ${t("messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.eight")} ${lastDay.toLowerCase()}`;
    }

    actions.setScheduleSendValueDate(selectedDaysText);
  }

  function formatTimeValue({
    selectedDays,
  }: Pick<
    GLRecurringNotificationOnActionDone,
    "config" | "type" | "selectedDays"
  >) {
    const pluralTextFormatted = `${t("messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.scheduling.InTheDays")} ${selectedDays}`;
    actions.setScheduleSendValueTimes(pluralTextFormatted);
  }

  function onClickDone({
    type,
    selectedDays,
    config,
  }: GLRecurringNotificationOnActionDone) {
    if (selectedDays) formatSelectedDaysText(selectedDays);

    if (type.value !== "everyday") {
      actions.setScheduleSendValueDate("");
    }

    formatTimeValue({ config, type, selectedDays });

    if (type === "workday" || type === "weekend" || type === "everyday") {
      actions.setCampaignConfig({
        type,
        config: {},
      });

      return;
    }

    actions.setCampaignConfig({
      type,
      config,
    });
    // actions.setDropdownBoxOptionsIsOpen(false);
  }

  function onClickCancel() {}

  const inputFrequencyAndPeriodValue = `${scheduleSendValue.times}${
    scheduleSendValue.date
      ? "" + scheduleSendValue.date
      : t(
          "messageCenter.list.modal.creationModal.steps.three.placeholder.dropdown.labels.six"
        )
  }`;

  useEffect(() => {
    createCampaignActions.setStepWasVisited<CreateCampaingStepNames>(
      "SCHEDULE"
    );
  }, []);

  return {
    selectSpecificDays,
    selectStartDate,
    handleOnSelectDate,
    onClickInStartNow,
    onClickDone,
    onClickCancel,
    selectEndDate,
    inputFrequencyAndPeriodValue,
    endDateValueFormatted,
    startDateValueFormatted,
    actions,
    campaign_interval,
  };
}
