import { GLBigNumberCard, GLBox } from "@group-link-one/grouplink-components";

import { DeviceOnlyMap } from "../DeviceOnlyMap/DeviceOnlyMap";
// import { useMediaQuery } from "usehooks-ts";
import { GraphAverageTime } from "../Graphs/AverageTime/AverageTime";
import { GraphEntryAndExitEvents } from "../Graphs/EntryAndExitEvents/EntryAndExitEvents";
import { GraphHeatMap } from "../Graphs/HeatMap/HeatMap";
import {
  BigNumbersContainer,
  ChartsContainer,
} from "./DeviceDetailsContentStyle";
import { useDeviceDetailsContent } from "./useDeviceDetailsContent";

export function DeviceDetailsContent() {
  const { bigNumbers, deviceDetailsState } = useDeviceDetailsContent();

  return (
    <GLBox width="100%" style={{ marginTop: 30 }} gap={50} direction="column">
      <BigNumbersContainer>
        {bigNumbers.map((bigNumber) => (
          <GLBigNumberCard
            key={bigNumber.title}
            bigNumber={bigNumber}
            isLoading={deviceDetailsState.isLoading}
          />
        ))}
      </BigNumbersContainer>

      <ChartsContainer>
        <GraphEntryAndExitEvents />
        <GraphAverageTime />
      </ChartsContainer>

      <GraphHeatMap />

      <DeviceOnlyMap />
    </GLBox>
  );
}
