import {
  BigNumberProps,
  Colors,
  GL_BAR_CHART,
  GL_CHECK,
  GL_COLORS,
  GL_GLStation,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "usehooks-ts";

import PeakPeriodImg from "../../../../images/DeviceList/bigNumbers/details-big-number-scale.svg";
import CheckImg from "../../../../images/MessageCenter/bigNumbers/icon-check.svg";
import StationImg from "../../../../images/MessageCenter/bigNumbers/icon-station.svg";
import { useMessageContentServices } from "../../../../Services/messageCenterService/MessageContentServices/useMessageContentServices";
import { getPeakPeriod } from "../../../../utils/getPeakPeriod";
import { useMessageCenterStore } from "../../../MessageCenter/store/messageCenter";
import { useOverviewStore } from "../../../MessageCenter/store/overview";
import { useOverviewGraphs } from "../Graphs/useOverviewGraphs";

export const useOverviewBigNumbers = () => {
  const isSM = useMediaQuery("(max-width: 660px)");
  const { selectedLanguege, t } = useI18n();
  const { state } = useMessageCenterStore();
  const { state: overviewState } = useOverviewStore();
  const { data } = useOverviewGraphs();
  const { getCount } = useMessageContentServices();

  const { data: allCampaignsCount } = useQuery({
    queryKey: ["get-count", state.search],
    queryFn: async () => {
      const response = await getCount({ is_published: true }).then((res) => {
        return res?.data?.count;
      });

      return response;
    },
  });

  const bigNumbers: BigNumberProps[] = [
    {
      iconLeft: (
        <GL_GLStation
          fillOne={GL_COLORS.BASE_BLACK}
          fill={GL_COLORS.BASE_BLACK}
          size={24}
        />
      ),
      iconRight: <img src={StationImg} alt="" />,
      iconRightStyle: {
        bottom: -15,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("messageCenter.overview.bigNumbers.one"),
      count: overviewState.allPushesCount || 0,
      type: isSM ? "sm" : "lg",
      backgroundColor: GL_COLORS.LIGHT_GREEN,
      textColor: "BASE_BLACK",
      tooltipZIndex: 1000,
      tooltipText: t("messageCenter.overview.bigNumbers.tooltips.one"),
      helpIconColor: "BASE_BLACK",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      iconLeft: <GL_CHECK fill={GL_COLORS.FONT_COLOR_VARIANT} size={24} />,
      iconRight: <img src={CheckImg} alt="" />,
      iconRightStyle: {
        bottom: -15,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("messageCenter.overview.bigNumbers.two"),
      count: allCampaignsCount || 0,
      type: isSM ? "sm" : "lg",
      backgroundColor: GL_COLORS.ACCENT_COLOR,
      textColor: "FONT_COLOR_VARIANT",
      tooltipZIndex: 1000,
      tooltipText: t("messageCenter.overview.bigNumbers.tooltips.two"),
      helpIconColor: "FONT_COLOR_VARIANT",
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      iconLeft: <GL_BAR_CHART fill={GL_COLORS.WHITE} size={24} />,
      iconRight: <img src={PeakPeriodImg} alt="" />,
      iconRightStyle: {
        bottom: -15,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("messageCenter.overview.bigNumbers.three"),
      count: getPeakPeriod(data, "hora", "push") || 0,
      type: isSM ? "sm" : "lg",
      backgroundColor: Colors.PURPLE,
      textColor: "WHITE",
      helpIconColor: "WHITE",
      tooltipZIndex: 1000,
      tooltipText: t("messageCenter.overview.bigNumbers.tooltips.three"),
      tooltipTextStyle: {
        whiteSpace: "nowrap",
      },
    },
  ];

  function formatBigNumber(value: number) {
    return value.toLocaleString(selectedLanguege, {
      maximumFractionDigits: 0,
    });
  }

  return {
    bigNumbers,
    formatBigNumber,
    isSM,
  };
};
