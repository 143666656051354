import {
  GLTextSwitcherOptions,
  useActiveLanguageDateFns,
  useI18n,
} from "@group-link-one/grouplink-components";
import { format, setDay } from "date-fns";
import { useEffect, useMemo } from "react";

import { useDetailsDeviceStore } from "../../../store/details-device-store";

export const useHeatMap = () => {
  const {
    state: deviceDetailsState,
    actions: { setDowDataFormatted, setDowTypeActive },
  } = useDetailsDeviceStore();

  const { t } = useI18n();
  const localeActive = useActiveLanguageDateFns();

  const textSwicherOptions: GLTextSwitcherOptions[] = [
    {
      id: 1,
      text: "Out",
      value: "EVENT_OUT",
    },
    {
      id: 2,
      text: "In",
      value: "EVENT_IN",
    },
  ];

  const columns = [
    format(setDay(new Date(), 0), "eee", { locale: localeActive }),
    format(setDay(new Date(), 1), "eee", { locale: localeActive }),
    format(setDay(new Date(), 2), "eee", { locale: localeActive }),
    format(setDay(new Date(), 3), "eee", { locale: localeActive }),
    format(setDay(new Date(), 4), "eee", { locale: localeActive }),
    format(setDay(new Date(), 5), "eee", { locale: localeActive }),
    format(setDay(new Date(), 6), "eee", { locale: localeActive }),
  ];

  const rows = [
    "00h",
    "01h",
    "02h",
    "03h",
    "04h",
    "05h",
    "06h",
    "07h",
    "08h",
    "09h",
    "10h",
    "11h",
    "12h",
    "13h",
    "14h",
    "15h",
    "16h",
    "17h",
    "18h",
    "19h",
    "20h",
    "21h",
    "22h",
    "23h",
  ];

  const optionActive = useMemo(() => {
    return textSwicherOptions.findIndex(
      (item) => item.value === deviceDetailsState.dowTypeActive
    );
  }, [deviceDetailsState.dowTypeActive]);

  function onSelectItem(optionId?: number) {
    const option = textSwicherOptions.find((item) => item.id === optionId);

    if (option) {
      setDowTypeActive(option.value as "EVENT_IN" | "EVENT_OUT");
    }
  }

  useEffect(() => {
    const newValues = [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ];

    const events = deviceDetailsState.currentDowData?.filter(
      (item) => item.event_type === deviceDetailsState.dowTypeActive
    );

    events?.forEach((item) => {
      const day = item.day_of_week - 1;
      const hour = item.hour;
      const value = item.events_count;

      newValues[day][hour] += value;
    });

    setDowDataFormatted(newValues);
  }, [deviceDetailsState.currentDowData, deviceDetailsState.dowTypeActive]);

  return {
    t,
    deviceDetailsState,
    columns,
    rows,
    values: deviceDetailsState.currentDowDataFormatted,
    textSwicherOptions,
    optionActive,
    onSelectItem,
  };
};
