import { useAuth } from "@group-link-one/gl-auth";
import { useI18n } from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

export const useOverview = () => {
  const { user } = useAuth();
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { t } = useI18n();

  const greetingMessage = () => {
    const date = new Date();
    const hours = date.getHours();
    let greeting = "";

    if (hours < 12)
      greeting = `${t("dashboard.greeting.morning")} ${user?.name}`;
    else if (hours < 18)
      greeting = `${t("dashboard.greeting.afternoon")} ${user?.name}`;
    else greeting = `${t("dashboard.greeting.evening")} ${user?.name}`;

    return greeting;
  };

  return {
    t,
    isMobile,
    greetingMessage,
  };
};
