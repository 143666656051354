import { create } from "zustand";

import AndroidPhoneImage from "../../../../../images/MessageCenter/phones/android.png";
import IphoneDarkImage from "../../../../../images/MessageCenter/phones/iphone-dark.png";
import IphoneLightImage from "../../../../../images/MessageCenter/phones/iphone-light.png";
import { EventTimeTypes } from "../MessageContent/useMessageContent";

export interface Notification {
  id?: string;
  body: string;
  title: string;
  event_type: EventTimeTypes;
  minute?: number;
}

interface MessageContentStoreState {
  notificationsList: Notification[];
  notificationError: string | undefined;
  optionsToTextSwitcher: {
    id: number;
    text: string;
    image: string;
  }[];
}

interface MessageContentStoreActions {
  addNotification: (notification: Notification) => void;
  removeNotification: (notificationId: string) => void;
  addNewNotifications: (notifications: Notification[]) => void;
  editNotification: (
    notificationId: string,
    notification: Notification
  ) => void;

  updatePlaformImageWhenDarkMode: () => void;

  setNotificationError: (error: string) => void;

  resetAll: () => void;
}

interface MessageContentStore {
  state: MessageContentStoreState;
  actions: MessageContentStoreActions;
}

export const useMessageContentStore = create<MessageContentStore>((set) => ({
  state: {
    notificationsList: [],
    notificationError: undefined,
    optionsToTextSwitcher: [
      {
        id: 1,
        text: "iPhone",
        image: window.matchMedia("(prefers-color-scheme: dark)").matches
          ? IphoneDarkImage
          : IphoneLightImage,
      },
      {
        id: 2,
        text: "Android",
        image: AndroidPhoneImage,
      },
    ],
  },
  actions: {
    addNotification: (notification: Notification) =>
      set((state) => ({
        state: {
          ...state.state,
          notificationsList: [...state.state.notificationsList, notification],
        },
      })),
    addNewNotifications: (notification: Notification[]) =>
      set((state) => ({
        state: {
          ...state.state,
          notificationsList: notification,
        },
      })),

    removeNotification: (notificationBody: string) =>
      set((state) => ({
        state: {
          ...state.state,
          notificationsList: state.state.notificationsList.filter(
            (notification) => notification.body !== notificationBody
          ),
        },
      })),

    editNotification: (notificationId: string, notification: Notification) =>
      set((state) => ({
        state: {
          ...state.state,
          notificationsList: state.state.notificationsList.map((item) =>
            item.id === notificationId ? notification : item
          ),
        },
      })),

    setNotificationError: (error: string) =>
      set((state) => ({
        state: {
          ...state.state,
          notificationError: error,
        },
      })),

    updatePlaformImageWhenDarkMode: () => {
      const darkModeListener = window.matchMedia(
        "(prefers-color-scheme: dark)"
      );

      darkModeListener.addEventListener("change", (event) => {
        set((state) => ({
          state: {
            ...state.state,
            optionsToTextSwitcher: state.state.optionsToTextSwitcher.map(
              (item) => ({
                ...item,
                image:
                  item.text === "iPhone"
                    ? event.matches
                      ? IphoneDarkImage
                      : IphoneLightImage
                    : item.image,
              })
            ),
          },
        }));
      });
    },

    resetAll: () =>
      set((state) => ({
        state: {
          ...state.state,
          notificationsList: [],
        },
      })),
  },
}));
