import {
  GL_CHEVRON_DOWN,
  GL_COLORS,
  GL_PENCIL,
  GLCard,
  GLDropdown,
  GLTypography,
  useI18n,
} from "@group-link-one/grouplink-components";
import { Helmet } from "react-helmet";

import { useCreateCampaignStore } from "../store/createCampaign";
import { useScheduleStore } from "../store/schedule";
import { ReviewContainer } from "./ReviewStyle";
import { useReview } from "./useReview";

export function Review() {
  const { t } = useI18n();
  const { segmentation, notificationList, schedule, handleOnClickInEdit } =
    useReview();
  const { state } = useCreateCampaignStore();
  const { state: scheduleState } = useScheduleStore();

  return (
    <ReviewContainer>
      <GLTypography
        text={`${t(
          "messageCenter.list.modal.creationModal.steps.four.labels.reviewCampaign"
        )} ${
          state.modalType === "creation"
            ? t(
                "messageCenter.list.modal.creationModal.steps.four.labels.scheduling"
              )
            : t("messageCenter.list.modal.creationModal.steps.four.labels.edit")
        }`}
        color="FONT_COLOR"
        fontSize={4}
        weight={400}
      />

      <div className="review-body">
        <div className="review-block">
          <div>
            <GLTypography
              text={t(
                "messageCenter.list.modal.creationModal.steps.four.labels.segmentation"
              )}
              color="FONT_COLOR"
              fontSize={4}
              weight={600}
            />
          </div>

          <div>
            <div className="review-info">
              <GLTypography
                text={t(
                  "messageCenter.list.modal.creationModal.steps.four.labels.campaignName"
                )}
                color="FONT_COLOR"
                fontSize={4}
                weight={400}
              />
              <GLTypography
                text={segmentation.campaignName}
                color="FONT_COLOR"
                fontSize={4}
                weight={600}
              />
            </div>

            <div className="review-info has-dropdown">
              <GLTypography
                text={t(
                  "messageCenter.list.modal.creationModal.steps.four.labels.sourceApps"
                )}
                color="FONT_COLOR"
                fontSize={4}
                weight={400}
              />

              <GLDropdown.Root width="100%">
                <GLDropdown.Input
                  value={segmentation.appsSelectedText}
                  icon={
                    <GL_CHEVRON_DOWN
                      fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                      size={12}
                    />
                  }
                  // width="100%"
                />

                <GLDropdown.BoxOptions>
                  {segmentation.appsSelected.map((app, index) => (
                    <GLDropdown.Option
                      key={index}
                      value={String(app.id)}
                      text={app.name}
                      hasCheckInActive={false}
                    />
                  ))}
                </GLDropdown.BoxOptions>
              </GLDropdown.Root>
            </div>

            <div className="review-info has-dropdown">
              <GLTypography
                text={`${t("messageCenter.list.modal.creationModal.steps.four.labels.audience")} (GL Device)`}
                color="FONT_COLOR"
                fontSize={4}
                weight={400}
              />

              <GLDropdown.Root width="100%">
                <GLDropdown.Input
                  value={segmentation.devicesSelectedText}
                  icon={
                    <GL_CHEVRON_DOWN
                      fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                      size={12}
                    />
                  }
                />

                <GLDropdown.BoxOptions>
                  {segmentation.devicesSelected.map((device, index) => (
                    <GLDropdown.Option
                      key={index}
                      value={String(device.device_id)}
                      text={`Device ID: ${String(device.device_id)}`}
                      hasCheckInActive={false}
                    />
                  ))}
                </GLDropdown.BoxOptions>
              </GLDropdown.Root>
            </div>
          </div>

          <button
            className="review-edit"
            onClick={() => handleOnClickInEdit("SEGMENTATION")}
          >
            <GL_PENCIL />
          </button>
        </div>

        <div className="review-block">
          <div>
            <GLTypography
              text={t(
                "messageCenter.list.modal.creationModal.steps.four.labels.notificationList"
              )}
              color="FONT_COLOR"
              fontSize={4}
            />
          </div>

          <div>
            <div className="review-notication-list">
              {/* <div className="notification-list-header">d
                <GLPaginationComponent
                  currentPage={1}
                  itemsPerPage={10}
                  totalItems={100}
                  nextPage={() => {}}
                  previousPage={() => {}}
                />
              </div> */}

              <div className="notification-list-body">
                {notificationList.messages.map((notification, index) => (
                  <GLCard
                    variant="sr-notification-list-card"
                    id={String(index + 1)}
                    key={notification.id}
                    message={notification.body}
                    title={notification.title}
                    type={notification.event_type}
                    minute={notification.minute}
                    onDelete={() => {}}
                  />
                ))}
              </div>
            </div>
          </div>

          <button
            className="review-edit"
            onClick={() => handleOnClickInEdit("MESSAGE_CONTENT")}
          >
            <GL_PENCIL />
          </button>
        </div>

        <div className="review-block">
          <div>
            <GLTypography
              text={t(
                "messageCenter.list.modal.creationModal.steps.four.labels.schedule"
              )}
              color="FONT_COLOR"
              fontSize={4}
              weight={600}
            />
          </div>

          <div>
            <div className="schedule-info">
              <GLTypography
                text={t(
                  "messageCenter.list.modal.creationModal.steps.four.labels.sendFrequency"
                )}
                color="FONT_COLOR"
                fontSize={4}
                weight={400}
              />

              <GLTypography
                text={schedule.frequency}
                color="FONT_COLOR"
                fontSize={4}
                weight={600}
              />
            </div>

            {/* <div className="schedule-info">
              <GLTypography
                text="Send period"
                color="FONT_COLOR"
                fontSize={4}
                weight={400}
              />

              <GLTypography
                text="Today"
                color="FONT_COLOR"
                fontSize={4}
                weight={600}
              />
            </div> */}

            <div className="schedule-info">
              <GLTypography
                text={t(
                  "messageCenter.list.modal.creationModal.steps.four.labels.minimumBetween"
                )}
                color="FONT_COLOR"
                fontSize={4}
                weight={400}
              />

              <GLTypography
                text={schedule.minimumInterval}
                color="FONT_COLOR"
                fontSize={4}
                weight={600}
              />
            </div>

            {scheduleState.startDateValueFormatted && (
              <div className="schedule-info">
                <GLTypography
                  text={`${t(
                    "messageCenter.list.modal.creationModal.steps.three.labels.startDate"
                  )}:`}
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={400}
                />

                <GLTypography
                  text={scheduleState.startDateValueFormatted}
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={600}
                />
              </div>
            )}

            {scheduleState.endDateValueFormatted && (
              <div className="schedule-info">
                <GLTypography
                  text={`${t(
                    "messageCenter.list.modal.creationModal.steps.three.labels.endDate"
                  )}:`}
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={400}
                />

                <GLTypography
                  text={scheduleState.endDateValueFormatted}
                  color="FONT_COLOR"
                  fontSize={4}
                  weight={600}
                />
              </div>
            )}
          </div>

          <button
            className="review-edit"
            onClick={() => handleOnClickInEdit("SCHEDULE")}
          >
            <GL_PENCIL />
          </button>
        </div>
      </div>

      <Helmet title="Message Center - Review" />
    </ReviewContainer>
  );
}
