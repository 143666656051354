import {
  GLDateRangeType,
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
  GLRelativeListOptionID,
  TabProps,
  useAuditStore,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

export const useAudit = () => {
  const { t } = useI18n();
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { actions: auditActions, state: auditState } = useAuditStore();

  const tabs: TabProps[] = [
    {
      id: 1,
      active: false,
      cacheKey: "all-audits",
      count: undefined,
      name: t("audit.list.tabs.one"),
      onClick: () => {},
    },
  ];

  const lastPageToken = {
    "all-audits": {
      token: undefined,
      page: undefined,
    },
  };

  const options: GLRelativeListOptionID[] = [
    "last_5_minutes",
    "last_15_minutes",
    "last_30_minutes",
    "last_1_hour",
    "last_3_hours",
    "last_6_hours",
    "last_12_hours",
    "last_24_hours",
    "last_3_days",
    "last_7_days",
    "last_15_days",
    "last_30_days",
  ];

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    currentRelativeTime: auditState.currentRelativeTime,
    currentDateRange: auditState.currentDateRange,
    rootOptions: {
      width: "fit-content",
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? "transparent" : "inherit",
        alignItems: isMobile ? "flex-end" : "initial",
      },
    },
    boxOptions: {
      position: "bottom-right",
      width: !isMobile ? "600px" : "calc(100vw - 80px)",
      style: {
        zIndex: 9999,
      },
    },
    inputOptions: {
      placeholder: t("audit.filters.dateFilter.placeholder"),
      style: {
        zIndex: 9999,
      },
    },
    options,
    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      auditActions.setCurrentRelativeTime(option);
    },

    setCurrentDateRange: (range?: GLDateRangeType) => {
      auditActions.setCurrentDateRange(range);
    },

    onRangeChange: async (range?: GLDateRangeType) => {
      if (!range?.from || !range?.to) return;
      auditActions.setRange(range);
    },
  };

  return {
    tabs,
    lastPageToken,
    relativeFilterTimeOptions,
  };
};
