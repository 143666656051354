import { IApp } from "@group-link-one/grouplink-components";
import { create } from "zustand";

interface SegmentationAppsProps extends IApp {
  app_id?: number | undefined;
  androidSelected?: boolean | undefined;
  iosSelected?: boolean | undefined;
}

interface SegmentationStoreState {
  campaignName: string;
  campaignNameError?: string | null;

  apps: SegmentationAppsProps[] | null;
  appsSelected: SegmentationAppsProps[] | [];
  appsError: string | null;
  textFieldValue: string;
  newAppSelected?: boolean | undefined;
}

interface SegmentationStoreActions {
  setCampaingName: (name: string) => void;
  setCampaignNameError: (error: string | null) => void;

  setApps: (apps: SegmentationAppsProps[] | null) => void;
  setAppsSelected: (
    app: SegmentationAppsProps,
    platform?: "ios" | "android"
  ) => void;
  setAppsError: (error: string | null) => void;
  setTextFieldValue: (value: string) => void;
  resetAll: (apps: SegmentationAppsProps[]) => void;
  setNewAppSelected: () => void;
}

interface UsersStore {
  state: SegmentationStoreState;
  actions: SegmentationStoreActions;
}

export const useSegmentationStore = create<UsersStore>((set) => ({
  state: {
    campaignName: "",
    campaignNameError: null,

    apps: null,
    appsSelected: [],
    appsError: null,
    textFieldValue: "",
  },
  actions: {
    setCampaingName: (name: string) => {
      set((state) => ({
        state: {
          ...state.state,
          campaignName: name,
        },
      }));
    },

    setNewAppSelected: () => {
      set((state) => ({
        state: {
          ...state.state,
          newAppSelected: state.state.newAppSelected ? false : true,
        },
      }));
    },

    setCampaignNameError: (error: string | null) => {
      set((state) => ({
        state: {
          ...state.state,
          campaignNameError: error,
        },
      }));
    },

    setApps: (apps: SegmentationAppsProps[] | null) => {
      set((state) => ({
        state: {
          ...state.state,
          apps: apps,
        },
      }));
    },

    setAppsSelected: (app: SegmentationAppsProps) => {
      set((state) => {
        const appIsAlreadySelected = state.state?.appsSelected?.find(
          (appSelected) =>
            appSelected?.app_id
              ? appSelected.app_id
              : appSelected.id === app?.id
        );

        if (appIsAlreadySelected) {
          const newAppsSelected = state.state.appsSelected.map(
            (appSelected) => {
              if (
                appSelected?.app_id
                  ? appSelected.app_id
                  : appSelected.id === app?.id
              ) {
                return {
                  ...appSelected,
                };
              }
              return appSelected;
            }
          );

          const hasTwoPlatformsSelectedInApp = newAppsSelected.some(
            (appSelected) => {
              if (
                appSelected?.app_id
                  ? appSelected.app_id
                  : appSelected.id === app?.id
              ) {
                return appSelected.androidSelected && appSelected.iosSelected;
              }
            }
          );

          if (!hasTwoPlatformsSelectedInApp) {
            return {
              state: {
                ...state.state,
                appsSelected: state.state.appsSelected.filter((appSelected) =>
                  appSelected?.app_id
                    ? appSelected.app_id
                    : appSelected.id !== app?.id
                ),
              },
            };
          }

          return {
            state: {
              ...state.state,
              appsSelected: newAppsSelected,
            },
          };
        }

        return {
          state: {
            ...state.state,
            appsSelected: [
              ...state.state.appsSelected,
              {
                ...app,
              },
            ],
          },
        };
      });
    },

    // setAppsSelected: (
    //   app: SegmentationAppsProps,
    //   platform?: "ios" | "android"
    // ) => {
    //   set((state) => {

    //     const appIsAlreadySelected = state.state?.appsSelected?.find(
    //       (appSelected) =>
    //         appSelected?.app_id
    //           ? appSelected.app_id
    //           : appSelected.id === app?.id
    //     );

    //     if (appIsAlreadySelected) {
    //       const platformSelected = appIsAlreadySelected[`${platform!}Selected`];

    //       const newAppsSelected = state.state.appsSelected.map(
    //         (appSelected) => {
    //           if (
    //             appSelected?.app_id
    //               ? appSelected.app_id
    //               : appSelected.id === app?.id
    //           ) {
    //             return {
    //               ...appSelected,
    //               [`${platform}Selected`]: !platformSelected,
    //             };
    //           }
    //           return appSelected;
    //         }
    //       );

    //       const hasAtLeastOnePlatformSelectedInApp = newAppsSelected.some(
    //         (appSelected) => {
    //           if (
    //             appSelected?.app_id
    //               ? appSelected.app_id
    //               : appSelected.id === app?.id
    //           ) {
    //             return appSelected.androidSelected || appSelected.iosSelected;
    //           }
    //         }
    //       );

    //       const hasTwoPlatformsSelectedInApp = newAppsSelected.some(
    //         (appSelected) => {
    //           if (
    //             appSelected?.app_id
    //               ? appSelected.app_id
    //               : appSelected.id === app?.id
    //           ) {
    //             return appSelected.androidSelected && appSelected.iosSelected;
    //           }
    //         }
    //       );

    //       if (!hasTwoPlatformsSelectedInApp) {
    //         return {
    //           state: {
    //             ...state.state,
    //             appsSelected: state.state.appsSelected.filter((appSelected) =>
    //               appSelected?.app_id
    //                 ? appSelected.app_id
    //                 : appSelected.id !== app?.id
    //             ),
    //           },
    //         };
    //       }

    //       if (!hasAtLeastOnePlatformSelectedInApp) {
    //         return {
    //           state: {
    //             ...state.state,
    //             appsSelected: state.state.appsSelected.filter((appSelected) =>
    //               appSelected?.app_id
    //                 ? appSelected.app_id
    //                 : appSelected.id !== app?.id
    //             ),
    //           },
    //         };
    //       }

    //       return {
    //         state: {
    //           ...state.state,
    //           appsSelected: newAppsSelected,
    //         },
    //       };
    //     }

    //     return {
    //       state: {
    //         ...state.state,
    //         appsSelected: [
    //           ...state.state.appsSelected,
    //           {
    //             ...app,
    //             [`${platform}Selected`]: true,
    //           },
    //         ],
    //       },
    //     };
    //   });
    // },

    setAppsError: (error: string | null) => {
      set((state) => ({
        state: {
          ...state.state,
          appsError: error,
        },
      }));
    },

    resetAll: (apps) => {
      set((state) => ({
        state: {
          ...state,
          campaignName: "",
          campaignNameError: null,

          apps: apps,
          appsSelected: [],
          appsError: null,
          textFieldValue: "",
        },
      }));
    },

    setTextFieldValue: (value: string) => {
      set((state) => ({
        state: {
          ...state.state,
          textFieldValue: value,
        },
      }));
    },
  },
}));
