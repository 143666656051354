import { GLBarChart, GLBoxChart } from "@group-link-one/grouplink-components";

import { useEntryAndExitEvents } from "./useEntryAndExitEvents";

export function GraphEntryAndExitEvents() {
  const { t, bars, data, deviceDetailsState } = useEntryAndExitEvents();

  return (
    <GLBoxChart
      title={t("deviceList.detailsModal.sr.graphs.one.title")}
      textSwitcherId="entry-exit-events"
      tooltipText={t(
        "deviceList.detailsModal.sr.graphs.one.tooltipDescription"
      )}
      isLoading={deviceDetailsState.isLoading}
      chart={
        <GLBarChart
          data={data}
          bars={bars}
          isLoading={false}
          legendOptions={{
            wrapperStyle: {
              top: -24,
            },
          }}
        />
      }
    />
  );
}
