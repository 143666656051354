import { GLMainContent, useI18n } from "@group-link-one/grouplink-components";

import { ModalCreateCampaign } from "../ModalCreateCampaign/modalCreateCampaign";
import { DeactivateCampaignModal } from "../ModalDeleteCampaign/modalDeleteCampaign";
import { OnlineCampaignContent } from "./Content/onlineCampaignContent";
import { OnlineCampaignRightContent } from "./RightContent/onlineCampaignRightContent";

export function OnlineCampaign() {
  const { t } = useI18n();

  return (
    <>
      <GLMainContent
        content={<OnlineCampaignContent />}
        rightContent={<OnlineCampaignRightContent />}
        subtitle={t("messageCenter.title")}
        title={t("messageCenter.subtitle")}
        hasList={true}
      />

      <DeactivateCampaignModal />
      <ModalCreateCampaign />
    </>
  );
}
