import { useAuth } from "@group-link-one/gl-auth";
import {
  BigNumberProps,
  Colors,
  GL_CHECK,
  GL_COLORS,
  GL_GLStation,
  GL_INTERNET,
  GL_LOGIN,
  TabProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useFlags } from "../../Context/FlagsProvider";
import CheckImg from "../../images/DeviceList/icon-check.svg";
import InternetImg from "../../images/DeviceList/icon-internet.svg";
import LoginImg from "../../images/DeviceList/icon-log-in.svg";
import StationImg from "../../images/DeviceList/icon-station.svg";
import { useDeviceListService } from "../../Services/deviceListService/useDeviceListService";
import { BignumbersResponse } from "../../Services/deviceListService/useDeviceListService.types";

export const useDeviceList = () => {
  const [activatedCount, setActivatedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [decommissionedCount, setDecommissionedCount] = useState(0);
  const [totalDevicesCount, setTotalDevicesCount] = useState(0);

  const isSM = useMediaQuery("(max-width: 660px)");
  const { user } = useAuth();
  const { t } = useI18n();
  const { getBignumbers } = useDeviceListService();

  const { userHasPrivileges, user: flagsUser, hasPrivileges } = useFlags();

  const tabs: TabProps[] = useMemo(() => {
    const Alltabs = [
      {
        id: 1,
        active: true,
        cacheKey: "devices-activated",
        count: undefined,
        name: t("deviceList.tabs.ut.one"),
        onClick: () => {},
      },
    ];

    if (userHasPrivileges(["OrganizationDevice.list"])) {
      Alltabs.push({
        id: 2,
        active: false,
        cacheKey: "pending-devices",
        count: undefined,
        name: t("deviceList.tabs.ut.two"),
        onClick: () => {},
      });
    }

    return Alltabs;
  }, [userHasPrivileges, flagsUser]);

  const lastPageToken = {
    "devices-activated": {
      token: undefined,
      page: undefined,
    },
    "pending-devices": {
      token: undefined,
      page: undefined,
    },
  };

  const { data: bignumbersResponse } = useQuery({
    queryKey: ["bignumbers"],
    queryFn: async () => {
      const response = await getBignumbers({
        org_id: user!.org.id!,
      });

      return response;
    },
  });

  useEffect(() => {
    if (bignumbersResponse) {
      const activated = parseInt(
        bignumbersResponse.data.rows.find(
          (item: BignumbersResponse) => item.type === "activated_devices"
        )?.count || "0",
        10
      );
      const pending = parseInt(
        bignumbersResponse.data.rows.find(
          (item: BignumbersResponse) => item.type === "pending_devices"
        )?.count || "0",
        10
      );
      const decommissioned = parseInt(
        bignumbersResponse.data.rows.find(
          (item: BignumbersResponse) => item.type === "decommissioned_devices"
        )?.count || "0",
        10
      );

      const totalDevices = activated + pending + decommissioned;

      setActivatedCount(activated);
      setPendingCount(pending);
      setDecommissionedCount(decommissioned);
      setTotalDevicesCount(totalDevices);
    }
  }, [bignumbersResponse]);

  const bigNumbers: BigNumberProps[] = [
    {
      iconLeft: (
        <GL_GLStation
          fillOne={GL_COLORS.BASE_BLACK}
          fill={GL_COLORS.BASE_BLACK}
          size={24}
        />
      ),
      iconRight: <img src={StationImg} alt="" />,
      iconRightStyle: {
        bottom: -20,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("deviceList.bigNumbers.one.title"),
      count: activatedCount,
      type: isSM ? "sm" : "lg",
      backgroundColor: GL_COLORS.LIGHT_GREEN,
      textColor: "BASE_BLACK",
      tooltipText: t("deviceList.bigNumbers.one.tooltipDescription"),
      helpIconColor: "BASE_BLACK",
      tooltipTextStyle: {
        whiteSpace: "nowrap"
      },
      tooltipZIndex: 100,
    },
    {
      iconLeft: <GL_LOGIN fill={GL_COLORS.WHITE} size={24} />,
      iconRight: <img src={LoginImg} alt="" />,
      iconRightStyle: {
        bottom: -20,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("deviceList.bigNumbers.two.title"),
      count: pendingCount,
      type: isSM ? "sm" : "lg",

      backgroundColor: Colors.NAVY,
      textColor: "WHITE",
      helpIconColor: "WHITE",
      tooltipText: t("deviceList.bigNumbers.two.tooltipDescription"),
      tooltipTextStyle: {
        whiteSpace: "nowrap"
      },
      tooltipZIndex: 100,
    },
    {
      iconLeft: <GL_CHECK fill={GL_COLORS.FONT_COLOR_VARIANT} size={24} />,
      iconRight: <img src={CheckImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("deviceList.bigNumbers.three.title"),
      count: decommissionedCount,
      type: isSM ? "sm" : "lg",

      backgroundColor: GL_COLORS.ACCENT_COLOR,
      textColor: "FONT_COLOR_VARIANT",
      tooltipText: t("deviceList.bigNumbers.three.tooltipDescription"),
      helpIconColor: "FONT_COLOR_VARIANT",
      tooltipTextStyle: {
        whiteSpace: "nowrap"
      },
      tooltipZIndex: 100,
    },
    {
      iconLeft: <GL_INTERNET fill={GL_COLORS.WHITE} size={24} />,
      iconRight: <img src={InternetImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
        right: isSM ? -40 : 20,
        transform: isSM ? "scale(0.85)" : "none",
      },
      title: t("deviceList.bigNumbers.four.title"),
      count: totalDevicesCount,
      type: isSM ? "sm" : "lg",
      backgroundColor: Colors.PURPLE,
      textColor: "WHITE",
      tooltipText: t("deviceList.bigNumbers.four.tooltipDescription"),
      helpIconColor: "WHITE",
      tooltipTextStyle: {
        whiteSpace: "nowrap"
      },
      tooltipZIndex: 100,
    },
  ];

  function hasPermissionToShowBigNumbers () {
    return hasPrivileges(["devices_activated_last_reading"])
  }

  return {
    tabs,
    lastPageToken,
    bigNumbers,
    hasPermissionToShowBigNumbers
  };
};
