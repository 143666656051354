import { GLModalDeviceDetails } from "@group-link-one/grouplink-components";

import { DeviceDetailsContent } from "./Content/DeviceDetailsContent";
import { DeviceDetailsTitleContentLeft } from "./TitleContent/DeviceDetailsTitleContent";
import { useModalDeviceDetails } from "./useModalDeviceDetails";

export function ModalDeviceDetails() {
  const { relativeFilterTimeOptions, isOpen, onClose } =
    useModalDeviceDetails();

  // if (!isOpen) return <></>;

  return (
    <GLModalDeviceDetails
      content={<DeviceDetailsContent />}
      titleContentLeft={<DeviceDetailsTitleContentLeft />}
      relativeFilterTimeOptions={relativeFilterTimeOptions}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}
