import {
  GLBoxChart,
  GLMatrixChart,
} from "@group-link-one/grouplink-components";

import { HeatMapContainer } from "./HeatMapStyle";
import { useHeatMap } from "./useHeatMap";

export function GraphHeatMap() {
  const {
    t,
    deviceDetailsState,
    textSwicherOptions,
    columns,
    optionActive,
    rows,
    values,
    onSelectItem,
  } = useHeatMap();

  return (
    <HeatMapContainer>
      <GLBoxChart
        title={t("deviceList.detailsModal.sr.graphs.three.title")}
        textSwitcherId="entry-exit-events"
        tooltipText={t(
          "deviceList.detailsModal.sr.graphs.three.tooltipDescription"
        )}
        optionsToTextSwitcher={textSwicherOptions}
        optionActive={optionActive}
        chart={<GLMatrixChart columns={columns} rows={rows} values={values} />}
        isLoading={deviceDetailsState.isLoading}
        onSelectItem={(value) => onSelectItem(value)}
      />
    </HeatMapContainer>
  );
}
