import { GL_COLORS } from "@group-link-one/grouplink-components";
import styled, { css } from "styled-components";

const flexColumn = (gap: number) => css`
  display: flex;
  flex-direction: column;
  gap: ${gap}px;
`;

export const ReviewContainer = styled.div`
  width: 80%;
  ${flexColumn(20)}

  .review-body {
    ${flexColumn(0)}

    border-radius: 10px;
    border: 1px solid ${GL_COLORS.BORDER_COLOR};

    .review-block {
      display: flex;
      position: relative;

      > div:nth-child(1) {
        flex: 0.3;

        border-right: 1px solid ${GL_COLORS.BORDER_COLOR};
        padding: 30px 20px;
      }

      > div:nth-child(2) {
        flex: 1;
        padding: 30px 20px;
        ${flexColumn(20)}

        .review-info {
          width: 80%;
          display: grid;
          gap: 20px;
          grid-template-columns: 40% 60%;
          align-items: center;

          &.has-dropdown {
            > div {
              width: 100%;
            }
          }
        }

        .schedule-info {
          width: 80%;
          display: grid;
          gap: 20px;
          grid-template-columns: 30% 70%;
          align-items: flex-start;
        }
      }

      > .review-edit {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        width: 50px;
        height: 50px;

        border-radius: 0px 9px 0px 10px;
        border: 1px solid ${GL_COLORS.BORDER_COLOR};

        background-color: ${GL_COLORS.BACKGROUND_SECONDARY};

        svg {
          fill: ${GL_COLORS.ACCENT_COLOR};
        }
      }

      & + .review-block {
        border-top: 1px solid ${GL_COLORS.BORDER_COLOR};
      }
    }

    .review-notication-list {
      border-radius: 5px;
      border: 1px solid ${GL_COLORS.BORDER_COLOR};
      width: calc(100% - 50px);

      .notification-list-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 0;

        background-color: ${GL_COLORS.BACKGROUND_SECONDARY};
      }
      .notification-list-body {
        padding: 15px 20px;
        ${flexColumn(10)}
      }
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 1024px) {
    padding-bottom: 120px;

    .review-body {
      .review-block {
        flex-direction: column;

        > div:nth-child(1) {
          border-right: none;
          border-bottom: 1px solid ${GL_COLORS.BORDER_COLOR};
        }

        > div:nth-child(2) {
          .review-info,
          .schedule-info {
            width: 100%;
            grid-template-columns: 100%;
            gap: 5px;
          }
        }
      }

      .review-notication-list {
        width: 100%;
      }
    }
  }
`;
