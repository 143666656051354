import { GLMainContent } from "@group-link-one/grouplink-components";

import { OverviewContent } from "./Content/OverviewContent";
import { OverviewRightContent } from "./RightContent/OverviewRightContent";
import { useOverview } from "./useOverview";

export function Overview() {
  const { t, greetingMessage } = useOverview();

  return (
    <GLMainContent
      title={greetingMessage()}
      subtitle="Dashboard"
      footerText={t("dashboard.greeting.relevantInformation")}
      rightContent={<OverviewRightContent />}
      content={<OverviewContent />}
      hasList
    />
  );
}
