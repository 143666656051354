import { useMotionValueEvent, useScroll } from "framer-motion";
import { useRef } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useMessageCenterStore } from "../../store/messageCenter";
import { useOnlineCampaignRightContent } from "../OnlineCampaign/RightContent/useOnlineRightContent";
import { useSegmentation } from "./Segmentation/hooks/useSegmentation";
import { useCreateCampaignStore } from "./store/createCampaign";
import { useMessageContentStore } from "./store/messageContent";
import { useSegmentationStore } from "./store/segmentation";
import { useSpotListStore } from "./store/spotList";

// import { useMessageContentStore } from "../store/messageContent";
// import { useScheduleStore } from "../store/schedule";
// import { useSpotListStore } from "../store/spotList";

export const useModalCreateCampaign = () => {
  const { actions: createCampaignActions, state: createCampaignState } =
    useCreateCampaignStore();

  const mediaIsMobile = useMediaQuery("(max-width: 1024px)");

  const DISTANCE_STEPER_FROM_TOP = mediaIsMobile ? 90 : 220;

  const { actions: messageContentActions } = useMessageContentStore();
  const { actions: spotListActions } = useSpotListStore();
  const { actions: segmentationStore } = useSegmentationStore();
  const { actions: messageCenterActions } = useMessageCenterStore();
  // const { actions: messageContentActions } = useMessageContentStore();
  // const { actions: scheduleActions } = useScheduleStore();
  const { appsFetch, devices } = useSegmentation();
  const { resetAll: resetAllFields } = useOnlineCampaignRightContent();

  const refDetailedModal = useRef<HTMLDivElement>(null);

  const { scrollY } = useScroll({
    container: refDetailedModal,
  });

  function resetAll() {
    createCampaignActions.resetAll();
    messageContentActions.addNewNotifications([]);
    spotListActions.setAudienceAddAllSpotsFiltered([]);
    resetAllFields();
    spotListActions.setAudienceAddSpotToListAdded([]);
    spotListActions.resetAll(devices, 1);
    spotListActions.setAudienceActiveTab(1);
    segmentationStore.resetAll(appsFetch!);
    messageCenterActions.resetAll();
  }

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (
      latest > DISTANCE_STEPER_FROM_TOP &&
      !createCampaignState.steperIsOnTop
    ) {
      createCampaignActions.setSteperIsOnTop(true);
    } else if (
      latest <= DISTANCE_STEPER_FROM_TOP &&
      createCampaignState.steperIsOnTop
    ) {
      createCampaignActions.setSteperIsOnTop(false);
    }
  });

  return {
    refDetailedModal,
    createCampaignActions,
    createCampaignState,
    resetAll,
  };
};
