import { GLDetailedModal, useI18n } from "@group-link-one/grouplink-components";

import { ModalCreateCampaignContent } from "./ModalContent/modalContent";
import { useModalCreateCampaign } from "./useModalCreateCampaign";

export function ModalCreateCampaign() {
  const { t } = useI18n();
  const {
    refDetailedModal,
    createCampaignActions,
    createCampaignState,
    resetAll,
  } = useModalCreateCampaign();

  return (
    <GLDetailedModal
      ref={refDetailedModal}
      open={createCampaignState.modalSteperContentIsOpen}
      setIs={(open) => {
        createCampaignActions.setModalSteperContentIsOpen(open);
        if (!open) resetAll();
      }}
      title={
        createCampaignState.modalType === "creation"
          ? t("messageCenter.list.modal.creationModal.title")
          : t("messageCenter.list.modal.creationModal.subtitle")
      }
      justifyContent="flex-start"
      width="100%"
      content={<ModalCreateCampaignContent />}
    />
  );
}
