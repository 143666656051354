import {
  GLDeviceList,
  GLPaginationProvider,
} from "@group-link-one/grouplink-components";

import { DeviceListContent } from "./Content/DeviceListContent";
import { ModalAreSureToEdit } from "./ModalAreSureToEdit/ModalAreSureToEdit";
import { ModalDeviceDetails } from "./ModalDeviceDetails/ModalDeviceDetails";
import { ModalRegisterDevice } from "./ModalRegisterDevice/ModalRegisterDevice";
import { useDeviceList } from "./useDeviceList";

export function DeviceList() {
  const { lastPageToken, tabs, bigNumbers, hasPermissionToShowBigNumbers } =
    useDeviceList();

  return (
    <GLPaginationProvider
      lastNextPageToken={lastPageToken}
      tabs={tabs}
      isInfiniteScroll
    >
      {/* <PageAnimated> */}
      <GLDeviceList
        allDevicesOptions={{
          deviceListContent: <DeviceListContent />,
        }}
        bigNumbersOptions={{ bigNumbers }}
        hasPermissionToShowBigNumbers={hasPermissionToShowBigNumbers()}
        isOnTopHeaderContent={!hasPermissionToShowBigNumbers()}
        modalRegister={<ModalRegisterDevice />}
        modalDetails={<ModalDeviceDetails />}
        modalAreYouSure={<ModalAreSureToEdit />}
      />
      {/* </PageAnimated> */}
    </GLPaginationProvider>
  );
}
