import { GLBox } from "@group-link-one/grouplink-components";
import { useMediaQuery } from "usehooks-ts";

import { OverviewBigNumbers } from "./BigNumbers/OverviewBigNumbers";
import { OverviewGraphs } from "./Graphs/OverviewGraphs";

export function OverviewContent() {
  const isSM = useMediaQuery("(max-width: 660px)");

  return (
    <GLBox direction="column" gap={isSM ? 15 : 20}>
      <OverviewBigNumbers />
      <OverviewGraphs />
    </GLBox>
  );
}
