import {
  BigNumberProps,
  Colors,
  GL_CLOCK,
  GL_COLORS,
  GL_LOGIN,
  GL_SCALE,
  GL_USER,
  GLBarChartDataProps,
  useActiveLanguageDateFns,
  useI18n,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";
import { useMediaQuery } from "usehooks-ts";

import AverageImg from "../../../../images/DeviceList/bigNumbers/details-big-number-clock.svg";
import EnteringImg from "../../../../images/DeviceList/bigNumbers/details-big-number-log-in.svg";
import PeakPeriodImg from "../../../../images/DeviceList/bigNumbers/details-big-number-scale.svg";
import VisitingImg from "../../../../images/DeviceList/bigNumbers/details-big-number-user.svg";
import { useDetailsDeviceStore } from "../../store/details-device-store";

export const useDeviceDetailsContent = () => {
  const { state: deviceDetailsState } = useDetailsDeviceStore();
  const isSM = useMediaQuery("(max-width: 660px)");
  const { t } = useI18n();

  const localeActive = useActiveLanguageDateFns();

  const bigNumbers: BigNumberProps[] = [
    {
      iconLeft: <GL_LOGIN fill={GL_COLORS.ACCENT_COLOR} size={24} />,
      iconRight: <img src={EnteringImg} alt="" />,
      iconRightStyle: {
        bottom: -20,
        right: 30,
        display: isSM ? "none" : "block",
      },
      title: t("deviceList.detailsModal.sr.bigNumbers.one.title"),
      count: getEnteringEventsCount() || 0,
      countColor: "FONT_COLOR",
      type: isSM ? "sm" : "lg",
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      textColor: "FONT_COLOR_DARK_GREY",
      tooltipText: t(
        "deviceList.detailsModal.sr.bigNumbers.one.tooltipDescription"
      ),
      hasBorder: true,
      hasShadow: false,
    },
    {
      iconLeft: <GL_USER fill={Colors.LEMON_GREEN} size={24} />,
      iconRight: <img src={VisitingImg} alt="" />,
      iconRightStyle: {
        bottom: 0,
        right: 30,
        display: isSM ? "none" : "block",
      },
      type: isSM ? "sm" : "lg",
      title: t("deviceList.detailsModal.sr.bigNumbers.two.title"),
      textColor: "FONT_COLOR_DARK_GREY",
      count: getOutgoingEventsCount() || 0,
      countColor: "FONT_COLOR",
      tooltipText: t(
        "deviceList.detailsModal.sr.bigNumbers.two.tooltipDescription"
      ),
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      hasBorder: true,
    },
    {
      iconLeft: <GL_CLOCK fill={GL_COLORS.PURPLE} size={24} />,
      iconRight: <img src={AverageImg} alt="" />,
      iconRightStyle: {
        bottom: -14,
        right: 30,
        display: isSM ? "none" : "block",
      },
      title: t("deviceList.detailsModal.sr.bigNumbers.three.title"),
      textColor: "FONT_COLOR_DARK_GREY",
      count: getAverageTime(),
      type: isSM ? "sm" : "lg",
      countColor: "FONT_COLOR",
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      tooltipText: t(
        "deviceList.detailsModal.sr.bigNumbers.three.tooltipDescription"
      ),
      hasBorder: true,
    },
    {
      iconLeft: <GL_SCALE fill={GL_COLORS.NAVY} size={24} />,
      iconRight: <img src={PeakPeriodImg} alt="" />,
      iconRightStyle: {
        bottom: -15,
        right: 15,
        display: isSM ? "none" : "block",
      },
      title: t("deviceList.detailsModal.sr.bigNumbers.four.title"),
      textColor: "FONT_COLOR_DARK_GREY",
      count: getPeakPeriod(),
      countColor: "FONT_COLOR",
      countSize: isSM ? 5 : 6.5,
      type: isSM ? "sm" : "lg",
      backgroundColor: GL_COLORS.BACKGROUND_PRIMARY,
      tooltipText: t(
        "deviceList.detailsModal.sr.bigNumbers.four.tooltipDescription"
      ),
      hasBorder: true,
    },
  ];

  function getEnteringEventsCount() {
    const enteringEvents = deviceDetailsState?.currentData?.filter(
      (data) => data.event_type === "EVENT_IN"
    );

    const countAllEnteringEvents = enteringEvents?.reduce(
      (acc, curr) => acc + curr.events_count,
      0
    );

    return countAllEnteringEvents;
  }

  function getOutgoingEventsCount() {
    const outgoingEvents = deviceDetailsState?.currentData?.filter(
      (data) => data.event_type === "EVENT_OUT"
    );

    const countAllOutgoingEvents = outgoingEvents?.reduce(
      (acc, curr) => acc + curr.events_count,
      0
    );

    return countAllOutgoingEvents;
  }

  function getOutgoingEvents() {
    if (!deviceDetailsState.currentData) return [];

    const dailyReadginsEventOut = deviceDetailsState.currentData.filter(
      (item) => item.event_type === "EVENT_OUT"
    );

    const dailyReadginsFormattedByDate: GLBarChartDataProps[] =
      dailyReadginsEventOut.map((reading) => {
        return {
          label: format(new Date(reading.event_day), "yyyy-MM-dd"),
          x: Number((reading.duration_p50 / (60 * 1000)).toFixed(2)),
          valueType: "minutes",
        };
      });

    return dailyReadginsFormattedByDate;
  }

  function formatMinutesToMinutesSeconds(minutes: number) {
    const totalSeconds = Math.floor(minutes * 60);
    const mins = Math.floor(totalSeconds / 60);
    const secs =
      totalSeconds % 60 > 9 ? totalSeconds % 60 : `0${totalSeconds % 60}`;

    return `${mins}m ${secs}s`;
  }

  function getAverageTime() {
    const data = getOutgoingEvents();

    if (!data) return 0;

    const sum = data.reduce((acc, item) => {
      return acc + item.x!;
    }, 0);

    return sum !== 0 ? formatMinutesToMinutesSeconds(sum / data.length) : "--";
  }

  function getPeakPeriod() {
    const periodWithMostEvents = getOutgoingEvents().find((outgoingEvent) => {
      return (
        outgoingEvent.x ===
        Math.max(
          ...getOutgoingEvents().map(
            (outgoingEventParam) => outgoingEventParam.x!
          )
        )
      );
    });

    if (!periodWithMostEvents) return "--";

    return format(periodWithMostEvents.label, "MMM d, yyyy", {
      locale: localeActive,
    });
  }

  return {
    bigNumbers,
    deviceDetailsState,
  };
};
