import {
  useEventListStore,
  useI18n,
  useToast,
} from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useEventListService } from "../../../Services/eventListService/useEventListService";
import { GetEventListResponse } from "../../../Services/eventListService/useEventListService.types";
import { queryClient } from "../../../Services/queryClient";

export const useTableContent = () => {
  const { t } = useI18n();
  const { getEventList, getDevices } = useEventListService();
  const { actions, state } = useEventListStore();
  const { addToast } = useToast();
  const isMobile = useMediaQuery("(max-width: 1024px)");

  const columns = [
    {
      columnName: t("eventList.columns.created_at"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.sent"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.latency"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.device_id"),
    },
    {
      columnName: t("eventList.columns.user_id"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.model"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.type"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.duration"),
      hasActions: false,
    },
  ];

  const {
    data: events,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [
      "get-event-list",
      format(
        new Date(state.range.from || new Date()),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      format(
        new Date(state.range.to || new Date()),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      state.devices_id_to_request,
      state.userId,
    ],
    queryFn: async () => {
      if (state.devices_id_to_request.length > 0 || state.userId) {
        try {
          const response = await getEventList({
            from: format(
              new Date(state.range.from || ""),
              "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
            ),
            until: format(
              new Date(state.range.to || ""),
              "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
            ),
            next_page_token: undefined,
            device_id: state.devices_id_to_request,
            user_id: state.userId,
          });

          actions.setNextPageToken(response.next_page_token || "");

          if (response.rows.length === 0) {
            actions.setEmptystate(
              t("eventList.list.emptyState.title2"),
              t("eventList.list.emptyState.description2")
            );

            addToast({
              title: `${t("attention")}!`,
              message: t("eventList.list.emptyState.description2"),
              type: "info",
            });

            return;
          }

          actions.setError(false);

          return response.rows;
        } catch (err: unknown) {
          const error = err as AxiosError<Error>;
          if (
            error?.response?.data?.message[0] ===
              "from must be a valid ISO 8601 date string" ||
            error?.response?.data?.message[1] ===
              "until must be a valid ISO 8601 date string"
          ) {
            addToast({
              title: t("eventList.errors.title"),
              message: t("eventList.errors.message"),
              type: "error",
            });
            actions.setError(true);
          }
          actions.setError(true);
        }
      }

      return;
    },
  });

  const previousData = queryClient.getQueryData(["get-event-list"]);

  const { data: devices_id, isLoading: deviceLoading } = useQuery({
    queryKey: ["get-devices-id"],
    queryFn: async () => {
      const response = await getDevices();

      return response.rows;
    },
    staleTime: 1000 * 60 * 60,
  });

  async function getMoreEvents() {
    const devicesIDFormatted = state.devices_id_to_request?.map((deviceid) =>
      Number(deviceid)
    );

    if (!state.next_page_token) return [];

    actions.setIsFetchingMoreEvents(true);

    const response = await getEventList({
      from: format(
        new Date(state.range.from || ""),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      until: format(
        new Date(state.range.to || ""),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      next_page_token: state.next_page_token,
      device_id: devicesIDFormatted,
      user_id: state.userId,
    });

    actions.setIsFetchingMoreEvents(false);

    actions.setNextPageToken(response.next_page_token || "");

    if (response.rows.length === 0) {
      actions.setEmptystate(
        t("eventList.list.emptyState.title2"),
        t("eventList.list.emptyState.description2")
      );

      addToast({
        title: `${t("attention")}!`,
        message: t("eventList.list.emptyState.description2"),
        type: "info",
      });

      return;
    }

    actions.setError(false);

    return response.rows;
  }

  const isAtBottom = (event: React.UIEvent<HTMLElement>): boolean => {
    const finalScroll =
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight;

    return (
      finalScroll - event.currentTarget.scrollTop < 100 &&
      finalScroll - event.currentTarget.scrollTop > -100
    );
  };

  const handleScroll = async (event: React.UIEvent<HTMLElement>) => {
    if (!isAtBottom(event) || state.isFetchingMoreEvents) return;

    const moreEvents = await getMoreEvents();

    if (!moreEvents || moreEvents.length === 0) return;

    const currentData: GetEventListResponse[] | undefined =
      queryClient.getQueryData([
        "get-event-list",
        format(
          new Date(state.range.from || ""),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        format(new Date(state.range.to || ""), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        state.devices_id_to_request,
        state.userId,
      ]);

    if (!currentData) return;

    queryClient.setQueryData(
      [
        "get-event-list",
        format(
          new Date(state.range.from || ""),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        format(new Date(state.range.to || ""), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        state.devices_id_to_request,
        state.userId,
      ],
      currentData.concat(moreEvents)
    );
  };

  useEffect(() => {
    actions.resetAllSR();
  }, []);

  useEffect(() => {
    const devicesID = devices_id?.map((device) => device.device_id);
    actions.setDevicesId(devicesID);
  }, [devices_id]);

  return {
    events,
    handleScroll,
    devices_id,
    isLoading,
    isMobile,
    previousData,
    deviceLoading,
    actions,
    state,
    refetch,
    columns,
  };
};
