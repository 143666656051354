import React, { useEffect, useRef, useState } from "react";
import { Image as KonvaImage, Layer, Stage } from "react-konva";
import simpleheat from "simpleheat";
import useImage from "use-image";

import { data } from "./data";
import lojaPNG from "./loja.png";

interface Point {
  x: number;
  y: number;
  value: number;
}

interface EditableImageProps {
  imageUrl: string;
  points: Point[];
}

// const generateNoise = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
//   const imageData = ctx.createImageData(width, height);
//   for (let i = 0; i < imageData.data.length; i += 4) {
//     const value = (Math.random() * 255) | 0;
//     imageData.data[i] = value;
//     imageData.data[i + 1] = value;
//     imageData.data[i + 2] = value;
//     imageData.data[i + 3] = 255;
//   }
//   ctx.putImageData(imageData, 0, 0);
// };

// const drawSmoothIrregularHeatmap = (canvas: HTMLCanvasElement, points: Point[], width: number, height: number) => {
//   const ctx = canvas.getContext("2d");
//   if (!ctx) return;

//   // Gerar multiplas áreas de ruido
//   const noiseCanvas = document.createElement("canvas");
//   noiseCanvas.width = width;
//   noiseCanvas.height = height;
//   const noiseCtx = noiseCanvas.getContext("2d");
//   if (!noiseCtx) return;
//   generateNoise(noiseCtx, width, height);

//   ctx.clearRect(0, 0, width, height);

//   points.forEach(point => {
//     const gradient = ctx.createRadialGradient(point.x, point.y, 0, point.x, point.y, point.value * 20);
//     gradient.addColorStop(0, "rgba(255, 0, 0, 1)");
//     gradient.addColorStop(0.2, "rgba(255, 165, 0, 0.8)");
//     gradient.addColorStop(0.4, "rgba(255, 255, 0, 0.6)");
//     gradient.addColorStop(0.6, "rgba(0, 255, 0, 0.5)");
//     gradient.addColorStop(0.8, "rgba(0, 0, 255, 0.3)");
//     gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

//     ctx.fillStyle = gradient;

//     // Criar um formato irregular suave usando ruido para deformação
//     const radius = point.value * 20;
//     ctx.beginPath();
//     for (let i = 0; i <= 2 * Math.PI; i += Math.PI / 16) {
//       const angle = i;
//       const noise = noiseCtx.getImageData(
//         (point.x + radius * Math.cos(angle)) % width,
//         (point.y + radius * Math.sin(angle)) % height,
//         1,
//         1
//       ).data[0];
//       const deformation = (noise / 255 - 0.5) * 10;
//       const x = point.x + (radius + deformation) * Math.cos(angle);
//       const y = point.y + (radius + deformation) * Math.sin(angle);
//       if (i === 0) {
//         ctx.moveTo(x, y);
//       } else {
//         ctx.lineTo(x, y);
//       }
//     }
//     ctx.closePath();
//     ctx.fill();
//   });
// };

const EditableImage: React.FC<EditableImageProps> = ({ imageUrl, points }) => {
  const [image] = useImage(imageUrl);
  const canvasRef = useRef<HTMLCanvasElement>(document.createElement("canvas"));
  const [heatmap, setHeatmap] = useState<any>(null);

  useEffect(() => {
    if (canvasRef.current && image) {
      const canvas = canvasRef.current;
      canvas.width = image.width;
      canvas.height = image.height;
      const heat = simpleheat(canvas);
      setHeatmap(heat);
    }
  }, [canvasRef, image]);

  useEffect(() => {
    if (heatmap && image) {
      heatmap.gradient({
        1.0: "#4040c5",
        0.8: "#335CFF",
        0.6: "#788DE3",
        0.7: "#274ff1",
        0.4: "#4D70FF",
        0.2: "#C3D4F6",
        0.1: "#CCD6FF",
      });
      heatmap.data(data);
      heatmap.radius(15, 40); // ajuste do raio e blur
      heatmap.max(8); // ajuste conforme necessário

      const canvas = canvasRef.current;
      canvas.width = image.width;
      canvas.height = image.height;

      heatmap.draw(canvas.getContext("2d"));
    }
  }, [heatmap, points, image]);

  return (
    <Stage width={window.innerWidth} height={window.innerHeight}>
      <Layer>
        <KonvaImage image={image} />
        <KonvaImage
          image={canvasRef.current}
          width={image?.width}
          height={image?.height}
        />
      </Layer>
    </Stage>
  );
};

const HeatMap: React.FC = () => {
  const [points, setPoints] = useState<Point[]>([]);
  useEffect(() => {
    // Gerar pontos aleatórios com intensidade variável
    const generateRandomPoints = (
      numPoints: number,
      width: number,
      height: number
    ): Point[] => {
      const randomPoints: Point[] = [];
      for (let i = 0; i < numPoints; i++) {
        const x = Math.random() * width;
        const y = Math.random() * height;
        const value = Math.random() * 5 + 1;
        randomPoints.push({ x, y, value });
      }
      return randomPoints;
    };

    const img = new Image();
    img.src = lojaPNG;
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      const randomPoints = generateRandomPoints(25, width, height); // Gerar 50 pontos random
      setPoints(randomPoints);
    };
  }, []);

  // useEffect(() => {
  //   if (points.length === 0) {
  //     const img = new Image();
  //     img.src = lojaPNG
  //     img.onload = () => {
  //       const centerX = img.width / 2;
  //       const centerY = img.height / 2;
  //       setPoints([
  //         { x: centerX - 100, y: centerY - 50, value: 5 },
  //         { x: centerX + 100, y: centerY - 120, value: 5 },
  //         { x: centerX, y: centerY + 90, value: 5 },
  //       ]);
  //     };
  //   }
  // }, [points.length]);

  return <EditableImage imageUrl={lojaPNG} points={points} />;
};

export default HeatMap;
