import { useReview } from "../Review/useReview";
import { useMessageContentStore } from "../store/messageContent";
import { useSegmentationStore } from "../store/segmentation";
import { useSpotListStore } from "../store/spotList";

export const useModalContent = () => {
  const { state: segmentationState, actions: segmentationActions } =
    useSegmentationStore();

  const { sendForm, _updateCampaign } = useReview();

  const { spotListAddeds, setSpotListError } = useSpotListStore(
    (spotListState) => ({
      setSpotListError: spotListState.actions.setAudienceSpotListError,
      spotListAddeds: spotListState.state.glSpotsAddeds,
    })
  );

  const { actions: messageContentActions, state: messageContentState } =
    useMessageContentStore();

  const functionToVerifyIfCanAdvanceMap = {
    segmentation: verifySegmentationIsAllCorrect,
    "message-content": verifyMessageContentIsAllCorrect,
    schedule: () => false,
    review: () => false,
  };

  function verifySegmentationIsAllCorrect() {
    let isError = false;

    if (!segmentationState.campaignName) {
      segmentationActions.setCampaignNameError("Campaign name is required");
      isError = true;
    } else {
      segmentationActions.setCampaignNameError("");
    }

    if (segmentationState.appsSelected.length === 0) {
      segmentationActions.setAppsError("At least one app is required");
      isError = true;
    }

    if (spotListAddeds.length === 0) {
      setSpotListError("At least one device is required");
      isError = true;
    } else {
      setSpotListError("");
    }

    return isError;
  }

  function verifyMessageContentIsAllCorrect() {
    if (messageContentState.notificationsList.length === 0) {
      messageContentActions.setNotificationError(
        "At least one notification is required"
      );
      return true;
    }

    messageContentActions.setNotificationError("");

    return false;
  }

  return {
    functionToVerifyIfCanAdvanceMap,
    sendForm,
    _updateCampaign,
  };
};
