import {
  useGLPagination,
  useInfiniteScroll,
} from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";

import { useDeviceListService } from "../../../Services/deviceListService/useDeviceListService";
import {
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesPendingActivationParams,
} from "../../../Services/deviceListService/useDeviceListService.types";

export const DEVICE_LIST_IPP = 30;

export const useDeviceListContent = () => {
  const [distanceFromHeader, setDistanceFromHeader] = useState(0);

  const { getDevicesPendingActivation, getDevicesActivatedLastReadings } =
    useDeviceListService();

  const { state: paginationState, actions: paginationActions } =
    useGLPagination();

  const listContainerRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const { onInfiniteScroll } = useInfiniteScroll();

  const searchDeviceID = useMemo(() => {
    if (
      paginationState.search.length >= 0 &&
      paginationState.search.length < 10
    )
      return undefined;

    return isNaN(Number(paginationState.search))
      ? undefined
      : [Number(paginationState.search)];
  }, [paginationState.search]);

  const activeTab = useMemo(() => {
    return paginationActions.getActiveTabById(paginationState.activeTabId);
  }, [paginationState.activeTabId]);

  const { data: deviceList, isFetching: deviceListIsLoading } = useQuery({
    queryKey: ["devices-activateds-list", searchDeviceID],
    enabled: activeTab?.id === 1,
    queryFn: async () => {
      if (
        paginationState.search.length > 0 &&
        paginationState.search.length < 10
      )
        return getCurrentDeviceList();

      const { optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      console.log(
        "paginationState.search.length",
        paginationState.search.length
      );

      const options: GetDevicesActivatedLastReadingsParams = {
        ipp: DEVICE_LIST_IPP,
        next_page_token: undefined,
        application: "GLSmartRetail",
        device_id: searchDeviceID,
      };

      const response = await getDevicesActivatedLastReadings(options);

      paginationActions.setNextPageToken({
        hasMore: response.has_more,
        nextPageToken: response.next_page_token || undefined,
        optionsToStoreNextPageToken,
      });

      return response.rows;
    },
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: devicesPendingActivation,
    isLoading: devicesPendingActivationIsLoading,
  } = useQuery({
    queryKey: ["devices-pending-activation-list", searchDeviceID],
    enabled: activeTab?.id === 2,
    queryFn: async () => {
      const { nextPageToken, optionsToStoreNextPageToken } =
        paginationActions.getNextPageToken();

      const options: GetDevicesPendingActivationParams = {
        ipp: DEVICE_LIST_IPP,
        next_page_token: nextPageToken,
        application: "GLSmartRetail",
        device_id: searchDeviceID,
      };

      const response = await getDevicesPendingActivation(options);

      paginationActions.setNextPageToken({
        hasMore: response.has_more,
        nextPageToken: response.next_page_token || undefined,
        optionsToStoreNextPageToken,
      });

      return response.rows;
    },
    staleTime: 1000 * 60 * 5,
  });

  function getCurrentDeviceList() {
    const currentDeviceList:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData([
      "devices-activateds-list",
      paginationState.currentPage,
      "",
    ]);

    return currentDeviceList;
  }

  async function getMoreDevicesOnScroll() {
    const activeTabIdMap = {
      "tab-1": {
        do: async (options: GetDevicesActivatedLastReadingsParams) => {
          return await getDevicesActivatedLastReadings(options);
        },
        cachekey: "devices-activateds-list",
      },
      "tab-2": {
        do: async (options: GetDevicesPendingActivationParams) => {
          return await getDevicesPendingActivation(options);
        },
        cachekey: "devices-pending-activation-list",
      },
    };

    if (paginationState.isFetchingInfiniteScroll) return;

    const { nextPageToken, optionsToStoreNextPageToken } =
      paginationActions.getNextPageToken();

    if (!nextPageToken) return;

    const options:
      | GetDevicesPendingActivationParams
      | GetDevicesActivatedLastReadingsParams = {
      ipp: DEVICE_LIST_IPP,
      next_page_token: nextPageToken,
      application: "GLSmartRetail",
      device_id: searchDeviceID,
    };

    paginationActions.setIsFetchingInfiniteScroll(true);

    const tabMapActive =
      activeTabIdMap[`tab-${activeTab?.id}` as keyof typeof activeTabIdMap];

    const response = await tabMapActive.do(options);

    paginationActions.setIsFetchingInfiniteScroll(false);

    paginationActions.setNextPageToken({
      hasMore: response.has_more,
      nextPageToken: response.next_page_token || undefined,
      optionsToStoreNextPageToken,
    });

    const currentDeviceList:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData([
      tabMapActive.cachekey,
      searchDeviceID,
    ]);

    if (!currentDeviceList) return;

    queryClient.setQueryData(
      [tabMapActive.cachekey, searchDeviceID],
      [...currentDeviceList, ...response.rows]
    );
  }

  function invalidateAllQueries() {
    queryClient.invalidateQueries({
      queryKey: ["devices-activateds-list"],
    });

    queryClient.invalidateQueries({
      queryKey: ["devices-pending-activation-list"],
    });
  }

  const isLoading = useMemo(() => {
    const loadingMap = {
      1: deviceListIsLoading,
      2: devicesPendingActivationIsLoading,
    };

    return loadingMap[activeTab?.id as keyof typeof loadingMap];
  }, [devicesPendingActivationIsLoading, deviceListIsLoading]);

  const currentDeviceList = useMemo(() => {
    const deviceListMap = {
      1: deviceList,
      2: devicesPendingActivation,
    };

    return deviceListMap[activeTab?.id as keyof typeof deviceListMap];
  }, [activeTab?.id, deviceList, devicesPendingActivation]);

  useEffect(() => {
    paginationActions.tabsActions.setOnClick(paginationState.tabs[0], () => {
      paginationActions.setCurrentPage(0);
      paginationActions.setActiveTab(1);
    });

    if (paginationState.tabs[1]) {
      paginationActions.tabsActions.setOnClick(paginationState.tabs[1], () => {
        paginationActions.setCurrentPage(0);
        paginationActions.setActiveTab(2);
      });
    }

    invalidateAllQueries();
    // TODO
    // paginationActions.tabsActions.setCount(paginationState.tabs[1], 100);
  }, []);

  useEffect(() => {
    paginationActions.setIsLoading(deviceListIsLoading);
  }, [deviceListIsLoading]);

  useEffect(() => {
    setTimeout(() => {
      const header = document.querySelector("header")?.getBoundingClientRect();

      if (listContainerRef.current) {
        setDistanceFromHeader(
          listContainerRef.current.offsetTop - (header?.height || 0)
        );
      }
    }, 200);
  }, []);

  return {
    activeTab,
    listContainerRef,
    distanceFromHeader,
    currentDeviceList,
    isLoading,
    paginationState,
    paginationActions,
    tabs: paginationState.tabs,
    devicesCount: undefined,
    getMoreDevicesOnScroll,
    onInfiniteScroll,
  };
};
