import {
  formatValueSize,
  GL_COLORS,
  GL_PENCIL,
  GL_PIN,
  GLBox,
  GLButton,
  GLTextField,
  GLTooltip,
  GLTypography,
} from "@group-link-one/grouplink-components";
import { format } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";

import { useDetailsDeviceStore } from "../../store/details-device-store";
import { DeviceNameContainer } from "./DeviceDetailsTitleContentStyle";
import { useDeviceDetailsTitleContent } from "./useDeviceDetailsTitleContent";

export function DeviceDetailsTitleContentLeft() {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { state: detailsDeviceStore } = useDetailsDeviceStore();

  const {
    t,
    editInputRef,
    editingDeviceNameValue,
    isEditingDeviceName,
    is768Px,
    onDeviceNameClick,
    onAddressClick,
    registerDeviceActions,
    registerDeviceState,
  } = useDeviceDetailsTitleContent();

  const deviceAddress = detailsDeviceStore.device?.meta.display_address || "";

  return (
    <GLBox
      width="80%"
      direction="column"
      gap={20}
      style={{ marginRight: !isMobile ? 30 : 0 }}
    >
      <GLBox gap={isMobile ? 15 : 20} align={"flex-start"} direction={"column"}>
        <GLBox direction="column" align="flex-start">
          <DeviceNameContainer
            className={isEditingDeviceName ? "editing" : ""}
            onClick={onDeviceNameClick}
            tabIndex={0}
            role="button"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (
                  editingDeviceNameValue === registerDeviceState.newDeviceName
                ) {
                  return;
                }

                registerDeviceActions.setCanEdit(true);
                registerDeviceActions.setModalAreYouSureIsOpen(true);
                onDeviceNameClick();
              }
            }}
          >
            <AnimatePresence mode="wait">
              {isEditingDeviceName ? (
                <GLBox
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  style={{ maxWidth: 300 }}
                >
                  <GLTextField
                    ref={editInputRef}
                    border={true}
                    icon={<GL_PENCIL fill={GL_COLORS.ACCENT_COLOR} />}
                    defaultValue={editingDeviceNameValue}
                    style={{
                      fontWeight: "600",
                      fontSize: is768Px ? "22px" : "25px",
                    }}
                    onChange={(e) => {
                      registerDeviceActions.setNewDeviceName(e.target.value);
                    }}
                    max={50}
                    maxLength={50}
                  />
                </GLBox>
              ) : (
                <motion.div
                  initial={{ opacity: 0, x: 0 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 0 }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                    width: "100%",
                  }}
                  className="device-name-text-box"
                >
                  <GLTypography
                    as="p"
                    text={editingDeviceNameValue || ""}
                    color="FONT_COLOR"
                    weight={600}
                    fontSize={is768Px ? 5.5 : 6.5}
                    style={{
                      whiteSpace: !is768Px ? "nowrap" : "normal",
                      outline: "transparent",
                    }}
                  />

                  <GL_PENCIL fill={GL_COLORS.ACCENT_COLOR} />
                </motion.div>
              )}
            </AnimatePresence>
          </DeviceNameContainer>

          <GLTypography
            text={`ID ${detailsDeviceStore.device?.device_id || ""}`}
            as="p"
            color="FONT_COLOR_DARK_GREY"
            fontSize={3}
            style={{ whiteSpace: "nowrap" }}
          />
        </GLBox>

        <GLTooltip
          text={deviceAddress.length > 70 ? deviceAddress : undefined}
          position="top-left"
        >
          <GLButton
            text={formatValueSize({
              value: deviceAddress,
              size: 70,
            })}
            variant="outline"
            align="left"
            border={false}
            icon={<GL_PIN size={18} fill={GL_COLORS.ACCENT_COLOR} />}
            color={GL_COLORS.ACCENT_COLOR}
            weight={600}
            onClick={() => {
              if (
                !detailsDeviceStore.device?.meta.latitude ||
                !detailsDeviceStore.device?.meta.longitude
              ) {
                return;
              }

              onAddressClick(
                detailsDeviceStore.device?.meta.latitude,
                detailsDeviceStore.device?.meta.longitude
              );
            }}
            style={{
              width: "fit-content",
              fontSize: 14,
              // alignItems: "flex-end",
              height: "fit-content",
              textAlign: "left",
            }}
          />
        </GLTooltip>
      </GLBox>

      <GLBox
        width="fit-content"
        align="center"
        gap={30}
        style={{
          borderRadius: 16,
          backgroundColor: GL_COLORS.DISABLED_BACKGROUND_COLOR,
          padding: "15px 20px",
          maxWidth: 640,
        }}
      >
        <GLBox width="fit-content" align="center" gap={8}>
          <GLTypography
            text={t("deviceList.detailsModal.registeredAt")}
            as="p"
            color="FONT_COLOR_DARK_GREY"
            fontSize={3}
            weight={600}
            style={{ textTransform: "uppercase" }}
          />

          <GLTypography
            text={format(
              new Date(
                detailsDeviceStore.device?.channels[0].activated_at || ""
              ),
              "MMM d, yyyy"
            )}
            as="p"
            color="FONT_COLOR"
            fontSize={3}
            weight={600}
            style={{ whiteSpace: "nowrap", textTransform: "uppercase" }}
          />
        </GLBox>
      </GLBox>
    </GLBox>
  );
}
