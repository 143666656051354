import {
  CreateReportBody,
  CreateReportResponse,
  DeleteReportParams,
  DeleteReportResponse,
  GetReportsAvailableResponse,
  GetReportsParams,
  GetReportsResponse,
} from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useReportListService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  async function getReports({
    name,
  }: GetReportsParams): Promise<GetReportsResponse["rows"]> {
    const response = await httpPrivate.get<GetReportsResponse>("/reports", {
      params: {
        name,
      },
    });

    return response.data.rows;
  }

  async function getReportsAvailables(): Promise<
    GetReportsAvailableResponse["rows"]
  > {
    const response = await httpPrivate.get<GetReportsAvailableResponse>(
      "/reports/available-reports"
    );

    return response.data.rows;
  }

  async function createReport({
    name,
    params,
    type,
  }: CreateReportBody): Promise<CreateReportResponse> {
    const response = await httpPrivate.post("/reports", {
      name,
      params,
      type,
    });

    return response.data;
  }

  async function deleteReport({
    id,
  }: DeleteReportParams): Promise<DeleteReportResponse> {
    const response = await httpPrivate.delete(`/reports/report/${id}`);

    return response.data;
  }

  return {
    getReports,
    getReportsAvailables,
    createReport,
    deleteReport,
  };
};
