import { PaginateResponseProps } from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import {
  BignumbersParams,
  GetDevicesActivatedDailyReadingsParams,
  GetDevicesActivatedDailyReadingsResponse,
  GetDevicesActivatedLastReadingsParams,
  GetDevicesActivatedLastReadingsResponse,
  GetDevicesCountParams,
  GetDevicesCountResponse,
  GetDevicesDowSummaryParams,
  GetDevicesDowSummaryResponse,
  GetDevicesPendingActivationParams,
  GetDevicesPendingActivationResponse,
  RegisterDeviceBody,
  RegisterDeviceResponse,
  UpdateDeviceNameParams,
  UpdateDeviceParams,
} from "./useDeviceListService.types";

export const useDeviceListService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getDevicesActivatedLastReadings = async ({
    ...params
  }: GetDevicesActivatedLastReadingsParams): Promise<
    PaginateResponseProps<GetDevicesActivatedLastReadingsResponse[]>
  > => {
    const response = await httpPrivate.get("/devices-activated/last-reading", {
      params,
    });

    return response.data;
  };

  const getDevicesActivatedDailyReadings = async ({
    ...params
  }: GetDevicesActivatedDailyReadingsParams): Promise<
    GetDevicesActivatedDailyReadingsResponse["rows"]
  > => {
    const response =
      await httpPrivate.get<GetDevicesActivatedDailyReadingsResponse>(
        "/sr/daily-summary",
        {
          params,
        }
      );

    return response.data.rows;
  };

  const getDevicesDowSummary = async ({
    ...params
  }: GetDevicesDowSummaryParams): Promise<
    GetDevicesDowSummaryResponse["rows"]
  > => {
    const response = await httpPrivate.get<GetDevicesDowSummaryResponse>(
      "/sr/dow-summary",
      {
        params,
      }
    );

    return response.data.rows;
  };

  const getDevicesCount = async (
    params?: GetDevicesCountParams
  ): Promise<GetDevicesCountResponse> => {
    const response = await httpPrivate.get("/device-activation/count", {
      params,
    });

    return response.data;
  };

  const getDevicesPendingActivation = async ({
    ...params
  }: GetDevicesPendingActivationParams): Promise<
    PaginateResponseProps<GetDevicesPendingActivationResponse[]>
  > => {
    const response = await httpPrivate.get("/devices-pending-activation", {
      params,
    });

    return response.data;
  };

  const registerDevice = async (
    body: RegisterDeviceBody
  ): Promise<RegisterDeviceResponse> => {
    const response = await httpPrivate.post("/device-activation", body);

    return response.data;
  };

  // const getLocationByAddress = async (
  //   address: string
  // ): Promise<GetLocationByAddressResponse[]> => {
  //   const addressWithoutSpecialCharacters = address
  //     .normalize("NFD")
  //     .replace(/[\u0300-\u036f]/g, "")
  //     .replace(/[^a-zA-Z0-9 ]/g, "")
  //     .replace(/ +/g, "+");

  //   const response = await axios.get(
  //     `https://nominatim.openstreetmap.org/search?addressdetails=1&q=${addressWithoutSpecialCharacters}&format=jsonv2&countrycodes=BR`
  //   );

  //   return await response.data;
  // };

  const updateDevice = async (params: UpdateDeviceParams) => {
    const response = await httpPrivate.patch("/device-activation", params);
    return response.data;
  };

  const updateDeviceName = async (params: UpdateDeviceNameParams) => {
    const response = await httpPrivate.patch("/sr/device-name", params);

    return response.data;
  };

  const getBignumbers = async (params: BignumbersParams) => {
    const response = await httpPrivate.get("my-organization/count-panel", {
      params,
    });

    return response;
  };

  return {
    getDevicesActivatedLastReadings,
    getDevicesActivatedDailyReadings,
    getDevicesDowSummary,
    getDevicesCount,
    getDevicesPendingActivation,
    getBignumbers,
    registerDevice,
    updateDeviceName,
    updateDevice,
  };
};
