import { GLModalAreSureToEditDevice } from "@group-link-one/grouplink-components";

import { useDeviceDetailsTitleContent } from "../ModalDeviceDetails/TitleContent/useDeviceDetailsTitleContent";

export const ModalAreSureToEdit = () => {
  const { onClickToEditDeviceName, loading } = useDeviceDetailsTitleContent();

  return (
    <GLModalAreSureToEditDevice
      onClickEdit={onClickToEditDeviceName}
      isLoading={loading}
    />
  );
};
