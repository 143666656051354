import {
  GL_PLUS,
  GLBox,
  GLButton,
  GLButtonIcon,
  GLExtendedTextField,
} from "@group-link-one/grouplink-components";

import { useSegmentation } from "../../ModalCreateCampaign/Segmentation/hooks/useSegmentation";
import { useSpotListStore } from "../../ModalCreateCampaign/store/spotList";
import { useOnlineCampaignRightContent } from "./useOnlineRightContent";

export function OnlineCampaignRightContent() {
  const { createCampaignActions, handleInputChange, t, resetAll, isMobile } =
    useOnlineCampaignRightContent();
  const { actions: spotListActions } = useSpotListStore();
  const { devices } = useSegmentation();

  return (
    <GLBox gap={!isMobile ? 40 : 30} align="center" justify="flex-end">
      <GLExtendedTextField onChange={(e) => handleInputChange(e)} />

      {!isMobile ? (
        <GLButton
          variant="fill"
          align="center"
          weight={600}
          text={t("messageCenter.headerButton")}
          onClick={() => {
            createCampaignActions.setModalType("creation");
            createCampaignActions.setModalSteperContentIsOpen(true);
            spotListActions.resetAll(devices, 1);
            spotListActions.setAudienceActiveTab(1);
            resetAll();
          }}
          style={{
            width: "fit-content",
            padding: "15px 30px",
          }}
        />
      ) : (
        <GLButtonIcon
          variant="fill"
          icon={<GL_PLUS size={12} />}
          onClick={() => {
            createCampaignActions.setModalType("creation");
            createCampaignActions.setModalSteperContentIsOpen(true);
            spotListActions.resetAll(devices, 1);
            spotListActions.setAudienceActiveTab(1);
            resetAll();
          }}
        />
      )}
    </GLBox>
  );
}
