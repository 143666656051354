import { GLBigNumberCard } from "@group-link-one/grouplink-components";
import React from "react";

import { OverviewBigNumbersContainer } from "./OverviewBigNumbersStyle";
import { useOverviewBigNumbers } from "./useOverviewBigNumbers";

export function OverviewBigNumbers() {
  const { bigNumbers, isSM } = useOverviewBigNumbers();

  return (
    <OverviewBigNumbersContainer direction="row" gap={isSM ? 15 : 20}>
      {bigNumbers &&
        bigNumbers.map((bigNumber, index) => (
          <GLBigNumberCard bigNumber={bigNumber} key={index} />
        ))}
    </OverviewBigNumbersContainer>
  );
}
