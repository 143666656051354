import {
  GL_COLORS,
  GLBarChartDataProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { addDays, differenceInDays, format } from "date-fns";
import { useMemo } from "react";

import { useDetailsDeviceStore } from "../../../store/details-device-store";

export const useAverageTime = () => {
  const { state: deviceDetailsState } = useDetailsDeviceStore();
  const { t } = useI18n();

  const bars = [
    {
      fill: GL_COLORS.PURPLE,
      dataKey: "x",
      name: "X",
    },
  ];

  const data = useMemo(() => {
    if (
      !deviceDetailsState.currentData ||
      !deviceDetailsState.range.from ||
      !deviceDetailsState.range.to
    )
      return [];

    const dailyReadginsEventOut = deviceDetailsState.currentData.filter(
      (item) => item.event_type === "EVENT_OUT"
    );

    const dailyReadginsFormattedByDate: GLBarChartDataProps[] =
      dailyReadginsEventOut.map((reading) => {
        return {
          label: format(new Date(reading.event_day), "MM/dd/yyyy"),
          x: Number((reading.duration_p50 / (60 * 1000)).toFixed(2)),
          valueType: "minutes",
        };
      });

    const countAllDays = differenceInDays(
      deviceDetailsState.range.to,
      deviceDetailsState.range.from
    );

    const allDays: GLBarChartDataProps[] = Array.from({
      length: countAllDays + 1,
    }).map((_, index) => {
      const date = format(
        addDays(new Date(deviceDetailsState.range.from!), index),
        "MM/dd/yyyy"
      );

      const existingDate = dailyReadginsFormattedByDate.find(
        (item) => item.label === date
      );

      if (existingDate) {
        return {
          label: date,
          x:
            dailyReadginsFormattedByDate.find((item) => item.label === date)
              ?.x || 0,
          valueType: "number",
          unit: "min",
        };
      } else {
        return {
          label: date,
          x: 0,
          valueType: "number",
        };
      }
    });

    return allDays;
  }, [deviceDetailsState.currentData]);

  const averageTime = useMemo(() => {
    if (!data) return 0;

    const sum = data.reduce((acc, item) => {
      return acc + item.x!;
    }, 0);

    return Number((sum / data.length).toFixed(2));
  }, [data]);

  return {
    t,
    data,
    bars,
    deviceDetailsState,
    averageTime,
  };
};
