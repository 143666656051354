import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import {
  IOverviewPushService,
  IOverviewVisitsService,
} from "./useOverviewServices.types";

export const useOverviewServices = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getOSPushs = async ({ from, until, os_name }: IOverviewPushService) => {
    return await httpPrivate.post("/sr/pushes-dashboard", {
      from,
      until,
      os_name,
    });
  };

  const getPushsPerHour = async ({ from, until }: IOverviewPushService) => {
    return await httpPrivate.post("/sr/pushes-hour", {
      from,
      until,
    });
  };

  const getVisitings = async ({ from, until }: IOverviewVisitsService) => {
    return await httpPrivate.post("/sr/visiting-dashboard", {
      from,
      until,
    });
  };

  const getVisitingsApiKey = async ({
    from,
    until,
  }: IOverviewVisitsService) => {
    return await httpPrivate.post("/sr/visiting-dashboard-apikey", {
      from,
      until,
    });
  };

  return {
    getOSPushs,
    getPushsPerHour,
    getVisitings,
    getVisitingsApiKey,
  };
};
