import {
  AuditRow,
  GL_COLORS,
  GLBox,
  GLEmptyList,
  GLList,
  GLLoading,
  useI18n,
} from "@group-link-one/grouplink-components";
import { GL_USER_XMARK } from "@group-link-one/grouplink-components";

import { AuditCard } from "../Cards/AuditCard";
import { useAuditContent } from "./useAuditContent";
export const AuditContent = () => {
  const { t } = useI18n();
  const {
    audits,
    tabs,
    activeTab,
    auditIsLoading,
    paginationState,
    isToShowEmptyList,
    isToShowError,
    showFilteredEmptyList,
    getMoreDevicesOnScroll,
    onInfiniteScroll,
  } = useAuditContent();

  return (
    <GLList
      tabs={tabs}
      tabActive={activeTab?.name || ""}
      hasFixedHeaderAnimation={false}
      isLoading={auditIsLoading}
      style={{
        height: "80%",
      }}
      content={
        <>
          {audits?.map((audit: AuditRow) => (
            <AuditCard key={audit.id} item={audit} />
          ))}
          {showFilteredEmptyList && (
            <GLEmptyList
              hasBorder={false}
              title={t("audit.list.emptyList.auditNotFound.title")}
              description={t("audit.list.emptyList.auditNotFound.description")}
              icon={
                <GL_USER_XMARK
                  size={30}
                  fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                />
              }
            />
          )}
          {paginationState.isFetchingInfiniteScroll && (
            <GLBox align="center" justify="center">
              <GLLoading type="dots" size="primary" />
            </GLBox>
          )}
        </>
      }
      mode="infinit-scroll"
      type={isToShowEmptyList || isToShowError ? "empty" : "list"}
      listName={t("audit.subtitle")}
      onScroll={(event) =>
        onInfiniteScroll({ event, callback: getMoreDevicesOnScroll })
      }
    />
  );
};
