import { GL_COLORS } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const DeviceNameContainer = styled.div`
  cursor: pointer;
  transition: all 0.4s;
  border: 1px solid transparent;

  display: flex;
  align-items: center;
  gap: 20px;

  min-width: 300px;

  position: relative;
  z-index: 999;

  svg {
    opacity: 1;
    transition: all 0.4s;
  }

  .device-name-text-box {
    svg {
      flex: 0 0 22px;
    }
  }

  &:hover,
  &.editing,
  &:focus {
    /* border-bottom: 1px solid ${GL_COLORS.ACCENT_COLOR}; */

    svg {
      opacity: 1;
    }
  }
`;
