import { TabProps, useI18n } from "@group-link-one/grouplink-components";

export const useReportList = () => {
  const { t } = useI18n();

  const tabs: TabProps[] = [
    {
      id: 1,
      active: true,
      cacheKey: "reports-created",
      count: 0,
      name: t("reports.tabsList.created"),
      onClick: () => {},
    },
    {
      id: 2,
      active: false,
      cacheKey: "reports-processing",
      count: 0,
      name: t("reports.tabsList.processing"),
      onClick: () => {},
    },
  ];

  return { tabs, t };
};
