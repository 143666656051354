import {
  GLDateRangeType,
  GLRelativeListOption,
  i18n,
} from "@group-link-one/grouplink-components";
import { subDays } from "date-fns";
import { create } from "zustand";

interface OverviewStoreState {
  currentRelativeTime: GLRelativeListOption | undefined;
  currentDateRange: GLDateRangeType | undefined;

  range: {
    from: Date | undefined;
    to: Date | undefined;
  };

  allPushesCount: number;
  peakPeriod: string;
}

interface OverviewStoreActions {
  setCurrentRelativeTime: (option?: GLRelativeListOption) => void;
  setCurrentDateRange: (range?: GLDateRangeType) => void;

  setRange: (date: GLDateRangeType) => void;

  setAllPushesCount: (count: number) => void;
  setPeakPeriod: (period: string) => void;
}

interface OverviewStore {
  state: OverviewStoreState;
  actions: OverviewStoreActions;
}

export const useOverviewStore = create<OverviewStore>((set) => ({
  state: {
    currentRelativeTime: {
      id: "last_30_days",
      range: {
        from: subDays(new Date(), 30),
        to: new Date(),
      },
      text: i18n.t("days.last30Days"),
    },
    currentDateRange: undefined,

    range: {
      from: subDays(new Date(), 30),
      to: new Date(),
    },

    allPushesCount: 0,
    peakPeriod: "0h",
  },
  actions: {
    setCurrentRelativeTime: (currentRelativeTime) =>
      set((state) => ({
        state: {
          ...state.state,
          currentRelativeTime,
        },
      })),

    setCurrentDateRange: (currentDateRange) =>
      set((state) => ({
        state: {
          ...state.state,
          currentDateRange,
        },
      })),

    setRange: (range) =>
      set((state) => ({
        state: {
          ...state.state,
          range,
        },
      })),

    setAllPushesCount: (count) =>
      set((state) => ({
        state: {
          ...state.state,
          allPushesCount: count,
        },
      })),

    setPeakPeriod: (period) =>
      set((state) => ({
        state: {
          ...state.state,
          peakPeriod: period,
        },
      })),
  },
}));
