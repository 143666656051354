import {
  GL_COLORS,
  GLBarChartDataProps,
  GLPieChartDataProps,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useMediaQuery } from "usehooks-ts";

import { useOverviewServices } from "../../../../Services/overviewServices/useOverviewServices";
import { useOverviewStore } from "../../../MessageCenter/store/overview";

type Pushs = {
  push: number;
  hora: number;
};

type OS = {
  title: string;
  os: string;
  event_type: string;
  count: number;
};

export const useOverviewGraphs = () => {
  const isSM = useMediaQuery("(max-width: 660px)");
  const { t } = useI18n();
  const { getPushsPerHour, getOSPushs } = useOverviewServices();
  const { selectedLanguege } = useI18n();
  const { actions: overviewActions, state: overviewState } = useOverviewStore();

  function formatBigNumber(value: number) {
    return value.toLocaleString(selectedLanguege, {
      maximumFractionDigits: 0,
    });
  }

  const { data: pushsOS, isLoading: pushOSLoading } = useQuery({
    queryKey: [
      "get-pushs-per-os",
      format(
        new Date(overviewState.range.from!),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      format(new Date(overviewState.range.to!), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    ],
    queryFn: async () => {
      const response = await getOSPushs({
        from: format(
          new Date(overviewState.range.from!),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        until: format(
          new Date(overviewState.range.to!),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
      });

      const allPushesCount = response.data.rows.reduce(
        (total: number, os: OS) => total + os.count,
        0
      );

      overviewActions.setAllPushesCount(allPushesCount);

      return response.data.rows;
    },
  });

  const graphOSData: GLPieChartDataProps[] = [
    {
      label: "Android",
      value:
        pushsOS
          ?.filter((os: OS) => os.os === "android")
          ?.reduce((total: number, os: OS) => total + os.count, 0) || 0,
      color: GL_COLORS.LIGHT_GREEN,
      description: "pushes",
      tooltipTitle: "Android",
      hasToShowTooltipTitle: true,
      unit: "pushes",
    },
    {
      label: "iOS",
      value:
        pushsOS
          ?.filter((os: OS) => os.os === "iOS")
          ?.reduce((total: number, os: OS) => total + os.count, 0) || 0,
      color: GL_COLORS.ACCENT_COLOR,
      description: "pushes",
      unit: "pushes",
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: [
      "get-pushs-per-hour",
      format(
        new Date(overviewState.range.from!),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      format(new Date(overviewState.range.to!), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    ],
    queryFn: async () => {
      const response = await getPushsPerHour({
        from: format(
          new Date(overviewState.range.from!),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        until: format(
          new Date(overviewState.range.to!),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
      });

      return response.data.rows;
    },
  });

  const graphPushesData: GLBarChartDataProps[] = [];
  for (let i = 0; i < 24; i++) {
    const pushData = data?.find(({ hora }: Pushs) => hora === i);
    const push = pushData ? pushData.push : 0;
    graphPushesData.push({
      label: `${i}h`,
      type: "number",
      unit: "pushes",
      x: push,
    });
  }

  const bars = [
    {
      fill: GL_COLORS.ACCENT_COLOR,
      dataKey: "x",
      name: "Pushes",
    },
  ];

  const graphTypeEventsData: GLPieChartDataProps[] = [
    {
      label: t("messageCenter.overview.graphs.labels.event_in"),
      value:
        pushsOS
          ?.filter((os: OS) => os.event_type === "EVENT_IN")
          ?.reduce((total: number, os: OS) => total + os.count, 0) || 0,
      color: GL_COLORS.LIGHT_GREEN,
      description: t("messageCenter.overview.graphs.labels.events"),
      tooltipTitle: "Android",
      hasToShowTooltipTitle: true,
      unit: t("messageCenter.overview.graphs.labels.events"),
    },
    {
      label: t("messageCenter.overview.graphs.labels.event_out"),
      value:
        pushsOS
          ?.filter((os: OS) => os.event_type === "EVENT_OUT")
          ?.reduce((total: number, os: OS) => total + os.count, 0) || 0,
      color: GL_COLORS.NAVY,
      description: t("messageCenter.overview.graphs.labels.events"),
      unit: t("messageCenter.overview.graphs.labels.events"),
    },
  ];

  return {
    formatBigNumber,
    graphOSData,
    graphPushesData,
    graphTypeEventsData,
    data,
    isLoading,
    pushOSLoading,
    bars,
    isSM,
    t,
  };
};
