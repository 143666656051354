import {
  GL_BINOCULARS,
  GL_COLORS,
  GLEmptyList,
  GLTable,
  GLTableSkeleton,
} from "@group-link-one/grouplink-components";

import { CardsDesktop, CardsMobile } from "../Cards/cards";
// import { EventListCards } from "../Cards/EventListCards";
import { useTableContent } from "./useTableContent";

export function EventListTableContent() {
  const {
    notificationList,
    handleScroll,
    isLoading,
    notificationListState,
    columns,
    isMobile,
  } = useTableContent();

  return (
    <>
      {notificationList && notificationList?.length > 0 ? (
        <GLTable
          onScroll={handleScroll}
          columns={columns}
          isLoading={notificationListState.isFetchingMoreEvents}
          content={
            !isMobile ? (
              <CardsDesktop items={notificationList} />
            ) : (
              <CardsMobile items={notificationList} />
            )
          }
        />
      ) : isLoading ? (
        <GLTableSkeleton />
      ) : (
        <GLEmptyList
          title={notificationListState.emptyState.title}
          description={notificationListState.emptyState.description}
          icon={
            <GL_BINOCULARS size={30} fill={GL_COLORS.FONT_COLOR_DARK_GREY} />
          }
          hasBorder
        />
      )}
    </>
  );
}
