import { GLModalSteperContent } from "@group-link-one/grouplink-components";

import { MessageContent } from "../MessageContent/messageContent";
import { Review } from "../Review/Review";
import { Schedule } from "../Schedule/Schedule";
import { Segmentation } from "../Segmentation/segmentation";
import { useCreateCampaignStore } from "../store/createCampaign";
import { useModalContent } from "./useModalContent";

const contentsPerStep = {
  segmentation: <Segmentation />,
  "message-content": <MessageContent />,
  schedule: <Schedule />,
  review: <Review />,
};

export function ModalCreateCampaignContent() {
  const { state, actions } = useCreateCampaignStore();

  const { functionToVerifyIfCanAdvanceMap, _updateCampaign, sendForm } =
    useModalContent();

  return (
    <GLModalSteperContent
      actions={actions}
      state={state}
      contentsPerStep={contentsPerStep}
      isLoading={state.isLoading}
      functionToVerifyIfCanAdvanceMap={functionToVerifyIfCanAdvanceMap}
      sendForm={sendForm}
      updateForm={_updateCampaign}
    />
  );
}
