export function getPeakPeriod(
  data: [],
  hourKey = "hora",
  pushKey = "visiting"
) {
  const pushesByHour = new Map<number, number>();

  data?.forEach((item) => {
    const hour = item[hourKey];
    const pushCount = item[pushKey];
    const currentPushes = pushesByHour.get(hour) || 0;
    pushesByHour.set(hour, currentPushes + pushCount);
  });

  let peakHour: number | null = null;
  let maxPushes = 0;

  pushesByHour.forEach((totalPushes, hour) => {
    if (totalPushes > maxPushes) {
      maxPushes = totalPushes;
      peakHour = hour;
    }
  });

  return peakHour !== null ? `${peakHour}h` : null;
}
