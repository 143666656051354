import { i18n } from "@group-link-one/grouplink-components";

export type ErrorType =
  | "invalid_email_or_pass"
  | "blocked_password"
  | "Unauthorized"
  | string;

interface SmartRetailErrorsProps {
  error?: ErrorType;
}

export class SmartRetailErrors {
  private error?: ErrorType;

  constructor({ error }: SmartRetailErrorsProps) {
    this.error = error;
  }

  private errorsMap(): { [key: string]: { title: string; message: string } } {
    return {
      invalid_email_or_pass: {
        title: i18n.t("login.form.errors.request.loginFailed"),
        message: i18n.t("login.form.errors.request.loginFailedDescription"),
      },

      blocked_password: {
        title: i18n.t("login.form.errors.request.blockedAccount"),
        message: i18n.t("login.form.errors.request.blockedAccountDescription"),
      },

      Unauthorized: {
        title: "Unauthorized",
        message: "Session is no longer active",
      },

      default: {
        title: i18n.t("login.form.errors.request.default"),
        message: i18n.t("login.form.errors.request.defaultDescription"),
      },
    };
  }

  public getError() {
    if (!this.error) return this.errorsMap().default;

    const findError = this.error in this.errorsMap();

    const error = findError
      ? this.errorsMap()[this.error]
      : this.errorsMap().default;

    return error;
  }
}
