import { i18n } from "@group-link-one/grouplink-components";
import { create } from "zustand";

import { GLSpotsMockProps } from "../../../../../mock/gl-spots";
import { TabProps } from "../../../../Settings/Users/Content/useUsersContent";

interface SpotListStoreState {
  currentPage: number;
  activeTabId: number | undefined;
  search: string;
  tabs: TabProps[];
  spotListError: string | undefined;

  glSpotsSelected: GLSpotsMockProps[];
  glSpotsOnList: GLSpotsMockProps[];
  glSpotsOnFiltered: GLSpotsMockProps[];

  glSpotsAddeds: GLSpotsMockProps[];
  allGlSpots: GLSpotsMockProps[];

  nextPageTokenSearching: string | undefined;
  listType: "grid" | "list";
  selectAll: boolean;
  lastNextPageToken: {
    "all-gl-spots": {
      token: string | undefined;
      page: number | undefined;
    };
    "gl-spots-added": {
      token: string | undefined;
      page: number | undefined;
    };
  };
}

interface SpotListStoreActions {
  setAudienceListType: (type: "grid" | "list") => void;
  setAudienceSelectAll: (selectAll: boolean) => void;
  setAudienceActiveTab: (tabId: number) => void;
  setAudienceSearch: (search: string) => void;
  setAudienceNextPageToken: (token: string) => void;
  setAudienceLastNextPageToken: (
    key: "all-gl-spots" | "gl-spots-added",
    token: string,
    page: number
  ) => void;
  setAudienceCurrentPage: (page: number) => void;
  setAudienceTabs: (tabs: TabProps[]) => void;
  setAudienceSpotListError: (error: string | undefined) => void;

  setAudienceAddSpotToListSelected: (spot: GLSpotsMockProps[]) => void;
  setAudienceAddCurrentListSpots: (spot: GLSpotsMockProps[]) => void;

  setAudienceAddSpotToListAdded: (spot: GLSpotsMockProps[]) => void;
  setAudienceAddAllSpots: (spot: GLSpotsMockProps[]) => void;
  setAudienceAddAllSpotsFiltered: (spot: GLSpotsMockProps[]) => void;
  setAudienceIncrementAllSpots: (spot: GLSpotsMockProps[]) => void;

  setAudienceResetList: () => void;
  setAudienceRemoveSpotFromAddedList: (spot: GLSpotsMockProps) => void;
  setAudienceRemoveSpotFromGLSpotAllList: (spot: GLSpotsMockProps) => void;
  setAudienceRemoveSpotFromSelectedList: (spot: GLSpotsMockProps) => void;

  tabsActions: {
    setCount: (tab: TabProps, count: number) => void;
    setOnClick: (tab: TabProps, onClick: () => void) => void;
  };

  resetAll: (
    glSpots: GLSpotsMockProps[],
    activeTab: number | undefined
  ) => void;
}

interface UsersStore {
  state: SpotListStoreState;
  actions: SpotListStoreActions;
}

export const useSpotListStore = create<UsersStore>((set) => ({
  state: {
    currentPage: 0,
    activeTabId: undefined,
    search: "",
    nextPageTokenSearching: undefined,
    spotListError: undefined,

    glSpotsSelected: [],
    glSpotsOnList: [],
    glSpotsOnFiltered: [],

    glSpotsAddeds: [],
    allGlSpots: [],

    listType: "grid",
    selectAll: false,
    lastNextPageToken: {
      "all-gl-spots": {
        token: undefined,
        page: undefined,
      },
      "gl-spots-added": {
        token: undefined,
        page: undefined,
      },
    },
    tabs: [
      {
        id: 1,
        name: i18n.t(
          "messageCenter.list.modal.creationModal.steps.one.audienceList.tabs.one"
        ),
        active: undefined,
        count: 0,
        onClick: () => {},
        cacheKey: "all-gl-spots",
      },
      {
        id: 2,
        name: i18n.t(
          "messageCenter.list.modal.creationModal.steps.one.audienceList.tabs.two"
        ),
        active: true,
        count: 0,
        onClick: () => {},
        cacheKey: "gl-spots-added",
      },
    ],
  },
  actions: {
    setAudienceActiveTab: (tabId: number) => {
      set((state) => ({
        state: {
          ...state.state,
          activeTabId: tabId,
        },
      }));
    },

    setAudienceSearch: (search: string) => {
      set((state) => ({
        state: {
          ...state.state,
          search: search,
        },
      }));
    },

    setAudienceNextPageToken: (token: string) => {
      set((state) => ({
        state: {
          ...state.state,
          nextPageTokenSearching: token,
        },
      }));
    },

    setAudienceLastNextPageToken: (
      key: "all-gl-spots" | "gl-spots-added",
      token: string,
      page: number
    ) => {
      set((state) => ({
        state: {
          ...state.state,
          lastNextPageToken: {
            ...state.state.lastNextPageToken,
            [key]: {
              token: token,
              page: page,
            },
          },
        },
      }));
    },

    setAudienceCurrentPage: (page: number) => {
      set((state) => ({
        state: {
          ...state.state,
          currentPage: page,
        },
      }));
    },

    setAudienceTabs: (tabs: TabProps[]) => {
      set((state) => ({
        state: {
          ...state.state,
          tabs: tabs,
        },
      }));
    },

    setAudienceAddSpotToListSelected: (spot: GLSpotsMockProps[]) => {
      set((state) => ({
        state: {
          ...state.state,
          glSpotsSelected: [...state.state.glSpotsSelected, ...spot],
        },
      }));
    },

    setAudienceAddSpotToListAdded: (spot: GLSpotsMockProps[]) => {
      set((state) => ({
        state: {
          ...state.state,
          glSpotsAddeds: [...state.state.glSpotsAddeds, ...spot],
        },
      }));
    },

    setAudienceAddCurrentListSpots: (spot: GLSpotsMockProps[]) => {
      set((state) => ({
        state: {
          ...state.state,
          glSpotsOnList: spot,
        },
      }));
    },

    setAudienceRemoveSpotFromSelectedList: (spot: GLSpotsMockProps) => {
      set((state) => ({
        state: {
          ...state.state,
          glSpotsSelected: state.state.glSpotsSelected.filter(
            (s) => s.device_id !== spot.device_id
          ),
        },
      }));
    },

    setAudienceRemoveSpotFromGLSpotAllList: (spot: GLSpotsMockProps) => {
      set((state) => ({
        state: {
          ...state.state,
          allGlSpots: state.state.allGlSpots.filter(
            (s) => s.device_id !== spot.device_id
          ),
        },
      }));
    },

    setAudienceRemoveSpotFromAddedList: (spot: GLSpotsMockProps) => {
      set((state) => ({
        state: {
          ...state.state,
          glSpotsAddeds: state.state.glSpotsAddeds.filter(
            (s) => s.device_id !== spot.device_id
          ),
        },
      }));
    },

    setAudienceAddAllSpots(spot: GLSpotsMockProps[]) {
      set((state) => ({
        state: {
          ...state.state,
          allGlSpots: spot,
        },
      }));
    },

    setAudienceAddAllSpotsFiltered(spot: GLSpotsMockProps[]) {
      set((state) => ({
        state: {
          ...state.state,
          glSpotsOnFiltered: spot,
        },
      }));
    },

    setAudienceIncrementAllSpots(spot: GLSpotsMockProps[]) {
      set((state) => ({
        state: {
          ...state.state,
          allGlSpots: [...state.state.allGlSpots, ...spot],
        },
      }));
    },

    setAudienceResetList: () => {
      set((state) => ({
        state: {
          ...state.state,
          glSpotsSelected: [],
        },
      }));
    },

    setAudienceListType: (type: "grid" | "list") => {
      set((state) => ({
        state: {
          ...state.state,
          listType: type,
        },
      }));
    },

    setAudienceSelectAll: (selectAll: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          selectAll: selectAll,
        },
      }));
    },

    setAudienceSpotListError: (error: string | undefined) => {
      set((state) => ({
        state: {
          ...state.state,
          spotListError: error,
        },
      }));
    },

    tabsActions: {
      setCount: (tab: TabProps, count: number) =>
        set((state) => ({
          state: {
            ...state.state,
            tabs: state.state.tabs.map((t: TabProps) =>
              t.name === tab.name ? { ...t, count } : t
            ),
          },
        })),
      setOnClick: (tab: TabProps, onClick: () => void) =>
        set((state) => ({
          state: {
            ...state.state,
            tabs: state.state.tabs.map((t: TabProps) =>
              t.name === tab.name ? { ...t, onClick } : t
            ),
          },
        })),
    },

    resetAll: (glSpots: GLSpotsMockProps[], activeTab: number | undefined) => {
      set((state) => ({
        state: {
          ...state.state,
          currentPage: 0,
          activeTabId: activeTab,
          search: "",
          nextPageTokenSearching: undefined,
          spotListError: undefined,

          glSpotsSelected: [],
          glSpotsOnList: glSpots,

          glSpotsAddeds: [],
          allGlSpots: glSpots,

          listType: "grid",
          selectAll: false,
          lastNextPageToken: {
            "all-gl-spots": {
              token: undefined,
              page: undefined,
            },
            "gl-spots-added": {
              token: undefined,
              page: undefined,
            },
          },
        },
      }));
    },
  },
}));
