import {
  useGLPagination,
  useI18n,
  useRegisterDeviceStore,
  useToast,
} from "@group-link-one/grouplink-components";
import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useDeviceListService } from "../../../../Services/deviceListService/useDeviceListService";
import {
  DevicesActivatedLastReadingsChannel,
  GetDevicesActivatedLastReadingsResponse,
} from "../../../../Services/deviceListService/useDeviceListService.types";
import { useDetailsDeviceStore } from "../../store/details-device-store";

export const useDeviceDetailsTitleContent = () => {
  const { t } = useI18n();
  const [isEditingDeviceName, setIsEditingDeviceName] = useState(false);
  const { actions: registerDeviceActions, state: registerDeviceState } =
    useRegisterDeviceStore();
  const [loading, setLoading] = useState(false);

  const { updateDevice } = useDeviceListService();
  const { addToast } = useToast();

  const is768Px = useMediaQuery("(max-width: 768px)");

  const { state: detailsDeviceState, actions: detailsDeviceActions } =
    useDetailsDeviceStore();

  const editInputRef = useRef<HTMLInputElement>(null);

  const queryClient = useQueryClient();

  const { state: paginationState, actions: paginationActions } =
    useGLPagination();

  const activeTab = useMemo(() => {
    return paginationActions.getActiveTabById(paginationState.activeTabId);
  }, [paginationState.activeTabId]);

  const currentChannelData = useMemo(() => {
    return detailsDeviceState.device?.channels[0];
  }, []);

  const [editingDeviceNameValue, setEditingDeviceNameValue] = useState<
    string | undefined
  >(currentChannelData?.name || "");

  function onAddressClick(lat: number, lng: number) {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank");
  }

  function onDeviceNameClick() {
    registerDeviceActions.setDeviceData({
      item: t("deviceList.detailsModal.sr.modalEdit.name"),
      currentName: currentChannelData?.name || "",
      newName: registerDeviceState.newDeviceName || "",
    });
    setIsEditingDeviceName(true);
    setTimeout(() => {
      editInputRef.current?.focus();
    }, 100);
  }

  async function onClickToEditDeviceName() {
    setLoading(true);
    setIsEditingDeviceName(false);

    if (registerDeviceState.newDeviceName === currentChannelData?.name) {
      setEditingDeviceNameValue(currentChannelData?.name);
      return;
    }

    if (!registerDeviceState.newDeviceName) {
      setEditingDeviceNameValue(currentChannelData?.name);
      return;
    }

    try {
      const updateDeviceParams: any = {
        device_id: detailsDeviceState.device?.device_id,
        channel_data: detailsDeviceState.device?.channels.map((channel) => ({
          channel_name: registerDeviceState.newDeviceName,
          channel: channel.channel,
        })),
      };

      await updateDevice(updateDeviceParams);

      registerDeviceActions.setModalAreYouSureIsOpen(false);
      setLoading(false);

      queryClient.refetchQueries({
        queryKey: ["devices-activateds-list"],
      });

      const newChannelData: any = detailsDeviceState?.device?.channels.map(
        (channel) => {
          if (channel.channel === currentChannelData?.channel) {
            return {
              ...channel,
              name: registerDeviceState.newDeviceName,
            };
          }

          return channel;
        }
      );

      updateStoreAfterSuccess(newChannelData);
      updateCacheAfterSuccess(newChannelData);
    } catch (error) {
      setLoading(false);

      addToast({
        type: "error",
        title: t("deviceList.detailsModal.onEditDeviceName.error.title"),
        message: t(
          "deviceList.detailsModal.onEditDeviceName.error.description"
        ),
      });
    }
  }

  function updateStoreAfterSuccess(
    newChannelData: DevicesActivatedLastReadingsChannel[]
  ) {
    if (!currentChannelData || !detailsDeviceState.device) return;

    detailsDeviceActions.setDevice({
      ...detailsDeviceState.device,
      channels: newChannelData,
    });
  }

  function updateCacheAfterSuccess(
    newChannelData: DevicesActivatedLastReadingsChannel[]
  ) {
    if (!currentChannelData || !detailsDeviceState.device || !activeTab) return;

    const activeTabMap = {
      "tab-1": {
        do: () => updateTabOne(newChannelData),
      },
    };

    activeTabMap[`tab-${activeTab.id}` as keyof typeof activeTabMap]?.do();
  }

  function updateTabOne(newChannelData: DevicesActivatedLastReadingsChannel[]) {
    const allCachesData: [
      QueryKey,
      GetDevicesActivatedLastReadingsResponse[] | undefined,
    ][] = queryClient.getQueriesData({
      queryKey: ["devices-activateds-list"],
    });

    if (allCachesData.length === 0) return;

    allCachesData.forEach(([queryKey, cachedData]) => {
      if (!cachedData) return;

      const hasDeviceID = cachedData.some(
        (device) => device.device_id === detailsDeviceState.device?.device_id
      );

      if (!hasDeviceID) return;

      const newCacheData = cachedData.map((device) => {
        if (device.device_id === detailsDeviceState.device?.device_id) {
          return {
            ...device,
            channels: newChannelData,
          };
        }

        return device;
      });

      queryClient.setQueryData(queryKey, newCacheData);
    });
  }

  useEffect(() => {
    if (isEditingDeviceName) {
      setEditingDeviceNameValue(registerDeviceState.newDeviceName);
    }
  }, [isEditingDeviceName]);

  return {
    t,
    editInputRef,
    editingDeviceNameValue,
    isEditingDeviceName,
    setIsEditingDeviceName,
    is768Px,
    onAddressClick,
    onDeviceNameClick,
    onClickToEditDeviceName,
    registerDeviceActions,
    registerDeviceState,
    loading,
  };
};
