import { GL_COLORS } from "@group-link-one/grouplink-components";
import styled from "styled-components";

export const CampaignCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .div__buttons {
    button {
      :hover {
        transition: all 0.4s;
        border-radius: 5px;
        box-shadow: 0 0px 0 2px ${GL_COLORS.OUTLINE_ACCENT};
      }
    }
  }
  
`;
