// disable-eslint

import {
  GL_CARDS,
  GL_COLORS,
  GL_HAMBURGUER_MENU,
  GL_IDEA,
  GLButton,
  GLCard,
  GLCheckbox,
  GLEmptyList,
  GLExtendedTextField,
  GLList,
  GLPaginationComponent,
  GLTextField,
  GLTipsCard,
  GLTypography,
  SkeletonBox,
  StyledAnim,
  useI18n,
} from "@group-link-one/grouplink-components";
import { AnimatePresence, motion } from "framer-motion";
import { memo } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "usehooks-ts";

import LogoGLTag from "../../../../../images/ico-glcoin.svg";
import LogoGLPlug from "../../../../../images/icon-glplug.svg";
import LogoGLStation from "../../../../../images/icon-glstation.svg";
import EmptyListImage from "../../../../../images/MessageCenter/empty_art_light.svg";
import NoDeviceFoundImage from "../../../../../images/MessageCenter/no_device-found.svg";
import { useOnlineCampaignContent } from "../../OnlineCampaign/Content/useOnlineCampaignContent";
import { useCreateCampaignStore } from "../store/createCampaign";
import { useSegmentationStore } from "../store/segmentation";
import { useCampaignName } from "./hooks/useCampaignName";
import { useSegmentation } from "./hooks/useSegmentation";
import { useSetSourceApps } from "./hooks/useSetSourceApps";
import {
  ListFiltersBottomContainer,
  ListFiltersTopContainer,
  SegmentationContainer,
  SpotListPopup,
} from "./segmentationStyle";

const iconMap = {
  station: {
    icon: LogoGLStation,
    alt: "GL Station Icon",
  },
  plug: {
    icon: LogoGLPlug,
    alt: "GL Plug Icon",
  },
  tag: {
    icon: LogoGLTag,
    alt: "GL TAG Icon",
  },
};

function SegmentationContent() {
  const { t } = useI18n();
  const {
    activeTab,
    createCampaignState,
    handleNextPage,
    handlePreviousPage,
    handleOnSelectGLSpot,
    handleOnSelectAllGLSpots,
    handleOnAddSpotsToAudience,
    formatStatusGLSpot,
    spotListState,
    spotListActions,
    selectAllGLSpots,
    deselectAllGLSpots,
    listContainerRef,
  } = useSegmentation();

  const {
    handleOnInput,
    hasErrorInInputCampaignName,
    inputCampaignNameMessageError,
    inputCampaignNameValue,
  } = useCampaignName();

  const { state: segmentationState, actions: segmentationActions } =
    useSegmentationStore();

  const {
    hasErrorInSourceApps,
    sourceAppsMessageError,
    handleOnSelectApp,
    verifyIfWasSelected,
  } = useSetSourceApps();
  const { loadingNotification } = useOnlineCampaignContent();

  const { state } = useCreateCampaignStore();

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const filterDevicesId = (value: string) => {
    segmentationActions.setTextFieldValue(value);
    const filteredSpots = spotListState?.glSpotsOnList.filter((spot) =>
      spot.device_id.toString().includes(value)
    );

    spotListActions.setAudienceAddAllSpotsFiltered(filteredSpots);
  };

  return (
    <SegmentationContainer>
      <div className="segmentation-content-left">
        <div className="segmentaion-campaign-name">
          {loadingNotification && state.modalType === "edition" ? (
            <SkeletonBox width={40} height={20}>
              <StyledAnim />
            </SkeletonBox>
          ) : (
            <GLTextField
              title={t(
                "messageCenter.list.modal.creationModal.steps.one.labels.campaignName"
              )}
              placeholder={t(
                "messageCenter.list.modal.creationModal.steps.one.placeholder"
              )}
              onInput={handleOnInput}
              failure={hasErrorInInputCampaignName}
              failureMessage={inputCampaignNameMessageError!}
              value={inputCampaignNameValue}
              style={{
                maxWidth: "400px",
              }}
            />
          )}
        </div>

        <div className="segmentation-source-apps">
          <GLTypography
            text={t(
              "messageCenter.list.modal.creationModal.steps.one.labels.sourceApps"
            )}
            color="DISABLED_FONT_COLOR"
            weight={600}
            fontSize={4}
            as="p"
            style={{
              textTransform: "uppercase",
            }}
          />

          <p>
            {t(
              "messageCenter.list.modal.creationModal.steps.one.labels.appSender"
            )}
          </p>

          <div
            className={`segmentation-apps-list ${hasErrorInSourceApps ? "error" : ""}`}
          >
            {state.modalType === "creation"
              ? segmentationState.apps &&
                segmentationState.apps.length > 0 &&
                segmentationState.apps?.map((app) => {
                  return (
                    <GLCard
                      key={app.id}
                      variant="sr-select-app-card"
                      appName={app.name}
                      isMobile={isMobile}
                      onCheck={() => handleOnSelectApp(app.id!)}
                      osApp={{
                        android: app.android,
                        ios: app.ios,
                      }}
                      isSelected={verifyIfWasSelected(app.id)}
                    />
                  );
                })
              : segmentationState.apps &&
                segmentationState.apps.length > 0 &&
                segmentationState.apps?.map((app) => {
                  return (
                    <GLCard
                      key={app.id}
                      variant="sr-select-app-card"
                      appName={app.name}
                      isMobile={isMobile}
                      onCheck={() => handleOnSelectApp(app.id!)}
                      osApp={{
                        android: app.android,
                        ios: app.ios,
                      }}
                      isSelected={verifyIfWasSelected(app.id)}
                    />
                  );
                })}

            {(!segmentationState.apps ||
              segmentationState.apps.length === 0) && (
              <div className="segmentation-list-empty">
                <GLEmptyList
                  title="No apps found"
                  icon={<img src={EmptyListImage} alt="" />}
                  description="There are no apps available to be selected."
                />
              </div>
            )}
          </div>

          {sourceAppsMessageError && (
            <GLTypography
              text={sourceAppsMessageError}
              color="DANGER"
              className="error-message"
              fontSize={3.5}
              as="span"
              data-error-message
              style={{
                marginTop: "10px",
              }}
            />
          )}
        </div>

        <div className="segmentation-list-spots" ref={listContainerRef}>
          <div className="list-spots-header">
            <GLTypography
              text={t(
                "messageCenter.list.modal.creationModal.steps.one.labels.chooseAudience"
              )}
              color="DISABLED_FONT_COLOR"
              fontSize={3.5}
              as="h3"
              weight={600}
              style={{
                textTransform: "uppercase",
              }}
            />

            <div>
              <p>
                {t(
                  "messageCenter.list.modal.creationModal.steps.one.labels.audience"
                )}
              </p>
              <GLExtendedTextField
                value={segmentationState.textFieldValue}
                onChange={(e) => filterDevicesId(e.target.value)}
              />
            </div>

            {spotListState.spotListError && (
              <GLTypography
                text={spotListState.spotListError}
                color="DANGER"
                fontSize={3.5}
                as="span"
                weight={600}
                data-error-message
                style={{
                  marginTop: "10px",
                }}
              />
            )}
          </div>

          <GLList
            tabs={spotListState?.tabs}
            tabActive={activeTab?.name || ""}
            type={spotListState?.listType}
            error={spotListState?.spotListError}
            content={
              <>
                {spotListState?.glSpotsOnList?.length > 0 ? (
                  (spotListState.glSpotsOnFiltered.length > 0
                    ? spotListState.glSpotsOnFiltered
                    : spotListState.glSpotsOnList
                  )?.map((spot) => (
                    <GLCard
                      variant="sr-spot-card"
                      key={spot?.id}
                      spot={spot?.type}
                      id={spot?.device_id}
                      type={
                        spotListState?.listType === "grid"
                          ? "card"
                          : "line-card"
                      }
                      status={formatStatusGLSpot(spot?.device_id)}
                      icon={
                        <img
                          src={iconMap["station"]?.icon}
                          alt={iconMap["station"]?.alt}
                        />
                      }
                      onSelect={() => handleOnSelectGLSpot(spot?.device_id)}
                    />
                  ))
                ) : (
                  <div className="device-empty-list">
                    <GLEmptyList
                      title={t("messageCenter.tips.title")}
                      description={t("messageCenter.tips.description")}
                      icon={<img src={NoDeviceFoundImage} alt="" />}
                      hasBorder={false}
                    />
                  </div>
                )}
              </>
            }
            filters={
              <ListFiltersTopContainer>
                <button
                  onClick={() => spotListActions.setAudienceListType("grid")}
                >
                  <GL_CARDS
                    size={20}
                    fill={
                      spotListState?.listType === "grid"
                        ? GL_COLORS.ACCENT_COLOR
                        : GL_COLORS.FONT_COLOR_DARK_GREY
                    }
                  />
                </button>

                <button
                  onClick={() => spotListActions.setAudienceListType("list")}
                >
                  <GL_HAMBURGUER_MENU
                    size={20}
                    fill={
                      spotListState?.listType === "list"
                        ? GL_COLORS.ACCENT_COLOR
                        : GL_COLORS.FONT_COLOR_DARK_GREY
                    }
                  />
                </button>
              </ListFiltersTopContainer>
            }
            filtersBottom={
              spotListState?.glSpotsOnList?.length > 0 ? (
                <ListFiltersBottomContainer>
                  <div>
                    <GLCheckbox
                      iconType="check"
                      text={t(
                        "messageCenter.list.modal.creationModal.steps.one.audienceList.header.select"
                      )}
                      onChange={() => handleOnSelectAllGLSpots()}
                      checked={spotListState?.selectAll}
                    />
                    {!isMobile && (
                      <AnimatePresence mode="wait">
                        {spotListState?.glSpotsSelected.length > 0 && (
                          <>
                            <motion.p
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0, x: -20 }}
                            >
                              <span>
                                {spotListState?.glSpotsSelected.length}
                              </span>
                              GL Spots{" "}
                              {t(
                                "messageCenter.list.modal.creationModal.steps.one.audienceList.header.spotHover"
                              )}
                            </motion.p>
                            <motion.p
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0, x: -20 }}
                            >
                              <button
                                style={{
                                  color: GL_COLORS.ACCENT_COLOR,
                                  fontWeight: 600,
                                  border: "none",
                                  background: "inherit",
                                }}
                                onClick={() => {
                                  handleOnAddSpotsToAudience(
                                    spotListState?.glSpotsSelected,
                                    spotListState?.activeTabId === 1
                                      ? "to-audience-added"
                                      : "to-all-gl-spots"
                                  );
                                }}
                              >
                                {spotListState?.glSpotsAddeds.length > 0 &&
                                spotListState?.activeTabId === 2
                                  ? t(
                                      "messageCenter.list.modal.creationModal.steps.one.audienceList.header.spotSelected.alert.removeButton"
                                    )
                                  : t(
                                      "messageCenter.list.modal.creationModal.steps.one.audienceList.header.spotSelected.alert.button"
                                    )}
                              </button>
                            </motion.p>
                          </>
                        )}
                      </AnimatePresence>
                    )}
                  </div>

                  <GLPaginationComponent
                    itemsPerPage={15}
                    nextPage={handleNextPage}
                    previousPage={handlePreviousPage}
                    totalItems={activeTab?.count || 0}
                    currentPage={spotListState?.currentPage}
                  />
                </ListFiltersBottomContainer>
              ) : undefined
            }
            hasFixedHeaderAnimation={false}
          />
        </div>

        <AnimatePresence mode="wait">
          {spotListState?.glSpotsSelected?.length > 0 && (
            <SpotListPopup>
              <p>
                <span>{spotListState?.glSpotsSelected?.length}</span>
                GL Devices{" "}
                {t(
                  "messageCenter.list.modal.creationModal.steps.one.audienceList.header.spotSelected.alert.title"
                )}
              </p>

              <div>
                {!isMobile ? (
                  <GLButton
                    text={`${t(
                      "messageCenter.list.modal.creationModal.steps.one.audienceList.header.spotSelected.alert.subtitle"
                    )} ${spotListState?.glSpotsOnList?.length} GL Devices`}
                    border={false}
                    variant="outline"
                    align="center"
                    weight={600}
                    onClick={() => selectAllGLSpots()}
                    className="popup-select-all-button"
                    style={{
                      color: GL_COLORS.ACCENT_COLOR,
                      whiteSpace: "nowrap",
                    }}
                  />
                ) : (
                  <GLButton
                    text="Deselect"
                    border={true}
                    variant="outline"
                    align="center"
                    weight={600}
                    padding={true}
                    onClick={() => deselectAllGLSpots()}
                    className="popup-select-all-button"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  />
                )}

                <GLButton
                  variant={isMobile ? "fill" : "outline"}
                  border={true}
                  weight={600}
                  align="center"
                  className="popup-update-button"
                  style={{
                    whiteSpace: "nowrap",
                    padding: "0px 15px",
                  }}
                  onClick={() =>
                    handleOnAddSpotsToAudience(
                      spotListState?.glSpotsSelected,
                      spotListState?.activeTabId === 1
                        ? "to-audience-added"
                        : "to-all-gl-spots"
                    )
                  }
                  text={
                    spotListState?.activeTabId === 1
                      ? t(
                          "messageCenter.list.modal.creationModal.steps.one.audienceList.header.spotSelected.alert.button"
                        )
                      : t(
                          "messageCenter.list.modal.creationModal.steps.one.audienceList.header.spotSelected.alert.removeButton"
                        )
                  }
                />
              </div>
            </SpotListPopup>
          )}
        </AnimatePresence>
      </div>

      <div className="segmentation-content-right">
        <GLTipsCard
          icon={<GL_IDEA fill={GL_COLORS.FONT_COLOR} />}
          title={t(
            "messageCenter.list.modal.creationModal.steps.one.tips.title"
          )}
          content={
            <p>
              {t(
                "messageCenter.list.modal.creationModal.steps.one.tips.description"
              )}
            </p>
          }
        />
      </div>

      {createCampaignState.modalSteperContentIsOpen && (
        <Helmet title="Message Center - Segmentation" />
      )}
    </SegmentationContainer>
  );
}

export const Segmentation = memo(SegmentationContent);
