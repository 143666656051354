import styled from "styled-components";

import { PageAnimatedContainer } from "../../../../../utils/pageAnimated";

export const OnlineCampaignContentContainer = styled(PageAnimatedContainer)`
  display: flex;
  flex-direction: column;

  .online-campaign-empty-list {
    flex: 1;
    display: flex;
    flex-direction: column;

    > div {
      flex: 1;
    }
  }
`;
