import { useAuth } from "@group-link-one/gl-auth";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

export function useCallbackToModalLogout() {
  const [, , removeValue] = useLocalStorage("persist:root", "");
  const navigate = useNavigate();
  const { manipulateReduxValues } = useAuth();

  function callbackFnToModalLogout() {
    removeValue();
    navigate("/");
    manipulateReduxValues("SET_ACCESS_TOKEN", "");
    manipulateReduxValues("SET_ACCESS_TOKEN_IS_VALID", false);
    manipulateReduxValues("SET_HASHED_TOKEN", "");
    manipulateReduxValues("SET_USER", "");
  }

  return {
    callbackFnToModalLogout,
  };
}
