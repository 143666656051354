import {
  GL_COLORS,
  GLButton,
  GLModal,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useState } from "react";
import { Helmet } from "react-helmet";

import { useMessageCenterStore } from "../../store/messageCenter";
import { useModalDeleteCampaign } from "./useModalDeleteCampaign";

export const DeactivateCampaignModal = () => {
  const { t } = useI18n();
  useState(false);
  const { actions, state } = useMessageCenterStore();
  const { deleteCampaignById, isLoading } = useModalDeleteCampaign();

  return (
    <GLModal
      open={state.modalDeleteIsOpen}
      title={t("messageCenter.modalDelete.title")}
      description={`${t("messageCenter.modalDelete.description")}`}
      content={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            gap: "25px",
          }}
        >
          <GLButton
            style={{
              border: `2px solid ${GL_COLORS.NAVY}`,
              color: `${GL_COLORS.NAVY}`,
              fontWeight: 600,
            }}
            text={t("messageCenter.modalDelete.buttonCancel")}
            align="center"
            variant="outline"
            onClick={() => actions.setModalDeleteIsOpen(false)}
          />
          <GLButton
            text={t("messageCenter.modalDelete.buttonDelete")}
            align="center"
            weight={600}
            variant="danger_fill"
            isLoading={isLoading}
            size="primary"
            disabled={isLoading}
            onClick={() => {
              if (!state.campaignId) return;
              deleteCampaignById(state.campaignId);
            }}
          />

          {state.modalDeleteIsOpen && (
            <Helmet title="Message Center - Delete" />
          )}
        </div>
      }
    />
  );
};
